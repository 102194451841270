import React, { Component } from "react";
import { TableCtn } from "../../styles/styled";
import ultrabankLogo from "../../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../../assets/images/aladdin_logo.png";
import { useState } from "react";
import { showErrorMessage } from "../../../../utils/notification";
import { getRequest } from "../../../../utils/httprequests/requests";
import { useEffect } from "react";
import Dashboard from "../..";
import {
  getActiveProgressDot,
  getFormattedNumber,
  getLoanStatusText,
} from "../../../../utils/functions";
import "./styles.scss";
import { Link } from "react-router-dom";
import PlanLoader from "../../overview/components/PlanLoader";
import EmptyPlansTable from "../../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import { formatPlanDate } from "../../pendingplans/pendingplans";
import CompanyLogo from "../../../../dashboardcomponents/companylogo/companylogo";
import Paginator from "../../../../dashboardcomponents/paginator/paginator";

const tableArray = [
  {
    company_name: "Ultrabank",
    company_logo: ultrabankLogo,
    amount: "2,000,000.05",
    due_date: "03-Sept-2022",
  },
  {
    company_name: "Xensys Finance",
    company_logo: xensysLogo,
    amount: "100,000",
    due_date: "29-Dec-2010",
  },
  {
    company_name: "Renmoneye ",
    company_logo: renmoneyeLogo,
    amount: "40,700,000",
    due_date: "15-Jan-2022",
  },
  {
    company_name: "Aladdin Digital ",
    company_logo: aladdinLogo,
    amount: "5,000,000",
    due_date: "03-Jun-2019",
  },
];
const PaymentActivePlans = () => {
  const [loans, setLoans] = useState(null);
  const [loadingLoans, setLoadingLoans] = useState(false);
  const [showMilestone, setShowMilestone] = useState({});

  async function fetchLoans() {
    setLoadingLoans(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/enrolled_plans/2");
      if (status == "success") {
        setLoans((prev) => info?.details);
      } else {
        // setLoans(tableArray);
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      // showErrorMessage(err?.response?.data?.message || err?.message || err);
    }
    setLoadingLoans(false);
  }

  function printStyle() {
    let style = [];

    for (let i = 0; i <= 100; i++) {
      style.push(`&.percent-${i} {
        @apply w-[${i}%] absolute;
      }`);
    }

    console.log(style.join("\n"));
  }

  useEffect(() => {
    // printStyle();
    fetchLoans();
  }, []);

  return (
    <>
      {" "}
      <TableCtn className="payment-large-table overflow-auto">
        {loadingLoans ? (
          <PlanLoader />
        ) : loans?.data?.length > 0 ? (
          <>
            <div className="flex flex-col gap-10 min-w-[548px] pt-10 w-full">
              {loans?.data?.map((company, idx) => (
                <div className="item grid grid-cols-7 gap-x-6 w-full px-3">
                  <div className="min-w-[100px] ">
                    <CompanyLogo
                      logo={company?.company_logo}
                      name={company?.company_name}
                    />
                  </div>
                  <div className="grid items-center col-span-2 ml-2">
                    <div>
                      <div className="truncated font-bold text-[16px] leading-[22.4px]">
                        {company?.company_name || ""}
                      </div>
                      <div className="fs-10 faded-text">
                        Due date: {formatPlanDate(company?.due_date)}
                      </div>
                    </div>
                  </div>
                  <div className="grid items-center col-span-2">
                    <p className="text-sm font-bold">
                      ₦{getFormattedNumber(company?.amount)}
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col justify-content-center">
                    <Link
                      to={
                        "/dashboard/payments/payment_details/" +
                        company.restructure_id
                      }
                    >
                      <button className="btn fs-14 btn_primary px-4 mx-auto">
                        View Next Payment
                      </button>
                    </Link>
                  </div>
                  <div className="col-span-full mt-[14px]">
                    <button
                      className="text-primary bg-transparent font-bold px-0"
                      onClick={() =>
                        showMilestone[idx]
                          ? setShowMilestone((prev) => ({
                              ...prev,
                              [idx]: false,
                            }))
                          : setShowMilestone((prev) => ({
                              ...prev,
                              [idx]: true,
                            }))
                      }
                    >
                      {showMilestone[idx] ? "Hide" : "Show"} Payment Progress
                    </button>
                  </div>
                  {showMilestone[idx] && (
                    <div className="col-span-full mt-[28px]">
                      <div className="progress">
                        <div
                          className={`progress-fill percent-${(
                            (100 / parseInt(company?.amount)) *
                            parseInt(company?.total_paid || 0)
                          ).toFixed(0)}`}
                        ></div>
                        <div
                          className={`progress-dot ${
                            getActiveProgressDot(
                              company?.total_paid,
                              (0 / 100) * parseInt(company?.amount)
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="dot-label">
                            ₦
                            {getFormattedNumber(
                              (0 / 100) * parseInt(company?.amount),
                              0
                            )}
                          </div>
                        </div>
                        <div
                          className={`progress-dot two ${
                            getActiveProgressDot(
                              company?.total_paid,
                              (25 / 100) * parseInt(company?.amount)
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="dot-label">
                            ₦
                            {getFormattedNumber(
                              (25 / 100) * parseInt(company?.amount),
                              0
                            )}
                          </div>
                        </div>
                        <div
                          className={`progress-dot three ${
                            getActiveProgressDot(
                              company?.total_paid,
                              (50 / 100) * parseInt(company?.amount)
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="dot-label">
                            ₦
                            {getFormattedNumber(
                              (50 / 100) * parseInt(company?.amount),
                              0
                            )}
                          </div>
                        </div>
                        <div
                          className={`progress-dot four ${
                            getActiveProgressDot(
                              company?.total_paid,
                              (75 / 100) * parseInt(company?.amount)
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="dot-label">
                            ₦
                            {getFormattedNumber(
                              (75 / 100) * parseInt(company?.amount),
                              0
                            )}
                          </div>
                        </div>
                        <div
                          className={`progress-dot five ${
                            getActiveProgressDot(
                              company?.total_paid,
                              (100 / 100) * parseInt(company?.amount)
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          <div className="dot-label">
                            ₦
                            {getFormattedNumber(
                              (100 / 100) * parseInt(company?.amount),
                              0
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <EmptyPlansTable />
        )}
      </TableCtn>
      {loans?.data?.length > 0 && (
        <div className="px-3 pt-3 pb-10 w-full">
          <Paginator
            data={loans}
            setData={setLoans}
            setLoading={setLoadingLoans}
          />
        </div>
      )}
    </>
  );
};

export default PaymentActivePlans;
