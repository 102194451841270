import TipsCard from "../TipsCard";
import woman_smile_tips_img from "../../../../../assets/images/woman_smile_tips_img.png";

export default function TipsTwoTipThree(props) {
  return (
    <TipsCard {...props}>
      <div className="w-full px-[29px] pt-[33px] bg-white">
        <img
          src={woman_smile_tips_img}
          alt=""
          className="w-full max-w-[640px] mx-auto relative top-[13px]"
        />
      </div>
      <div className="p-[38px] bg-[#066A96] z-10 relative">
        <p className="text-sm leading-[26px] text-white max-w-[75%]">
          With flexible payements and discounts, paying back your loans have
          never been easier. Restructure allows you to have the easiest way to
          become debt free without stress.
        </p>
      </div>
    </TipsCard>
  );
}
