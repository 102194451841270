import { useEffect, useState } from "react";
import Dashboard from "..";

import { postRequest } from "../../../utils/httprequests/requests";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";
import {
  cookieStorage,
  setToStorage,
} from "../../../utils/storage/tokenstorage";

export default function NotificationsPage() {
  const [channel, setChannel] = useState(
    cookieStorage.getItem("preferred_channel") || ""
  );
  const [savePreferenceLoading, setSavePreferenceLoading] = useState(false);

  const handleInput = (e) => {
    setChannel(e.target.name);
  };

  async function savePreference() {
    setSavePreferenceLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/update_preferred_channel", {
        preferred_channel: channel,
      });
      if (status == "success") {
        showSuccessMessage(message);
        setToStorage("preferred_channel", channel);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      err?.response?.data?.message &&
        showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setSavePreferenceLoading(false);
  }
  return (
    <>
      <div className="row mx-3 mt-4 pt-1 gx-md-5 gy-4">
        <div className="grid md:w-2/3 form gap-6">
          <div className="mb-4">
            <div className="text-base font-semibold text-[#555555]">
              Change preferred channel
            </div>
            <div className="text-xs text-[#717171]">
              Select a new channel for receiving campaign notifications
            </div>
          </div>
          <div className="options">
            <div className="inputs flex gap-3 mb-2">
              <input
                type="checkbox"
                name="1"
                id="email"
                checked={channel === "1"}
                onChange={handleInput}
              />
              <label htmlFor="email" className="relative -top-1">
                Email
              </label>
            </div>
            <div className="inputs flex gap-3 mb-2">
              <input
                type="checkbox"
                name="2"
                id="sms"
                checked={channel === "2"}
                onChange={handleInput}
              />
              <label htmlFor="sms" className="relative -top-1">
                Sms
              </label>
            </div>
            <div className="inputs flex gap-3 mb-2">
              <input
                type="checkbox"
                name="3"
                id="whatsapp"
                checked={channel === "3"}
                onChange={handleInput}
              />
              <label htmlFor="whatsapp" className="relative -top-1">
                Whatsapp
              </label>
            </div>
            {/* <div className="inputs flex gap-3 mb-2">
              <input
                type="checkbox"
                name="4"
                id="botcall"
                checked={channel === "4"}
                onChange={handleInput}
              />
              <label htmlFor="botcall" className="relative -top-1">
                Botcall
              </label>
            </div> */}
          </div>
          <div className="input-ctn mt-10">
            <button
              onClick={savePreference}
              className="btn-primary hover:bg-[#05aaf1] active:bg-[#05aaf1] font-bold text-center text-white bg-primary w-full p-[0.875rem]"
            >
              {savePreferenceLoading ? (
                <span className="mx-auto">
                  {" "}
                  <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                </span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
