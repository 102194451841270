import React, { Component } from "react";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";

import "./style.scss";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatPlanDate } from "../pendingplans/pendingplans";
import {
  calculateActivatedAmount,
  getCustomPlanLabel,
  getFormattedNumber,
  getPlanBreakdown,
} from "../../../utils/functions";
import { useEffect } from "react";
import { allRestructurePlansInit } from "../../../utils/data/static";
import { showErrorMessage } from "../../../utils/notification";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import { useRef } from "react";

const bank = {
  name: "Ultrabank",
  image: ultrabankLogo,
  amount: "2,000,000.05",
  due_date: "03-Sept-2022",
};

const paymentDetails = {
  amount: "200,000.02",
  next_repayment: "03-Sept-2022",
  repayment_amount: "100,000",
  repayment_tenure: "Bi-weekly",
  rewards: "10% off last payment",
};

const PaymentDetails = () => {
  const [view, setView] = useState(false);

  const [batchData, setBatchData] = useState(null);
  const [loadingBatchData, setLoadingBatchData] = useState(true);
  const { restructureId } = useParams();
  const navigate = useNavigate();
  const [lobbyData, setLobbyData] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [plans, setPlans] = useState(null);
  const [maximumPaymentPeriod, setMaximumPaymentPeriod] = useState(null);
  const [plansLoading, setPlansLoading] = useState(true);

  async function fetchPlans(ref, lobby_data) {
    setPlansLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { batch_plans, maximum_payment_period },
        },
      } = await postRequest("/fetch_plans", {
        batch_ref: ref,
      });

      if (status == "success") {
        setPlans(batch_plans);
        console.log(lobby_data);
        setSelectedPlan((prev) => ({
          ...batch_plans?.find((i) => i?.id === lobby_data[0]),
          validator: allRestructurePlansInit?.find(
            (i) => i?.data[0] === lobby_data[0]
          )?.validator,
        }));
        setMaximumPaymentPeriod(maximum_payment_period);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setPlansLoading(false);
  }

  async function fetchBatchDetails(lobby_data) {
    setLoadingBatchData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { details },
          },
        },
      } = await getRequest("/fetch_borrower_details/" + restructureId);

      if (status == "success") {
        if (details.status != 1) {
          showErrorMessage("This loan has already been activated");
          navigate("/dashboard/overview");
          return;
        }
        setBatchData({
          ...details,
          activated_amount: calculateActivatedAmount(lobby_data, details),
        });
        await fetchPlans(details?.batch_ref, lobby_data);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBatchData(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      let lobby_data = JSON.parse(localStorage.getItem("lobby_data"));

      if (!lobby_data || lobby_data?.length < 1) {
        showErrorMessage(
          "Please make sure you have selected a plan from the previous page"
        );
        navigate(`/dashboard/restructure_loan/${restructureId}`);
        return;
      }

      setLobbyData((prec) => [...lobby_data]);

      fetchBatchDetails(lobby_data);
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      <div className="row paymentdetails_page">
        <div className="breadcrumbs flex gap-2">
          <div className="item">
            <img src={bank?.image || ""} className="logo_cirle_sm me-3" />
          </div>
          <div className="item active">{bank.name}</div>
          <div className="item divider">&gt;</div>
          <div className="item">Restructure plan</div>
          <div className="item divider">&gt;</div>
          <div className="item active">Payment Details</div>
        </div>
        <div className="two-section-grid">
          <div className="left-section">
            <h3 className="font-bold text-[1rem] leading-[1.375rem] text-dashboard px-4 mb-[0.75rem]">
              Select Payment Plan
            </h3>
            <div className="payment-details">
              <div className="item">
                <div className="title">Payment Plan {selectedPlan?.id}</div>
                <div className="subtitle">
                  {selectedPlan?.type === "fixed"
                    ? selectedPlan?.data
                    : getCustomPlanLabel(selectedPlan?.data) || ""}
                </div>
                <div className="icon checked">
                  <Icon icon="mdi-checkbox-outline"></Icon>
                </div>
              </div>
              <div className="payment-dropdown font-bold text-sm flex justify-between cursor-pointer">
                <p className="text-primary flex flex-col justify-center">
                  Payment Details
                </p>
                <div className="flex flex-col justify-center">
                  <Icon icon="mdi-chevron-down" className="text-2xl"></Icon>
                </div>
              </div>
              <div className="payment-dropdown-content mt-4">
                <table className="w-3/4">
                  <thead></thead>
                  <tbody>
                    <tr className="w-full text-[1rem] leading-[1.375rem]">
                      <td className="pb-4 font-bold text-black">
                        Total Amount Payable:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦{getFormattedNumber(batchData?.activated_amount) || ""}
                      </td>
                    </tr>
                    {getPlanBreakdown(lobbyData, batchData)?.length > 0 &&
                      getPlanBreakdown(lobbyData, batchData)?.map(
                        (item, idx) => (
                          <tr
                            key={idx}
                            className="w-full text-[1rem] leading-[1.375rem]"
                          >
                            <td className="pb-4 font-bold text-black">
                              {item.label}:
                            </td>
                            <td className="pb-4 text-typography-4">
                              {item.value}
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="px-5 text-sm text-black">
              Don’t want this plan?{" "}
              <Link to={"/dashboard/restructure_loan/" + restructureId}>
                <span className="text-primary">
                  Select a different plan here
                </span>
              </Link>
            </div>
          </div>{" "}
          <div className="right-section">
            {loadingBatchData ? (
              <div className="h-full w-full grid place-items-center">
                <span className="mx-auto">
                  <Icon
                    icon="mdi:loading"
                    className="text-3xl mx-auto animate-spin"
                  />
                </span>
              </div>
            ) : batchData ? (
              <>
                <div className="logo-name flex mb-10">
                  <CompanyLogo
                    logo={batchData?.company_logo}
                    name={batchData?.company_name}
                  />
                  <div className="flex flex-col justify-center">
                    <div>
                      <h4 className="text-dash-heading font-bold text-xl">
                        {batchData?.company_name || ""}
                      </h4>
                      <p className="faded-text text-[0.625rem]">
                        Due Date: {formatPlanDate(batchData?.due_date) || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <table className="w-full">
                  <thead></thead>
                  <tbody>
                    <tr className="w-full text-[1rem] leading-[1.375rem]">
                      <td className="pb-4 font-bold text-dash-heading">
                        Amount Due:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦
                        {getFormattedNumber(
                          batchData?.current_amount_payable
                        ) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Paid:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦{getFormattedNumber(batchData?.amount_paid) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Balance:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦
                        {getFormattedNumber(
                          parseInt(batchData?.current_amount_payable) -
                            parseInt(batchData?.amount_paid || 0)
                        ) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Due Repayment Date:
                      </td>
                      <td className="pb-4 text-typography-4">
                        {formatPlanDate(batchData?.due_date) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Status:
                      </td>
                      <td
                        className={`pb-4 ${
                          batchData?.status == 1
                            ? "text-secondary"
                            : batchData.status == 2
                            ? "text-primary"
                            : batchData.status == 3
                            ? "text-green-500"
                            : ""
                        }`}
                      >
                        {batchData?.status == 1
                          ? "Pending"
                          : batchData.status == 2
                          ? "Active"
                          : batchData.status == 3
                          ? "Completed"
                          : ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Repayment Option:
                      </td>
                      <td className="pb-4 text-typography-4">
                        {plans?.length || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              false
            )}
          </div>
        </div>
        <div className="my-10">
          <Link
            className="w-full"
            to={`/dashboard/restructure_loan/${restructureId}/payment_method`}
          >
            <button className="font-bold text-center text-white bg-primary w-full max-w-[465px] p-[0.875rem]">
              Proceed to payment
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PaymentDetails;
