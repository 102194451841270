import React, { Component, useEffect, useState } from "react";
import { TableCtn } from "../styles/styled";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../assets/images/aladdin_logo.png";
import { Icon } from "@iconify/react";
import { getRequest } from "../../../utils/httprequests/requests";
import PlanLoader from "../overview/components/PlanLoader";
import { formatPlanDate } from "../pendingplans/pendingplans";
import { getFormattedNumber } from "../../../utils/functions";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import Paginator from "../../../dashboardcomponents/paginator/paginator";
const CompletedPlans = () => {
  const [loans, setLoans] = useState(null);
  const [loadingLoans, setLoadingLoans] = useState(false);

  async function fetchLoans() {
    setLoadingLoans(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/enrolled_plans/3");
      if (status == "success") {
        setLoans((prev) => info?.details);
      } else {
        // setLoans(tableArray);
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      // showErrorMessage(err?.response?.data?.message || err?.message || err);
    }
    setLoadingLoans(false);
  }

  useEffect(() => {
    fetchLoans();
  }, []);
  return (
    <>
      {" "}
      <TableCtn className="large-table overflow-auto">
        {loadingLoans ? (
          <PlanLoader />
        ) : loans?.data?.length > 0 ? (
          <>
            <table className="fs-14 fw-600 w-100">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loans?.data?.map((company, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <CompanyLogo
                          logo={company?.company_logo}
                          name={company?.company_name}
                        />
                      </td>
                      <td>
                        <div className="truncated">
                          {company?.company_name || ""}
                        </div>
                        <div className="fs-10 faded-text">
                          End date: {formatPlanDate(company?.due_date)}
                        </div>
                      </td>
                      <td className="">
                        ₦{getFormattedNumber(company?.amount)}
                      </td>
                      <td className="text-center">
                        <Icon
                          icon="mdi-check-circle"
                          className="text-3xl text-green-500"
                        ></Icon>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <EmptyPlansTable />
        )}
      </TableCtn>
      {loans?.data?.length > 0 && (
        <div className="px-3 pt-3 pb-10 w-full">
          <Paginator
            data={loans}
            setData={setLoans}
            setLoading={setLoadingLoans}
          />
        </div>
      )}
    </>
  );
};

export default CompletedPlans;
