import React, { Component, useState } from "react";
import Dashboard from "..";

import dirright from "../../../assets/svgs/right-dir.svg";
import BasicInfoPage from "../basicinfopage/basicinfopage";
const ProfilePage = () => {
  const [component, setComponent] = useState(1);
  const Items = [
    {
      name: "Basic Information",
      img: component === 1 ? dirright : "",
      id: 1,
      component: <BasicInfoPage />,
    },
  ];
  const [componentDetails] = useState(Items);
  const [currentComponent, setCurrentComponent] = useState([Items[0]]);

  const showDetails = (id = 1) => {
    setComponent(id);
    const currentItem = componentDetails.filter((item) => id === item.id);
    setCurrentComponent(currentItem);
  };

  return (
    <>
      <h4 className="fs-18 fw-700 mb-0">Profile</h4>
      <div className="row h100 ">
        <div className="col-md-3 p-0">
          {" "}
          <Dashboard.SideNav className="pt-md-4">
            {Items.map((item) => {
              return (
                <Dashboard.NavButton
                  key={item.id}
                  onClick={() => showDetails(item.id)}
                  className="flex"
                >
                  {item.name}{" "}
                  {item.img ? (
                      <img
                        src={item.img}
                        className="right-arrow ms-4 relative top-[3px]"
                        style={{ height: ".9rem", width: ".9rem" }}
                      />
                  ) : (
                    ""
                  )}
                </Dashboard.NavButton>
              );
            })}
          </Dashboard.SideNav>
        </div>
        <div className="col-md-9 p-0">
          {" "}
          <>{currentComponent[0].component}</>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
