import { Skeleton } from "@mui/material";

export default function PaymentPlanLoader() {
  return (
    <div className="plan-items grid md:grid-cols-3 gap-6 px-[16px]">
      {[1, 2, 3, 4].map((plan, idx) => (
        <div className="item shadow-none" key={idx}>
          <Skeleton variant="text" className="title w-[80%] leading-[24px]" />
          <Skeleton variant="text" className="title w-[80%] leading-[24px]" />
          <Skeleton
            variant="rectangular"
            className="icon checked h-[16px] w-[16px]"
          />
        </div>
      ))}
    </div>
  );
}
