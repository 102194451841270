import React, { Component, useContext } from "react";
import Dashboard from "../../pages/dashboard";
import overview from "../../assets/svgs/overview.svg";
import payments from "../../assets/svgs/transactions.svg";
import logout from "../../assets/svgs/logout.svg";
import badge from "../../assets/svgs/badge.svg";
import profile from "../../assets/svgs/profile.svg";
import setting from "../../assets/svgs/settings.svg";
import logo from "../../assets/svgs/logo.svg";
import cancel from "../../assets/svgs/x-cancel.svg";
import "./styles.scss";
import LogOut from "../../utils/auth/logout";
import { ToggleContext } from "../../pages/dashboard/dashboardroutes";
import { HorizontalSpacer, VerticalSpacer } from "../../pages/dashboard/styles/styled";

const LightStyle = {
  color: "red",
};
const SideMenu = () => {
  let activeClassName = (navData) => (navData.isActive ? "active" : "");
  const { isHidden, setIsHidden } = useContext(ToggleContext);
  const fixSidebar = () => {
    let width = window.innerWidth;
    if (width >= 992) {
      setIsHidden(true);
      // console.log("Info",isHidden,width);
    }
  };

  window.addEventListener("resize", fixSidebar);
  window.addEventListener("load", fixSidebar);
  window.addEventListener("scroll", fixSidebar);
  window.addEventListener("focus", fixSidebar);
  window.addEventListener("onchange", fixSidebar);
  window.addEventListener("onpageshow", fixSidebar);
  return (
    <>
      {isHidden ? (
        <section id="sidemenu" className="sidemenu">
          <div
            className="cancel-btn"
            style={{ paddingTop: "20.83px", paddingLeft: "26px" }}
          >
            <button
              className="btn btn-toggle"
              onClick={() => {
                setIsHidden(!isHidden);
                console.log("isHidden", isHidden);
              }}
            >
              <img src={cancel} />
            </button>
          </div>
          <div className="w-100 text-center">
            {" "}
            <img
              src={logo}
              alt="Restructure Borrower Portal"
              className="img-fluid mx-auto mt-3"
              style={{ width: "150px",  height:'auto'}}
            />
          </div>
          <Dashboard.UlList className="pt-3">
            <Dashboard.ListItem>
              <Dashboard.MenuItem
                itemName="Overview"
                src={overview}
                srcactive={"overview_active"}
                to="overview"
                className={activeClassName}
              />
            </Dashboard.ListItem>
            <Dashboard.ListItem>
              <Dashboard.MenuItem
                itemName="Payments"
                src={payments}
                srcactive={"payment_active"}
                to="payments"
                className={activeClassName}
              />
            </Dashboard.ListItem>
            <Dashboard.ListItem>
              <Dashboard.MenuItem
                itemName="Rewards"
                src={badge}
                srcactive={"badge_active"}
                to="rewards"
                className={activeClassName}
              />
            </Dashboard.ListItem>{" "}
            <Dashboard.ListItem>
              <Dashboard.MenuItem
                itemName="Profile"
                src={profile}
                srcactive={"profile_active"}
                to="profile"
                className={activeClassName}
              />
            </Dashboard.ListItem>{" "}
            <Dashboard.ListItem>
              <Dashboard.MenuItem
                itemName="Settings"
                src={setting}
                to="settings"
                srcactive={"settings_active"}
                className={activeClassName}
              />
            </Dashboard.ListItem>
            <HorizontalSpacer height="50px" heightSm="0px"/>
            <Dashboard.ListItem >
              <Dashboard.Logout
                itemName="Sign Out"
                src={logout}
                onClick={(e) => {
                  LogOut();
                }}
                className={activeClassName}
              ></Dashboard.Logout>
            </Dashboard.ListItem>
          </Dashboard.UlList>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default SideMenu;
