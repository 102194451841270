import Query from "url-query-parser/simple";
import { formatPlanDate } from "../../pages/dashboard/pendingplans/pendingplans";
import { allRestructurePlansInit, frequencyOptions } from "../data/static";

export const useQuery = (query) => {
  return Query.parse(query);
};

export const getLoanStatusText = (id) => {
  switch (id) {
    case "1":
      return "Pending";
    case "3":
      return "Active";
    case "4":
      return "Completed";
    default:
      return "";
  }
};

export const getFormattedNumber = (num, decimals) => {
  const formatter = new Intl.NumberFormat("en-NG", {
    minimumFractionDigits: decimals === 0 ? 0 : decimals || 2,
    maximumFractionDigits: decimals === 0 ? 0 : decimals || 2,
  });
  return formatter.format(parseInt(num || 0));
};

export const parseCustomPlansLabelData = (label, data) => {
  if (!label) return "";

  let key = "X";
  let indexes = [];

  for (let i = 0; i < label.length; i++) {
    if (label[i] === key) {
      indexes.push(i);
    }
  }

  for (let [idx, val] of indexes.entries()) {
    label = replaceChar(label, data[idx + 1], val);
  }

  return label?.replaceAll(" number of", "");
};

export function replaceChar(origString, replaceChar, index) {
  let firstPart = origString.substr(0, index);
  let lastPart = origString.substr(index + 1);

  let newString = firstPart + replaceChar + lastPart;
  return newString;
}

export function getCustomPlanLabel(data) {
  if (!data) return "";
  let plan = allRestructurePlansInit.find((d) => d?.data[0] === data[0]);
  return parseCustomPlansLabelData(plan?.label, data);
}

export function calculateActivatedAmount(data, batchData) {
  if ([1, 2, 3, 4, 16, 18, 20, 27, 28].includes(data[0])) {
    return parseInt(batchData?.current_amount_payable);
  } else if ([5, 6, 7, 8, 9, 17, 19, 21].includes(data[0])) {
    return parseInt(batchData?.loan_amount) - parseInt(batchData?.amount_paid);
  } else if (data[0] === 10) {
    return (
      parseInt(batchData?.current_amount_payable) -
      (parseInt(data[3]) / 100) * parseInt(batchData?.interest)
    );
  } else if ([11, 12, 13, 14, 15, 23, 24, 26].includes(data[0])) {
    return (
      parseInt(batchData?.current_amount_payable) -
      (parseInt(data[3]) / 100) * parseInt(batchData?.current_amount_payable)
    );
  } else if ([22, 25].includes(data[0])) {
    return (
      parseInt(batchData?.loan_amount) -
      parseInt(batchData?.amount_paid) -
      (parseInt(data[3]) / 100) * parseInt(batchData?.loan_amount)
    );
  } else {
    console.log("No plan match found");
    return 0;
  }
}

const userCustomFrequencyMap = [null, "days", "weeks", null, "months"];

export function getPlanBreakdown(data, batchData) {
  if (!data) return [];
  let breakdown;
  if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 27].includes(data[0])) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "Frequency",
        value: frequencyOptions.find((i) => i.value === data[2]).name,
      },
    ];
    return breakdown;
  } else if (data[0] === 10) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "Frequency",
        value: frequencyOptions.find((i) => i.value === data[2]).name,
      },
      {
        label: "Rewards",
        value: data[3] + "% discount on accrued interest",
      },
    ];
    return breakdown;
  } else if ([11, 12, 13, 14, 15].includes(data[0])) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "Frequency",
        value: "Every " + data[4] + " " + userCustomFrequencyMap[data[2]],
      },
      {
        label: "Maximum Tenure",
        value: data[3] + " " + userCustomFrequencyMap[data[2]],
      },
    ];
    return breakdown;
  } else if ([22, 23].includes(data[0])) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "Rewards",
        value:
          data[3] +
          (data[1] === 22
            ? "% discount on loan amount"
            : "% discount on delinquent amount"),
      },
    ];
    return breakdown;
  } else if ([24, 25, 26].includes(data[0])) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "Maximum no of installments",
        value: data[0] === 26 ? data[5] : data[4],
      },
      {
        label: "Payment schedule",
        value:
          data[0] === 26
            ? data[6]?.map((d) => formatPlanDate(d))?.join(", ")
            : data[5]?.map((d) => formatPlanDate(d))?.join(", "),
      },
      {
        label: "Rewards",
        value:
          data[3] +
          (data[0] === 25
            ? "% discount on loan amount minus total paid"
            : "% discount on delinquent amount"),
      },
    ];
    if (data[0] === 26) {
      breakdown = [
        {
          label: "Initial Payment",
          value:
            "₦ " +
            getFormattedNumber(
              (data[4] / 100) * parseInt(batchData?.current_amount_payable)
            ),
        },
        ...breakdown,
      ];
    }
    return breakdown;
  } else if (data[0] === 28) {
    breakdown = [
      {
        label: "Amount to pay",
        value: "₦ " + getFormattedNumber(data[1]),
      },
      {
        label: "No of installments",
        value: data[3],
      },
      {
        label: "Payment schedule",
        value: data[4]?.map((d) => formatPlanDate(d))?.join(", "),
      },
    ];
    return breakdown;
  } else {
    return [];
  }
}

export function getActiveProgressDot(amount_paid, progress_amount) {
  if (parseInt(amount_paid || 0) >= parseInt(progress_amount || 0)) {
    return true;
  } else {
    return false;
  }
}
