import { Icon } from "@iconify/react";
import bulb from "../../../../assets/svgs/bulb_icon.svg";
import "./styles.scss";

export default function TipsCard({
  closeBtnText,
  onCloseBtnClick,
  nextBtnText,
  nextBtnColor,
  onNextBtnClick,
  children,
  showInfoIcon,
  ...restProps
}) {
  return (
    <div
      {...restProps}
      className="relative overflow-hidden w-full max-w-[648px] rounded-xl min-h-[230px] sm:min-h-[260px] md:min-h-[300px] bg-white"
    >
      <>
        {showInfoIcon && (
          <div className="icon_ctn absolute top-[30px] left-[30px] z-20">
            <img className="img-fluid" src={bulb} />
          </div>
        )}
        <button
          onClick={onCloseBtnClick}
          className="close-btn z-20 flex gap-2 text-[#393939] text-[10.96px] leading-[19.73px] font-bold absolute top-[16px] right-[30px]"
        >
          <span>{closeBtnText || "Close Tutorial"}</span>
          <Icon
            icon="mdi:close"
            className="text-sm relative top-[0.2rem]"
          ></Icon>
        </button>
        {children}
        <button
          onClick={onNextBtnClick}
          style={{
            color: nextBtnColor ? nextBtnColor : "#009ddf",
          }}
          className={`next-btn z-20 flex gap-2 text-sm leading-[25px] font-bold absolute bottom-[20px] md:bottom-[50px] right-[30px]`}
        >
          <span>{nextBtnText || "Next Tip"}</span>
          <Icon
            icon="mdi:chevron-right"
            className="text-3xl relative -top-[0.05rem]"
          ></Icon>
        </button>
      </>
    </div>
  );
}
