import React, { Component } from "react";
import { getRequest, postRequest } from "../httprequests/requests";
import { showErrorMessage, showSuccessMessage } from "../notification";
import { cookieStorage } from "../storage/tokenstorage";
import LogOut from "./logout";
const TokenRefresh = async () => {
  console.log("token refresh called");
  const CallRefresh = async () => {
    return await postRequest("/customers_refresh_token")
      .then((res) => {
        console.log("Token refresh", res.data);
        if (res.data.status == "success") {
          cookieStorage.setItem("token", res.data.data.token);
          // showSuccessMessage("Token Refreshed")
        } else {
          // LogOut();
          // showErrorMessage("Session Expired, Could not refresh Token, ELSE");
        }
      })
      .catch(() => {
        // LogOut();

        showErrorMessage("Session Expired, Could not refresh Token");
      });
  };
  // CallRefresh();
  return setInterval(CallRefresh, 1000 * 60 * 5);
};

export default TokenRefresh;
