import React, { Component, useState } from "react";
import {
  Container,
  RouteContainer,
  MenuItem,
  UlList,
  ListItem,
  FitToWidthDiv,
  Notification,
  ProfileNav,
  TableCtn,
  Td,
  MenuItemNotLink,
  TabButton,
  InputGroup,
  SearchSelect,
  Loader,
  ErrorMessageCtn,
  CustomButton,
  InputErrorMessage,
  Spinner,
  TabNav,
  LocalGovtCard,
  OverviewCard,
  OverviewDataCard,
  SideNav,
  NavButton,
  ProfileImage,
} from "./styles/styled";
import bell from "../../assets/svgs/bell.svg";
import profileActive from "../../assets/svgs/profile_active.svg";
import overviewActive from "../../assets/svgs/overview_active.svg";
import settingsActive from "../../assets/svgs/settings_active.svg";
import paymentsActive from "../../assets/svgs/transactions_active.svg";
import rewardsActive from "../../assets/svgs/badge_active.svg";
import { useEffect } from "react";
import LogOut from "../../utils/auth/logout";
import storageFetch from "../../utils/storage/tokenstorage";
import { useLocation } from "react-router";

export default function Dashboard({ children }) {
  return <Container>{children}</Container>;
}
Dashboard.RouteContainer = function DashboardRouteContainer({
  children,
  ...restProps
}) {
  return <RouteContainer {...restProps}>{children}</RouteContainer>;
};
Dashboard.MenuItem = function DashboardMenuItem({
  src,
  itemName,
  to,
  ...restProps
}) {
  let url;
  const location = useLocation();
  (() => {
    switch (itemName) {
      case "Overview":
        url = overviewActive;
        break;
      case "Rewards":
        url = rewardsActive;
        break;
      case "Settings":
        url = settingsActive;
        break;
      case "Payments":
        url = paymentsActive;
        break;
      case "Profile":
        url = profileActive;
        break;
    }
  })();
  return (
    <MenuItem exact to={to} {...restProps} activeclassname="active">
      <span
        className="my-auto"
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></span>

      <img src={src} alt={itemName} className="menu-img my-auto" />
      <p>{itemName}</p>
    </MenuItem>
  );
};
Dashboard.Logout = function DashboardLogout({
  src,
  itemName,
  children,
  ...restProps
}) {
  return (
    <MenuItemNotLink {...restProps}>
      <img src={src} alt={itemName} className="menu-img" />
      <p>{itemName}</p> {children}
    </MenuItemNotLink>
  );
};
Dashboard.TabNav = function DashboardTabNav({ children }) {
  return <TabNav>{children}</TabNav>;
};
Dashboard.TabButton = function DashboardTabButton({
  children,
  onClick,
  ...restProps
}) {
  return (
    <TabButton onClick={onClick} {...restProps}>
      {children}
    </TabButton>
  );
};
Dashboard.UlList = function DashboardUlList({ children, ...restProps }) {
  return <UlList {...restProps}>{children}</UlList>;
};
Dashboard.ListItem = function DashboardListItem({ children }) {
  return <ListItem>{children}</ListItem>;
};
Dashboard.FitToWidthDiv = function DashboardFitToWidthDiv({
  children,
  ...restProps
}) {
  return <FitToWidthDiv {...restProps}>{children}</FitToWidthDiv>;
};

Dashboard.Notification = function DashboardNotification({
  notifications,
  ...restProps
}) {
  return (
    <Notification {...restProps}>
      <img src={bell} />
    </Notification>
  );
};

Dashboard.ProfileNav = function DashboardProfileNav({
  fname,
  lname,
  email,
  photo,
  ...restProps
}) {
  return (
    <ProfileNav {...restProps}>
      <div className="icon-ctn">
        <div className="icon overflow-hidden">
          {photo ? (
            <img
              src={"data:image/jpeg;charset=utf-8;base64," + photo}
              alt="profile image"
              className="w-full h-full"
            />
          ) : (
            fname?.charAt(0)
          )}
        </div>
        <span></span>
      </div>
      <div className="user-info-ctn">
        <h3>
          {fname} {lname}
        </h3>
        <p>{email}</p>
      </div>
    </ProfileNav>
  );
};

Dashboard.TableCtn = function DashboardTableCtn({ children, ...restProps }) {
  return <TableCtn {...restProps}>{children}</TableCtn>;
};
Dashboard.Td = function DashboardTd({ children, ...restProps }) {
  return <Td {...restProps}>{children}</Td>;
};
Dashboard.Loader = function DashboardLoader({ children, ...restProps }) {
  return (
    <Loader {...restProps}>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Loader>
  );
};
// Input select
const customStyles = {
  option: (provided, state) => {
    return {
      ...provided,
      color: "#4b1616",
      backgroundColor: "#FFFBFB",
    };
  },
};
Dashboard.SearchSelect = function DashboardSearchSelect({
  selectedOption,
  handleChange,
  options,
  placeholder,
  valueName,
  titleName,
  ...restProps
}) {
  return (
    <SearchSelect
      value={selectedOption}
      onChange={handleChange}
      styles={customStyles}
      options={options}
      {...restProps}
      placeholder={placeholder}
    />
  );
};
Dashboard.ErrorHandler = function DashboardErrorHandler(errors) {
  const [parsedError, setParsedError] = useState(false);
  useEffect(() => {
    console.log(errors);
    let x = new Array();
    for (const i in errors.errors) {
      console.log("parsed", i, errors.email, "parsed error");
    }
    console.log("all the error are", x);
    setParsedError(x);
  }, []);
  return (
    <ErrorMessageCtn>
      {/* {parsedError?.map((err) => {
        <p>{err}</p>;
      })} */}
    </ErrorMessageCtn>
  );
};

Dashboard.InputGroup = function DashboardInputGroup({
  label,
  input,
  placeholder,
  name,
  errorMessage,
  error,
  children,
  type,
  ...restProps
}) {
  // console.log("input info",error, errorMessage)
  let id = "input" + Math.round(Math.random() * 400);
  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <input
        name={name}
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : label}
        className="form-control"
        id={id}
        {...restProps}
      />
      {error && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
    </InputGroup>
  );
};
Dashboard.InputGroupTextArea = function DashboardInputGroupTextArea({
  label,
  input,
  placeholder,
  name,
  errorMessage,
  error,
  children,
  type,
  ...restProps
}) {
  // console.log("input info",error, errorMessage)
  let id = "input" + Math.round(Math.random() * 400);
  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <textarea
        name={name}
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : label}
        className="form-control"
        id={id}
        {...restProps}
      ></textarea>

      {error && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
    </InputGroup>
  );
};
Dashboard.InputGroupSelect = function DashboardInputGroupSelect({
  label,
  input,
  placeholder,
  name,
  errorMessage,
  error,
  children,
  options,
  ...restProps
}) {
  // console.log("input info",error, errorMessage)
  // console.log("input info select",options)
  let id = "input" + Math.round(Math.random() * 400);
  return (
    <InputGroup>
      <label htmlFor={id}>{label}</label>
      <select name={name} className="form-control" id={id} {...restProps}>
        <option value={""}>{placeholder ? placeholder : label}</option>
        {options.map((option, index) => {
          // console.log("index val", index);
          return (
            <option value={option.name} key={index}>
              {option.name}
            </option>
          );
        })}
      </select>
      {error && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
    </InputGroup>
  );
};
Dashboard.CustomButton = function DashboardCustomButton({
  width,
  children,
  bg,
  bgHover,
  color,
  ...restProps
}) {
  return (
    <CustomButton
      {...restProps}
      width={width}
      bg={bg}
      bgHover={bgHover}
      color={color}
    >
      {children}
    </CustomButton>
  );
};

Dashboard.OverviewCard = function DashboardOverviewCard({
  background,
  bgColor,
  bgColorSec,
  bgPos,
  icon,
  caption,
  subCaption,
  children,
  ...restProps
}) {
  return (
    <OverviewCard
      {...restProps}
      bgPos={bgPos}
      background={background}
      bgColor={bgColor}
      bgColorSec={bgColorSec}
    >
      {icon && (
        <div className="icon_ctn ">
          <img className="img-fluid" src={icon} />
        </div>
      )}
      <p className="mt-4">{caption}</p>
      {subCaption && <h5 className="mt-4">₦{subCaption}</h5>}
      {children}
    </OverviewCard>
  );
};
Dashboard.OverviewDataCard = function DashboardOverviewDataCard({
  bgColorSec,

  icon,
  caption,
  value,
  children,
  ...restProps
}) {
  return (
    <OverviewDataCard {...restProps} bgColorSec={bgColorSec}>
      {icon && (
        <div className="icon_ctn">
          <img className="img-fluid" src={icon} />
        </div>
      )}
      <div>
        {" "}
        <p>{caption}</p>
        {value && <h5 className="mt-1">{value}</h5>}
        {children}
      </div>
    </OverviewDataCard>
  );
};
Dashboard.SideNav = function DashboardSideNav({ children, ...restProps }) {
  return <SideNav {...restProps}>{children}</SideNav>;
};
Dashboard.NavButton = function DashboardNavButton({
  children,
  onClick,
  ...restProps
}) {
  return (
    <NavButton onClick={onClick} {...restProps}>
      {children}
    </NavButton>
  );
};
