import GaugeChart from "react-gauge-chart";
import TipsCard from "../TipsCard";

export default function TipsOneTipThree(props) {
  return (
    <TipsCard {...props}>
      <div className="p-[38px]">
        <GaugeChart
          id="gauge-chart6"
          colors={["#f7694f", "#F5CC19", "#19A641"]}
          percent={0.57}
          arcsLength={[0.3, 0.5, 0.2]}
          textColor={"purple"}
          hideText={true}
          needleColor={"#4c4b4b"}
          needleBaseColor={"#2d2c2c"}
        />
        <h3 className="text-center fw-700 my-3 mt-2">
          355 <span className="fs-14">/ 850</span>
        </h3>
      </div>
      <div className="p-[38px] bg-[#031821]">
        <h4 className="font-bold text-[19.2px] leading-[27px] text-white mb-3">
          Increase your credit score
        </h4>
        <p className="text-sm leading-[26px] text-white max-w-[75%]">
          Your credit score determines your access to credit, the more you pay
          back your bad loans, the more it improves.
        </p>
      </div>
    </TipsCard>
  );
}
