import React from "react";
import CreditScoreDash from "../../../dashboardcomponents/creditscore/creditscoredashboard";
import { HeaderText } from "../styles/styled";
import nodata_banner from "../../../assets/svgs/nodata_banner.svg";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../assets/images/aladdin_logo.png";
import mastercard_logo from "../../../assets/images/mastercard_logo.png";
import message_sent_icon from "../../../assets/gifs/message-sent-successfully-plane.gif";
import { useState } from "react";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateActivatedAmount,
  getActiveProgressDot,
  getFormattedNumber,
} from "../../../utils/functions";
import { useRef } from "react";
import { useEffect } from "react";
import { formatPlanDate } from "../pendingplans/pendingplans";
import { Icon } from "@iconify/react";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";
import Dashboard from "..";
import { Alert, Skeleton } from "@mui/material";
import moment from "moment/moment";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import Paginator from "../../../dashboardcomponents/paginator/paginator";
import TransactionTableLoader from "../../../dashboardcomponents/transactiontableloader/transactiontableloader";

const ViewNextPaymentPage = () => {
  const [formData, setFormData] = useState({ payment_method: "" });
  const [cardForm, setCardForm] = useState({
    save_card: false,
    amount: "10,000",
  });

  function handleInput(e) {
    setFormData((prev) => ({
      payment_method: e.target.name,
    }));
  }

  function handleCardInput(e) {
    setCardForm((prev) => ({ [e.target.name]: e.target.checked }));
  }

  function handleOffCycleInput(e) {
    setCardForm((prev) => ({
      ...prev,
      amount: e.target.value.toLocaleString(),
    }));
  }

  const [batchData, setBatchData] = useState(null);
  const [loadingBatchData, setLoadingBatchData] = useState(true);
  const { restructureId } = useParams();
  const navigate = useNavigate();

  const [plans, setPlans] = useState(null);
  const [maximumPaymentPeriod, setMaximumPaymentPeriod] = useState(null);
  const [plansLoading, setPlansLoading] = useState(true);

  async function fetchPlans(ref) {
    setPlansLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { batch_plans, maximum_payment_period },
        },
      } = await postRequest("/fetch_plans", {
        batch_ref: ref,
      });

      if (status == "success") {
        setPlans(batch_plans);
        setMaximumPaymentPeriod(maximum_payment_period);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setPlansLoading(false);
  }

  async function fetchBatchDetails() {
    setLoadingBatchData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { repayment_schedule },
          },
        },
      } = await getRequest("/fetch_next_repayment_schedule");

      if (status == "success") {
        setBatchData({
          ...repayment_schedule[0],
        });
        setFormData((prev) => ({
          ...prev,
        }));
        setDueDate(repayment_schedule[0]?.rest_due);
        // await fetchPlans(repayment_schedule[0]?.batch_ref);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBatchData(false);
  }

  const [transactions, setTransactions] = useState(null);
  const [loadingTransactions, setLoadingTransactions] = useState(true);

  async function fetchTransactions() {
    setLoadingTransactions(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { payment_history },
          },
        },
      } = await getRequest(
        "/fetch_borrower_payment_by_restructure_id/" + restructureId
      );
      if (status == "success") {
        setTransactions({
          ...payment_history,
        });
      } else {
        throw new Error(message);
      }
    } catch (err) {
      // err?.response?.data?.message &&
      //   showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingTransactions(false);
  }

  const [changePaymentMethodLoading, setChangePaymentMethodLoading] =
    useState(false);
  const changePaymentMethodModalCloseBtn = useRef(null);

  function handleInput(e) {
    let name = e.target.name;
    let val = e.target.value;
    console.log(name, val);
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  }

  async function changePaymentMethod() {
    setChangePaymentMethodLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/update_payment_method", {
        restructure_id: restructureId,
        payment_method: formData.payment_method,
      });
      if (status == "success") {
        showSuccessMessage(message);
        setBatchData((prev) => ({
          ...prev,
          payment_method:
            formData?.payment_method == "0" ? "manual" : "automatic",
        }));
        changePaymentMethodModalCloseBtn?.current?.click();
        console.log(info);
        if (formData?.payment_method == 1 && !info.card_linked) {
          await startTokenization();
        }
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
    setChangePaymentMethodLoading(false);
  }

  const [plannedPaymentLoading, setPlannedPaymentLoading] = useState(false);
  const [offCyclePaymentLoading, setOffCyclePaymentLoading] = useState(false);

  async function initPayment(params) {
    try {
      let callback_url =
        window?.location?.protocol +
        "//" +
        window?.location?.host +
        "/dashboard/payments/confirm_payment/" +
        restructureId;
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/customers_make_payment", {
        restructure_id: restructureId,
        callback_url,
        amount: params?.amount || batchData?.activated_plan[1],
        ...params,
      });

      if (status == "success") {
        window?.location?.replace(info?.authorization_url);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
  }

  async function startTokenization() {
    let params = { payment_type: 2, amount: 50, just_token: true };
    await initPayment(params);
  }

  async function startOffCyclePayment() {
    setOffCyclePaymentLoading(true);
    let params = { payment_type: 2, amount: cardForm?.amount };
    await initPayment(params);
    setOffCyclePaymentLoading(false);
  }

  async function startPlannedPayment() {
    setPlannedPaymentLoading(true);
    let params = { payment_type: 1, amount: batchData?.amount };
    await initPayment(params);
    setPlannedPaymentLoading(false);
  }

  const [dueDate, setDueDate] = useState();
  const [dueDateError, setDueDateError] = useState("");
  const [dueFormIsValid, setDueFormIsValid] = useState(false);
  const [saveDueDateLoading, setSaveDueDateLoading] = useState(false);
  const extendDueDateModalCloseBtn = useRef(null);

  function handleExtendDateInput(e) {
    let val = e?.target?.value;

    if (val) {
      if (new Date(val).getTime() <= new Date().getTime()) {
        setDueDateError("New due date must be greater than today");
        setDueFormIsValid(false);
        return;
      }

      if (new Date(val).getTime() <= new Date(batchData?.due_date).getTime()) {
        setDueDateError("New due date must be greater than previous due date");
        setDueFormIsValid(false);
        return;
      }

      if (
        new Date(val).getTime() >
        new Date(
          moment
            .utc()
            .add(parseInt(maximumPaymentPeriod), "months")
            .toISOString()
            .substring(0, 10)
        ).getTime()
      ) {
        setDueDateError("New due date is greater maximum allowed period");
        setDueFormIsValid(false);
        return;
      }

      setDueDate(val.substring(0, 10));
      setDueDateError("");
      setDueFormIsValid(true);
    }
  }

  async function extendDueDate() {
    setSaveDueDateLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/extend_due_date", {
        restructure_id: restructureId,
        extension: dueDate,
      });

      if (status == "success") {
        showSuccessMessage(message);
        setBatchData((prev) => ({ ...prev, due_date: dueDate }));
        extendDueDateModalCloseBtn.current.click();
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || "An error occured");
    }
    setSaveDueDateLoading(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchBatchDetails();
      fetchTransactions();
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {/* dialogs start */}
      <div
        className="modal fade"
        id="paymentConfirmedModal"
        tabIndex={-1}
        aria-labelledby="paymentConfirmedlLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title"></div>
            <div className="modal-body">
              <div className="w-full max-w-[405px] grid place-items-center">
                <img
                  src={message_sent_icon}
                  alt="Payment successful"
                  className="w-[11.5rem] h-[11.5rem]"
                />
                <h4 className="text-black font-bold text-2xl mb-4">
                  Payment Successful
                </h4>
                <p className="text-black text-sm text-center mb-[18px]">
                  <span className="text-primary">Beautiful,</span>{" "}
                  Congratulations on becoming Debt-free, kindly proceed to
                  restruture any other loan you have.
                </p>
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="font-bold text-center text-white bg-primary w-full max-w-[160px] p-[0.875rem]"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="paymentModal"
        tabIndex={-1}
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title">Payment</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="form-title">Pay ₦ {cardForm.amount}</div>
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <img
                      src={mastercard_logo}
                      alt="Master Card Logo"
                      className="absolute top-[28%] left-[10px]"
                    />
                    <input
                      type="text"
                      name="card_number"
                      id="card_number"
                      placeholder="Card Number"
                      className="custom-input icon"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="expiry_date"
                      id="expiry_date"
                      placeholder="Expiry Date"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="cvv"
                      id="cvv"
                      placeholder="Cvv"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="card_holder_name"
                      id="card_holder_name"
                      placeholder="Card holder's name"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full flex">
                    <input
                      type="checkbox"
                      name="save_card"
                      id="save_card"
                      className="custom-checkbox checked relative top-[0.19rem]"
                      checked={cardForm.save_card}
                      onChange={handleCardInput}
                    />
                    <label htmlFor="save_card" className="ml-3">
                      Save Card
                    </label>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#paymentConfirmedModal"
                      className="font-bold hover:bg-[#05aaf1] active:bg-[#05aaf1] text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="changePaymentMethodModal"
        tabIndex={-1}
        aria-labelledby="changePaymentMethodLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title py-6">Change payment method</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="w-full">
                    <div className="plan-items">
                      <div
                        className={`item ${
                          formData.payment_method == "1" ? "active" : ""
                        }`}
                      >
                        <label htmlFor="auto_payment">
                          <div className="title pr-6">Automatic Payment</div>
                          <div className="subtitle pr-6">
                            Link your card for automatic payment
                          </div>
                        </label>
                        <input
                          type="checkbox"
                          name="payment_method"
                          id="auto_payment"
                          className="icon checked"
                          checked={formData.payment_method == "1"}
                          onChange={(e) =>
                            handleInput({
                              target: { name: e.target.name, value: "1" },
                            })
                          }
                        />
                      </div>
                      <div
                        className={`item ${
                          formData.payment_method == "0" ? "active" : ""
                        }`}
                      >
                        <label htmlFor="manual_payment">
                          <div className="title pr-6">Manual Payment</div>
                          <div className="subtitle pr-6">
                            Pay the stipulated amount using a payment gateway
                          </div>
                        </label>
                        <input
                          type="checkbox"
                          name="payment_method"
                          id="manual_payment"
                          className="icon checked"
                          checked={formData.payment_method == "0"}
                          onChange={(e) =>
                            handleInput({
                              target: { name: e.target.name, value: "0" },
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      onClick={changePaymentMethod}
                      className="font-bold text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      {changePaymentMethodLoading ? (
                        <span className="mx-auto">
                          {" "}
                          <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                        </span>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={changePaymentMethodModalCloseBtn}
              >
                Cancel change payment method
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="extendDueDateModal"
        tabIndex={-1}
        aria-labelledby="extendDueDateModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title py-6">Select new payment date</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  {dueDateError && (
                    <Alert variant="standard" color="error" className="w-full">
                      {dueDateError}
                    </Alert>
                  )}
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="date"
                      name="date"
                      id="date"
                      placeholder="Date"
                      className="custom-input"
                      value={dueDate}
                      onInput={handleExtendDateInput}
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      disable={!dueFormIsValid}
                      onClick={extendDueDate}
                      className="font-bold text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      {saveDueDateLoading ? (
                        <span className="mx-auto">
                          {" "}
                          <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                        </span>
                      ) : (
                        "Complete"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={extendDueDateModalCloseBtn}
                onClick={() => {
                  setDueDate(batchData?.due_date);
                  setDueFormIsValid(false);
                  setDueDateError("");
                }}
              >
                Cancel extend due date
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="offCyclePaymentModal"
        tabIndex={-1}
        aria-labelledby="offCyclePaymentModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title py-6">Off Cycle Payment</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      placeholder="Amount"
                      className="custom-input"
                      onChange={handleOffCycleInput}
                    />
                    <button
                      className="bg-transparent text-black text-xs px-3"
                      onClick={() => {
                        setCardForm((prev) => ({
                          ...prev,
                          amount: batchData?.activated_plan[1],
                        }));
                      }}
                    >
                      Autofill
                    </button>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      className="font-bold text-center text-white bg-primary w-full p-[0.875rem]"
                      onClick={startOffCyclePayment}
                    >
                      {offCyclePaymentLoading ? (
                        <span className="mx-auto">
                          {" "}
                          <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                        </span>
                      ) : (
                        "Complete Off Cycle Payment"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* dialogs end */}{" "}
      <div>
        <HeaderText>Payments</HeaderText>
        <div className="mt-4 w-full gap-6 grid grid-cols-12">
          <div className="col-span-full md:col-span-8">
            <div className="grid grid-cols-1 gap-6">
              <div className="one w-full mt-5 mb-10">
                <div className="w-full mb-[27px] rounded-[8px] border-2 border-primary overflow-x-auto">
                  {loadingBatchData ? (
                    <div className="min-w-[500px]">
                      <div className="header mb-2 py-[35px] px-[35px] border-b border-[rgba(31,_137,_219,_0.19)]">
                        <div className="name-logo grid items-center">
                          <div className="flex gap-5 items-center">
                            <Skeleton
                              variant="circular"
                              component="div"
                              className="logo logo_cirle_lg w-[3.625rem] h-[3.625rem]"
                            />
                            <Skeleton
                              variant="text"
                              component="h4"
                              className="grid items-center text-lg font-bold h-fit w-[120px]"
                            />
                          </div>
                        </div>
                        <div className="buttons grid items-center">
                          <Skeleton
                            variant="rectangular"
                            component="button"
                            className="btn fs-14 px-4 my-auto mx-auto w-[150px] h-[30px]"
                          />
                        </div>
                      </div>
                      <div className="content py-[35px] px-[35px]">
                        <table className="w-full mb-[60px]">
                          <thead></thead>
                          <tbody>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/2 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                            <tr className="w-full text-[1.25rem]">
                              <td className="pb-4 font-bold text-black w-1/3">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/3 pl-4">
                                <Skeleton variant="text" />
                              </td>
                              <td className="pb-4 text-typography-4 w-1/2 pl-4">
                                <Skeleton variant="text" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h4 className="text-xs text-primary font-bold mb-[28px]">
                          <Skeleton
                            variant="text"
                            className="text-lg w-[120px]"
                          />
                        </h4>
                        <div className="col-span-full mt-[28px]">
                          <div className="progress bg-transparent p-0">
                            <Skeleton
                              variant="rectangular"
                              className="h-[5px] rounded-[20px] w-full"
                            />
                            <div className={`progress-fill percent-0`}></div>
                            <div className="progress-dot bg-transparent">
                              <Skeleton
                                variant="circular"
                                component="div"
                                height="19.71px"
                                width="19.71px"
                                className={`rounded-full`}
                              ></Skeleton>
                              <div className="dot-label top-[20px]">
                                <Skeleton variant="text" width="50px" />
                              </div>
                            </div>
                            <div className="progress-dot bg-transparent two">
                              <Skeleton
                                variant="circular"
                                component="div"
                                height="19.71px"
                                width="19.71px"
                                className={`rounded-full`}
                              ></Skeleton>
                              <div className="dot-label top-[20px] -left-[80%]">
                                <Skeleton variant="text" width="50px" />
                              </div>
                            </div>
                            <div className="progress-dot bg-transparent three">
                              <Skeleton
                                variant="circular"
                                component="div"
                                height="19.71px"
                                width="19.71px"
                                className={`rounded-full`}
                              ></Skeleton>
                              <div className="dot-label top-[20px] -left-[80%]">
                                <Skeleton variant="text" width="50px" />
                              </div>
                            </div>
                            <div className="progress-dot bg-transparent four">
                              <Skeleton
                                variant="circular"
                                component="div"
                                height="19.71px"
                                width="19.71px"
                                className={`rounded-full`}
                              ></Skeleton>
                              <div className="dot-label top-[20px] -left-[80%]">
                                <Skeleton variant="text" width="50px" />
                              </div>
                            </div>
                            <div className="progress-dot bg-transparent five">
                              <Skeleton
                                variant="circular"
                                component="div"
                                height="19.71px"
                                width="19.71px"
                                className={`rounded-full`}
                              ></Skeleton>
                              <div className="dot-label top-[20px] -left-[150%]">
                                <Skeleton variant="text" width="50px" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : batchData ? (
                    <div className="min-w-[500px]">
                      <div className="header mb-2 py-[35px] px-[35px] border-b border-[rgba(31,_137,_219,_0.19)]">
                        <div className="name-logo grid items-center">
                          <div className="flex gap-5">
                            <CompanyLogo
                              logo={batchData?.company_logo}
                              name={batchData?.company_name}
                            />
                            <h4 className="grid items-center text-[#393939] text-[16px] leading-[22px] font-bold">
                              {batchData?.company_name}
                            </h4>
                          </div>
                        </div>
                        <div className="buttons grid items-center">
                          <button
                            className="btn fs-14 btn_primary px-4 my-auto mx-auto"
                            onClick={startPlannedPayment}
                          >
                            {plannedPaymentLoading ? (
                              <span className="mx-auto">
                                {" "}
                                <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                              </span>
                            ) : (
                              "Proceed to payment"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="content py-[35px] px-[35px]">
                        <table className="w-full mb-[60px]">
                          <thead></thead>
                          <tbody>
                            <tr className="w-full">
                              <td className="pb-4 font-bold text-black">
                                Next Repayment Date:
                              </td>
                              <td className="pb-4 text-typography-4">
                                {formatPlanDate(batchData?.date) || ""}
                              </td>
                            </tr>
                            <tr className="w-full text-[1rem] leading-[1.375rem]">
                              <td className="pb-4 font-bold text-black">
                                Next Repayment Amount:
                              </td>
                              <td className="pb-4 text-typography-4">
                                ₦
                                {getFormattedNumber(
                                  parseInt(batchData?.amount || 0)
                                ) || 0.0}
                              </td>
                            </tr>
                            <tr className="w-full text-[1rem] leading-[1.375rem]">
                              <td className="pb-4 font-bold text-black">
                                Payment Method:
                              </td>
                              <td className="pb-4 text-typography-4 capitalize">
                                {batchData?.payment_method == "0"
                                  ? "Manual"
                                  : "Automatic"}
                              </td>
                              <td className="pb-4 text-typography-4">
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#changePaymentMethodModal"
                                  className="text-primary bg-transparent font-bold px-0"
                                >
                                  Change payment method
                                </button>
                              </td>
                            </tr>
                            <tr className="w-full text-[1rem] leading-[1.375rem]">
                              <td className="pb-4 font-bold text-black">
                                Amount Due:
                              </td>
                              <td className="pb-4 text-typography-4">
                                ₦
                                {getFormattedNumber(
                                  parseInt(batchData?.activated_amount) -
                                    parseInt(batchData?.total_paid || 0)
                                ) || 0.0}
                              </td>
                            </tr>
                            <tr className="w-full text-[1rem] leading-[1.375rem]">
                              <td className="pb-4 font-bold text-black">
                                Total Paid:
                              </td>
                              <td className="pb-4 text-typography-4">
                                ₦
                                {getFormattedNumber(
                                  parseInt(batchData?.total_paid || 0)
                                ) || 0.0}
                              </td>
                            </tr>
                            <tr className="w-full">
                              <td className="pb-4 font-bold text-black">
                                Payment Due Date:
                              </td>
                              <td className="pb-4 text-typography-4">
                                {formatPlanDate(batchData?.rest_due) || ""}
                              </td>
                              <td className="pb-4 text-typography-4">
                                <button
                                  data-bs-toggle="modal"
                                  data-bs-target="#extendDueDateModal"
                                  className="text-primary bg-transparent font-bold px-0"
                                  onClick={() => {
                                    setDueDate(batchData?.rest_due);
                                  }}
                                >
                                  Extend due date
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <h4 className="text-xs text-primary font-bold mb-[28px]">
                          Payment Progress
                        </h4>
                        <div className="col-span-full mt-[28px]">
                          <div className="progress">
                            <div
                              className={`progress-fill percent-${(
                                (100 / parseInt(batchData?.activated_amount)) *
                                parseInt(batchData?.total_paid)
                              ).toFixed(0)}`}
                            ></div>
                            <div
                              className={`progress-dot ${
                                getActiveProgressDot(
                                  batchData?.total_paid,
                                  (0 / 100) *
                                    parseInt(batchData?.activated_amount)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="dot-label">
                                ₦
                                {getFormattedNumber(
                                  (0 / 100) *
                                    parseInt(batchData?.activated_amount),
                                  0
                                )}
                              </div>
                            </div>
                            <div
                              className={`progress-dot two ${
                                getActiveProgressDot(
                                  batchData?.total_paid,
                                  (25 / 100) *
                                    parseInt(batchData?.activated_amount)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="dot-label">
                                ₦
                                {getFormattedNumber(
                                  (25 / 100) *
                                    parseInt(batchData?.activated_amount),
                                  0
                                )}
                              </div>
                            </div>
                            <div
                              className={`progress-dot three ${
                                getActiveProgressDot(
                                  batchData?.total_paid,
                                  (50 / 100) *
                                    parseInt(batchData?.activated_amount)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="dot-label">
                                ₦
                                {getFormattedNumber(
                                  (50 / 100) *
                                    parseInt(batchData?.activated_amount),
                                  0
                                )}
                              </div>
                            </div>
                            <div
                              className={`progress-dot four ${
                                getActiveProgressDot(
                                  batchData?.total_paid,
                                  (75 / 100) *
                                    parseInt(batchData?.activated_amount)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="dot-label">
                                ₦
                                {getFormattedNumber(
                                  (75 / 100) *
                                    parseInt(batchData?.activated_amount),
                                  0
                                )}
                              </div>
                            </div>
                            <div
                              className={`progress-dot five ${
                                getActiveProgressDot(
                                  batchData?.total_paid,
                                  (100 / 100) *
                                    parseInt(batchData?.activated_amount)
                                )
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="dot-label">
                                ₦
                                {getFormattedNumber(
                                  (100 / 100) *
                                    parseInt(batchData?.activated_amount),
                                  0
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div className="p-[29px] rounded-[8px] flex flex-wrap md:flex-nowrap gap-4 justify-between bg-[#F9FDFE] border border-[#AFE7FE]">
                  <h2 className="text-[16px] leading-[22px] text-black font-[600]">
                    Make additional payment outside your restructured payment
                    plan cycle.
                  </h2>
                  <div className="buttons grid items-center">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#offCyclePaymentModal"
                      className="btn fs-14 btn_primary text-xs font-[800] px-4 my-auto mx-auto"
                    >
                      Off Cycle Repayment
                    </button>
                  </div>
                </div>
              </div>
              <div className="two w-full">
                <h4 className="font-bold ml-3 mt-5 mb-10">
                  Transaction History
                </h4>
                {loadingTransactions ? (
                  <TransactionTableLoader />
                ) : transactions?.data?.length > 0 ? (
                  <>
                    <div className="px-3 w-full overflow-auto">
                      <table className="w-full min-w-[548px]">
                        <thead>
                          <tr className="bg-[#F8F8F9] text-sm text-[#393939] border-y-[20px] border-transparent">
                            <th className="pl-[16px] min-w-[100px]">Company</th>
                            <th className="py-[10px] min-w-[100px]">Date</th>
                            <th className="py-[10px] pr-6">Amount</th>
                            <th className="py-[10px] min-w-[120px]">
                              Ref/Session ID
                            </th>
                            <th className="py-[10px] pr-[16px]">
                              Payment Channel
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.data?.map((transaction, idx) => {
                            console.log(transaction);
                            return (
                              <tr
                                key={idx}
                                className="border-b-[20px] text-[#555555] border-transparent text-sm"
                              >
                                <td className="py-[5px] pl-[16px] flex gap-2 pr-8">
                                  <img
                                    src={transaction.company_logo}
                                    alt="logo"
                                    className="logo logo_cirle_lg w-[45px] h-[45px]"
                                  ></img>
                                  <h4 className="grid items-center font-bold">
                                    {transaction.company_name}
                                  </h4>
                                </td>
                                <td className="py-[5px]">
                                  {formatPlanDate(transaction.created_at)}
                                </td>
                                <td className="py-[5px]">
                                  ₦{getFormattedNumber(transaction.amount_paid)}
                                </td>
                                <td className="py-[5px]">
                                  {transaction.payment_ref}
                                </td>
                                <td className="py-[5px] pr-[16px] capitalize">
                                  {transaction.payment_type}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="px-3 w-full">
                      <Paginator
                        data={transactions}
                        setData={setTransactions}
                        setLoading={setLoadingTransactions}
                      />
                    </div>
                  </>
                ) : (
                  <EmptyPlansTable text="There are no transactions to show at the moment" />
                )}
              </div>
            </div>
          </div>{" "}
          <div className="col-span-full mt-5 md:col-span-4">
            <CreditScoreDash
              showRepaymentStatus={false}
              showWithdrawStatus={false}
            ></CreditScoreDash>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNextPaymentPage;
