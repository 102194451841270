import { Navigate, useNavigate } from "react-router-dom";
import { CookieStorage } from "cookie-storage";

export const cookieStorage = new CookieStorage({ path: "/" });

export const setToStorage = (itemName, itemData) => {
  let name = itemName;
  let data = itemData;
  console.log(name, data, "item info", itemData);
  cookieStorage.setItem(`${name}`, data);
};

export const removeFromStorage = (key) => {
  cookieStorage.removeItem(key);
};

export const EnterDashboard = () => {
  console.log("ready to navigate");
  return <Navigate to="/dashboard/search" />;
};
