import { Icon } from "@iconify/react";
import {
  baseURL,
  getRequest,
  postRequest,
} from "../../utils/httprequests/requests";
import { showErrorMessage } from "../../utils/notification";
import "./styles.scss";
import prev_icon from "../../assets/svgs/go_back_icon.svg";
import next_icon from "../../assets/svgs/go_next_icon.svg";
import last_icon from "../../assets/svgs/go_last_icon.svg";
import first_icon from "../../assets/svgs/go_first_icon.svg";

export default function Paginator({
  data,
  setData,
  setLoading,
  method,
  params,
}) {
  async function updateData(url) {
    if (!url) return showErrorMessage("No url provided");
    setLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info, batch_plans },
        },
      } =
        method === "POST"
          ? await postRequest(url.replace(baseURL, ""), params)
          : await getRequest(url.replace(baseURL, ""));
      if (status == "success") {
        let keys = Object.keys(info);

        if (keys?.length < 2) {
          if (info[keys[0]]?.data.constructor === {}.constructor) {
            setData({
              ...info[keys[0]],
              data: Object.values(info[keys[0]]?.data),
            });
          } else {
            setData(info[keys[0]]);
          }
        } else {
          if (info?.data.constructor === {}.constructor) {
            setData({
              ...info,
              data: Object.values(info?.data),
            });
          } else {
            setData(info);
          }
        }
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }
  function goNext() {
    data?.next_page_url && updateData(data?.next_page_url);
  }
  function goPrev() {
    data?.prev_page_url && updateData(data?.prev_page_url);
  }
  function goLast() {
    data?.last_page_url &&
      !data.last_page_url.includes("page=" + data?.current_page) &&
      updateData(data?.last_page_url);
  }
  function goFirst() {
    data?.first_page_url &&
      !data?.first_page_url?.includes("page=" + data?.current_page) &&
      updateData(data?.first_page_url);
  }
  return (
    <div className="paginator-ctn">
      <div
        className="paginator-btn"
        onClick={goFirst}
        disabled={!data?.last_page_url}
      >
        <img src={first_icon} alt="" />
      </div>
      <div
        className="paginator-btn"
        onClick={goPrev}
        disabled={!data?.prev_page_url}
      >
        <img src={prev_icon} alt="" />
      </div>
      <div className="paginator-txt">
        Page {data?.current_page} of {data?.last_page}
      </div>
      <div
        className="paginator-btn"
        onClick={goNext}
        disabled={!data?.next_page_url}
      >
        <img src={next_icon} alt="" />
      </div>
      <div
        className="paginator-btn"
        onClick={goLast}
        disabled={!data?.last_page_url}
      >
        <img src={last_icon} alt="" />
      </div>
    </div>
  );
}
