import ultrabankLogo from "../../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../../assets/images/aladdin_logo.png";
import { Skeleton } from "@mui/material";

const tableArray = [
  {
    company_name: "Ultrabank",
    company_logo: ultrabankLogo,
    amount: "2,000,000.05",
    due_date: "03-Sept-2022",
  },
  {
    company_name: "Xensys Finance",
    company_logo: xensysLogo,
    amount: "100,000",
    due_date: "29-Dec-2010",
  },
  {
    company_name: "Renmoneye ",
    company_logo: renmoneyeLogo,
    amount: "40,700,000",
    due_date: "15-Jan-2022",
  },
];

const PlanLoader = () => {
  const styles = {
    borderRadius: "0.75rem",
    height: { xs: "134.9px", lg: "81px" },
    width: "100%",
  };
  return (
    <>
      <table className="fs-14 fw-600 w-100">
        <thead>
          <tr>
            <th style={{ width: "100px" }}></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableArray.map((company, i) => {
            return (
              <tr key={i}>
                <td>
                  <Skeleton
                    variant="circular"
                    className="me-3"
                    sx={{
                      width: "3.625rem",
                      height: "3.625rem",
                      borderRadius: "50%",
                      border: "1px solid #e9e9e9",
                    }}
                  ></Skeleton>
                </td>
                <td>
                  <Skeleton
                    variant="text"
                    sx={{ width: "100px", lineHeight: "35px" }}
                  ></Skeleton>
                  <Skeleton
                    variant="text"
                    sx={{ width: "130px", lineHeight: "20px" }}
                  ></Skeleton>
                </td>
                <td className="">
                  <Skeleton
                    variant="text"
                    sx={{ width: "100px", lineHeight: "35px" }}
                  ></Skeleton>
                </td>
                <td className="text-center">
                  <Skeleton
                    variant="text"
                    sx={{
                      width: "150px",
                      fontSize: "16px",
                      borderRadius: "8px",
                      padding: "16px",
                    }}
                    className="ml-3"
                  ></Skeleton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default PlanLoader;
