import React, { Component, useRef, useState } from "react";
import { Link, Navigate, resolvePath, useNavigate } from "react-router-dom";
import logologin from "../../../assets/svgs/logo.svg";
import "./style.scss";
import axios from "axios";
import { baseURL, getRequest } from "../../../utils/httprequests/requests";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/notification/index";
import {
  setToStorage,
  EnterDashboard,
  cookieStorage,
} from "../../../utils/storage/tokenstorage";

import { useEffect } from "react";
import TokenRefresh from "../../../utils/auth/tokenrefresh";
import email_icon from "../../../assets/svgs/email.svg";
import lock_icon from "../../../assets/svgs/lock.svg";
import { Icon } from "@iconify/react";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [inputType, setInputType] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [errPasswordLength, setErrPasswordLength] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const SubmitLogin = () => {
    setLoginStatus(true);

    setLoginLoading(true);

    if (ValidateForm()) {
      // console.log(ValidateForm, formData, "validated info");
      var headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(baseURL + "/customers_login", formData, { headers: headers })
        .then((response) => {
          // console.log("got i to this place", response);

          if (response.data.status == "success") {
            cookieStorage.setItem("token", response.data.data.token);
            cookieStorage.setItem("isLogged", true);
            console.log("logged in,", response.data.data.info.user);
            setToStorage("user fname", response.data.data.info.user.fname);
            setToStorage("user lname", response.data.data.info.user.lname);
            setToStorage("company_id", response.data.data.info.user.company_id);
            setToStorage("user id", response.data.data.info.user.id);
            setToStorage("admin status", response.data.data.info.user.is_admin);
            setToStorage("user email", response.data.data.info.user.email);
            setToStorage("staff status", response.data.data.info.user.is_staff);
            setToStorage("credit_score", response.data.data.info.user.score);
            setToStorage(
              "preferred_channel",
              response.data.data.info.user.preferred_channel
            );
            localStorage.setItem("photo", response.data.data.info.user.photo);
            setDisabled(false);
            setLoginStatus(false);
            setLoginSuccess(true);
            TokenRefresh();
            showSuccessMessage("Login successful");
            return navigate("/dashboard/overview");
          } else {
            /////////////// Dev testing
            // cookieStorage.setItem("token", "duihsddsiuhsusduiosd");
            // cookieStorage.setItem("isLogged", true);
            // console.log("logged in,", "Test Login");
            // setToStorage("user fname", "Samuel");
            // setToStorage("user lname", "Adeyanju");
            // setToStorage("company_id", "3949845");
            // setToStorage("user id", "8347894");
            // setToStorage("admin status", "1");
            // setToStorage("user email", "samsmart.ass59@gmail.com");
            // setToStorage("staff status", false);
            // showSuccessMessage("Login successful");
            // setDisabled(false);
            // setLoginStatus(false);
            // setLoginSuccess(true);
            // return navigate("/dashboard/overview");
            ///////////

            console.log("entered else");
            setDisabled(false);
            showErrorMessage(
              response?.data?.message || "Something went wrong , Try again"
            );
            setLoginStatus(false);
          }
        })
        .catch((error) => {
          /////////////// Dev testing
          // cookieStorage.setItem("token", "duihsddsiuhsusduiosd");
          // cookieStorage.setItem("isLogged", true);
          // console.log("logged in,", "Test Login");
          // setToStorage("user fname", "Samuel");
          // setToStorage("user lname", "Adeyanju");
          // setToStorage("company_id", "3949845");
          // setToStorage("user id", "8347894");
          // setToStorage("admin status", "1");
          // setToStorage("user email", "samsmart.ass59@gmail.com");
          // setToStorage("staff status", false);
          // showSuccessMessage("Login successful");
          // setDisabled(false);
          // setLoginStatus(false);
          // setLoginSuccess(true);
          // return navigate("/dashboard/overview");
          ///////////

          showErrorMessage(
            error?.response?.data?.message ||
              error?.message ||
              error ||
              "Something went wrong , Try again"
          );
          console.log(error, "error");

          setBadPassword(true);

          console.log("invalid password");
          setDisabled(false);
          setLoginStatus(false);
          // setLoginSuccess(true);
          // return navigate("/dashboard");
        });
    } else {
      setLoginStatus(false);
      setDisabled(false);
      console.log("i no go anywhere", formData, errors);
    }

    setLoginLoading(false);
  };
  const ValidateForm = () => {
    setDisabled(true);
    let errors = {};
    let erremail = false;
    let formIsValid = true;
    let errpasswordlength = false;

    if (formData.email == "") {
      formIsValid = false;
      erremail = true;
      errors["email"] = "*Please enter your email-ID.";
    }
    if (typeof formData.email !== undefined) {
      //regular expression for email validation

      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!pattern.test(formData.email)) {
        formIsValid = false;
        erremail = true;
        errors["email"] = "*Please enter valid email-ID.";
      }
    }

    if (!formData.password) {
      formIsValid = false;
      errpasswordlength = true;
      errors["password"] = "*Please enter your password.";
    }
    if (formData["password"].length <= 7) {
      // console.log("password check", formData["password"].length);
      errpasswordlength = true;
      formIsValid = false;
      errors["password"] = "*password less than 7 characters";
    }

    // this.setState({
    //   errpassword: errpassword,
    //   erremail: erremail,
    //   errors: errors,
    //   errpasswordlength: errpasswordlength,
    // });
    // seterrPassword(errpassword);
    setErrEmail(erremail);
    setErrors(errors);
    setErrPasswordLength(errpasswordlength);

    return formIsValid;
  };

  return (
    <div
      className="w-100 world-bg create_password_page"
      style={{ height: "100vh" }}
    >
      <div className="row h-100 w-100 justify-content-center m-0">
        <div className="col-md-5 pt-5">
          <div className="card border-0 mt-5 md:min-w-[405px]">
            <div className="card-body px-md-5 md:min-w-[405px]">
              <form
                className="my-auto mx-md-4 mx-auto pt-4 text-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  SubmitLogin();
                }}
              >
                <h3 className="form-welcome-title mb-4">
                  Login to Restructure
                </h3>
                <p className="form-welcome-subtitle mb-5">
                  Enter your email address and password
                </p>
                <div className="relative w-100  mb-alt mx-auto">
                  <img
                    src={email_icon}
                    alt=""
                    className="prepend-inner absolute top-[25%] left-[20px]"
                  />
                  <input
                    className="form-control-login form-control w-full mx-auto email-input"
                    type="email"
                    placeholder="Email "
                    name="email"
                    value={formData.email}
                    onInput={({ target }) => {
                      setFormData((prev) => ({ ...prev, email: target.value }));
                      // console.log("email changed", target.value);
                    }}
                  />{" "}
                </div>
                <div className="relative w-100  mb-5 mx-auto">
                  <img
                    src={lock_icon}
                    alt=""
                    className="prepend-inner absolute top-[25%] left-[20px]"
                  />
                  <input
                    className="form-control-login form-control w-full mx-auto password-input"
                    type={showPassword ? "text" : "password"}
                    placeholder=" password"
                    name="password"
                    value={formData.password}
                    onInput={({ target }) => {
                      setFormData((prev) => ({
                        ...prev,
                        password: target.value,
                      }));
                      // console.log("email changed", target.value);
                    }}
                  />
                  <Icon
                    icon="mdi-eye"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    className="prepend-inner text-lg absolute top-[30%] right-[18px] cursor-pointer z-10"
                  />
                </div>
                <button
                  className="btn custom_button btn-block mx-auto  login-btn mb-alt"
                  style={{ width: "307px ", alignSelf: "center" }}
                  type="submit"
                  disabled={disabled}
                >
                  {loginLoading ? (
                    <div
                      className="spinner-border text-light spinner"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Login"
                  )}
                </button>
                <p className="bottom-text text-center">
                  can't remember password?{" "}
                  <Link to="/forgot-password" className="createaccnt-redirect">
                    Forgot Password
                  </Link>
                </p>{" "}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
