import React, { Component, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "..";

import dirright from "../../../assets/svgs/right-dir.svg";
import BankDetailsPage from "../bankdetailspage/bankdetailspage";
import ChangePasswordPage from "../changepasswordpage/changepasswordpage";
import NotificationsPage from "../notificationspage/notificationspage";

const SettingsPage = () => {
  const [component, setComponent] = useState(1);
  const { state } = useLocation();
  const Items = [
    {
      name: "Change password",
      img: component === 1 ? dirright : "",
      id: 1,
      component: <ChangePasswordPage />,
    },
    {
      name: "Notifications",
      img: component === 2 ? dirright : "",
      id: 2,
      component: <NotificationsPage />,
    },
    {
      name: "Bank Details",
      img: component === 3 ? dirright : "",
      id: 3,
      component: <BankDetailsPage />,
    },
  ];
  const [componentDetails] = useState(Items);
  const [currentComponent, setCurrentComponent] = useState([Items[0]]);

  const showDetails = (id = 1) => {
    setComponent(id);
    const currentItem = componentDetails.filter((item) => id === item.id);
    setCurrentComponent(currentItem);
  };

  useEffect(() => {
    if (state?.tab) {
      showDetails(state?.tab);
    }
  }, [state]);
  return (
    <>
      <h4 className="fs-18 fw-700 mb-0">Settings</h4>
      <div className="row h100 ">
        <div className="col-md-3 p-0">
          {" "}
          <Dashboard.SideNav className="pt-md-4">
            {Items.map((item) => {
              return (
                <Dashboard.NavButton
                  key={item.id}
                  onClick={() => showDetails(item.id)}
                  className={`flex ${
                    item.id === currentComponent[0].id
                      ? "text-[#243656] font-bold"
                      : ""
                  }`}
                >
                  {item.name}{" "}
                  {item.img ? (
                    <img
                      src={item.img}
                      className="right-arrow ms-4 relative top-[3px]"
                      style={{ height: ".9rem", width: ".9rem" }}
                    />
                  ) : (
                    ""
                  )}
                </Dashboard.NavButton>
              );
            })}
          </Dashboard.SideNav>
        </div>
        <div className="col-md-9 p-0">
          {" "}
          <>{currentComponent[0].component}</>
        </div>
      </div>
    </>
  );
};
export default SettingsPage;
