import { Icon } from "@iconify/react";
import { Skeleton } from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Dashboard from "..";
import banks from "../../../utils/data/all_banks.json";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";

export default function BankDetailsPage() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [formData, setFormData] = useState({
    bank_name: "",
    bank_code: "",
    account_name: "",
    account_number: "",
  });

  const [formIsValid, setFormIsValid] = useState(false);

  function handleInput(e) {
    let name = e.target.name;
    let val = e.target.value;

    let data = { ...formData, [name]: val };

    setFormData((prev) => data);

    validateForm(data);
  }

  function validateForm(data) {
    let keys = Object.keys(data);
    let valid = {};

    for (let key of keys) {
      if (!data[key] || data[key].length < 3) {
        valid[key] = false;
      } else {
        valid[key] = true;
      }
    }

    setFormIsValid(Object.values(valid).includes(false) ? false : true);

    return Object.values(valid).includes(false) ? false : true;
  }

  const [loadingBankDetails, setLoadingBankDetails] = useState(true);

  async function fetchBankDetails() {
    setLoadingBankDetails(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/fetch_bank_details");
      if (status == "success") {
        let data = {
          ...formData,
          bank_name: info.bank_name,
          bank_code: info.bank_code,
          account_name: info.account_name,
          account_number: info.account_number,
        };
        setFormData((prev) => data);
        validateForm(data);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBankDetails(false);
  }

  const [saveBankDetailsLoading, setSaveBankDetailsLoading] = useState(false);
  const passwordModalCloseBtn = useRef(null);

  async function saveBankDetails() {
    setSaveBankDetailsLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/update_bank_details", { ...formData, password });
      if (status == "success") {
        showSuccessMessage(message);
        passwordModalCloseBtn.current.click();
      } else {
        showErrorMessage(message);
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setSaveBankDetailsLoading(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchBankDetails();
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {/* Modals start */}
      <div
        className="modal fade"
        id="confirmPasswordModal"
        tabIndex={-1}
        aria-labelledby="confirmPasswordModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title py-6">
              Confirm your password to continue
            </div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="input-ctn w-full relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      placeholder="Password"
                      className="custom-input w-full"
                      value={password}
                      onInput={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <Icon
                      icon={showPassword ? "mdi:eye" : "mdi:eye-off"}
                      className="text-2xl absolute top-[25%] right-[16px] cursor-pointer"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    ></Icon>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      disabled={!password || password.length < 6}
                      onClick={saveBankDetails}
                      className="btn font-bold text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      {saveBankDetailsLoading ? (
                        <span className="mx-auto">
                          {" "}
                          <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                        </span>
                      ) : (
                        "Confirm"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={passwordModalCloseBtn}
              >
                Cancel change bank details
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modals end */}
      <div className="row mx-3 mt-4 pt-1 gx-md-5 gy-4">
        <div className="grid md:w-2/3 form gap-6">
          <div className="text-base font-semibold text-[#555555]">
            Withdrawals are made to account numbers linked to your card
          </div>
          {loadingBankDetails ? (
            <>
              <Skeleton
                variant="rectangular"
                className="w-full h-[50px] rounded-[8px]"
              />
              <Skeleton
                variant="rectangular"
                className="w-full h-[50px] rounded-[8px]"
              />
              <Skeleton
                variant="rectangular"
                className="w-full h-[50px] rounded-[8px]"
              />
              <Skeleton
                variant="rectangular"
                className="w-full h-[50px] rounded-[8px]"
              />
              <Skeleton
                variant="text"
                className="w-[100px] text-base mx-auto rounded-[8px]"
              />
            </>
          ) : (
            <>
              <div className="custom-select relative">
                <input
                  type="band_name"
                  id="bank_name"
                  name="bank_name"
                  className="custom-input w-full capitalize"
                  placeholder="Choose Bank"
                  readOnly
                  value={
                    banks
                      ?.find((i) => i?.code == formData?.bank_code)
                      ?.name?.toUpperCase() || null
                  }
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                />
                <Icon
                  icon="mdi:chevron-down"
                  className="text-2xl absolute top-[25%] right-[16px]"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                ></Icon>
                {showDropdown && (
                  <div
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}
                    className="select-options cursor-pointer mt-2 w-full py-1 rounded-[8px] bg-white shadow absolute z-10 max-h-[400px] overflow-y-auto overflow-x-hidden"
                  >
                    {banks &&
                      banks.map((bank, idx) => (
                        <div
                          className={`option w-full p-2 hover:bg-gray-200 ${
                            formData.bank_code === bank.code
                              ? "bg-gray-200"
                              : ""
                          }`}
                          onClick={() => {
                            let data = {
                              ...formData,
                              bank_code: bank.code,
                              bank_name: bank.name,
                            };
                            setFormData((prev) => data);
                          }}
                        >
                          {bank?.name?.toUpperCase()}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <div className="input-ctn">
                <input
                  type="text"
                  name="account_name"
                  id="account_name"
                  placeholder="Account Name"
                  className="custom-input w-full"
                  value={formData.account_name}
                  onInput={handleInput}
                />
              </div>
              <div className="input-ctn">
                <input
                  type="text"
                  name="account_number"
                  id="account_number"
                  placeholder="Account Number"
                  className="custom-input w-full"
                  value={formData.account_number}
                  onInput={handleInput}
                />
              </div>
              <div className="input-ctn">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#confirmPasswordModal"
                  disabled={!formIsValid}
                  className="btn font-bold hover:bg-[#05aaf1] active:bg-[#05aaf1] text-center text-white bg-primary w-full p-[0.875rem]"
                >
                  Save
                </button>
              </div>
              <button className="text-center text-xs font-bold text-black">
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}
