import React, { Component } from "react";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";

import "./style.scss";
import { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import { useEffect } from "react";
import { showErrorMessage } from "../../../utils/notification";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import { formatPlanDate } from "../pendingplans/pendingplans";
import {
  calculateActivatedAmount,
  getCustomPlanLabel,
  getFormattedNumber,
} from "../../../utils/functions";
import PaymentPlanLoader from "./components/PaymentPlanLoader";
import { useRef } from "react";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import {
  allRestructurePlansInit,
  frequencyOptions,
  lobbyFormInit,
} from "../../../utils/data/static";
import cancel from "../../../assets/svgs/cancel.svg";
import delete_icon from "../../../assets/svgs/blue_delete.svg";
import { Alert, TextField } from "@mui/material";
import Paginator from "../../../dashboardcomponents/paginator/paginator";

const bank = {
  name: "Ultrabank",
  image: ultrabankLogo,
  amount: "2,000,000.05",
  due_date: "03-Sept-2022",
};

const RestructureLoan = () => {
  const navigate = useNavigate();
  const [view, setView] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [batchData, setBatchData] = useState(null);
  const [loadingBatchData, setLoadingBatchData] = useState(true);
  const [plans, setPlans] = useState(null);
  const [maximumPaymentPeriod, setMaximumPaymentPeriod] = useState(null);
  const [plansLoading, setPlansLoading] = useState(true);
  const { restructureId } = useParams();
  const [plansToShow, setPlansToShow] = useState(5);

  async function fetchPlans(ref) {
    setPlansLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { batch_plans, maximum_payment_period },
        },
      } = await postRequest("/fetch_plans", {
        batch_ref: ref,
      });

      if (status == "success") {
        setPlans(batch_plans);
        setMaximumPaymentPeriod(maximum_payment_period);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setPlansLoading(false);
  }

  function loadMorePlans() {
    if (plansToShow >= plans.length - 1) return;
    setPlansToShow(plansToShow + 5);
  }

  async function fetchBatchDetails() {
    setLoadingBatchData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { details },
          },
        },
      } = await getRequest("/fetch_borrower_details/" + restructureId);

      if (status == "success") {
        if (details.status != 1) {
          showErrorMessage("This loan has already been activated");
          navigate("/dashboard/overview");
          return;
        }
        setBatchData(details);
        fetchPlans(details?.batch_ref);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBatchData(false);
  }

  function handleInput(plan) {
    let form = allRestructurePlansInit.find((i) => i.data[0] === plan.id);
    // form.inputs.push({
    //   label: "Select Options",
    //   index: 6,
    //   placeholder: "--select option--",
    //   options: frequencyOptions,
    //   type: "select",
    // });
    // form.inputs.push({
    //   label: "Select Date",
    //   index: 7,
    //   placeholder: "--select dates--",
    //   type: "date",
    // });

    console.log(form.data);

    let borrowerInputs = form?.data?.filter(
      (i) => i && i?.toString()?.includes("X")
    );

    let staticQ1 = form?.data?.findIndex(
      (i) => i && i?.toString().includes("Q1")
    );

    let staticY1 = form?.data?.findIndex(
      (i) => i && i?.toString().includes("Y1")
    );

    if (borrowerInputs?.length > 0) {
      for (let i = 0; i < borrowerInputs.length; i++) {
        let index = form?.data?.indexOf(borrowerInputs[i]);
        form.data[index] = plan?.data[i + 1];
      }
    }

    if (staticQ1 > -1) {
      form.data[staticQ1] = batchData?.activated_amount;
    }

    // console.log(calculateActivatedAmount(form?.data, batchData));

    setBatchData((prev) => ({
      ...prev,
      activated_amount: calculateActivatedAmount(form?.data, prev),
    }));

    if (staticY1 > -1) {
      if (plan.data[0] === 24) {
        form.data[staticY1] =
          parseInt(batchData?.activated_amount) / form.data[4];
      } else if (plan.data[0] === 25) {
        form.data[staticY1] =
          parseInt(batchData?.activated_amount) / form.data[4];
      } else if (plan.data[0] === 26) {
        form.data[staticY1] =
          (parseInt(batchData?.activated_amount) -
            (form?.data[4] / 100) *
              parseInt(batchData?.current_amount_payable)) /
          form.data[5];
      }
    }

    setSelectedPlan(plan);

    setLobbyForm((prev) => ({ ...form, data: [...form.data] }));

    if (form?.inputs?.length > 0) {
      lobbyFormModalOpenBtn?.current?.click();
      setLobbyFormIsValid(false);
      setLobbyFormErrors(null);
    }
  }

  const [showDropdown, setShowDropdown] = useState(false);
  const [lobbyForm, setLobbyForm] = useState(lobbyFormInit);
  const [lobbyFormErrors, setLobbyFormErrors] = useState(null);
  const [lobbyFormIsValid, setLobbyFormIsValid] = useState(false);
  const lobbyFormModalCloseBtn = useRef(null);
  const lobbyFormModalOpenBtn = useRef(null);

  const HandleLobbyFormChange = (e) => {
    let targetIndex = parseInt(e.target.name.split(" ")[1]); // set input name property as "input 0" for example
    let val = e.target.value;

    let lobbyData = { ...lobbyForm };

    if (e?.target?.type === "date") {
      if (lobbyData?.data[targetIndex]?.includes(val)) return;

      lobbyData.data[targetIndex] = lobbyData.data[targetIndex]
        ? [...lobbyData.data[targetIndex], val]
        : [val];
    } else {
      lobbyData.data[targetIndex] = parseInt(val);
    }

    setLobbyForm((prev) => ({
      ...prev,
      ...lobbyData,
      inputs: lobbyData?.inputs?.map((obj) => ({ ...obj })),
      validator: lobbyData?.validator || prev.validator,
      data: [...lobbyData.data],
      errMessage: "",
    }));

    // console.log(" this is the value changed", targetname, val, lobbyForm);

    let { valid, errors } = lobbyForm.validator(
      lobbyData?.data,
      maximumPaymentPeriod,
      batchData
    );

    if (valid) {
      setLobbyFormIsValid(true);
      setLobbyFormErrors(null);
    } else {
      setLobbyFormIsValid(false);
      setLobbyFormErrors(errors);
    }
  };

  function removeDate(input_index, data_index) {
    let lobbyData = { ...lobbyForm };
    lobbyData?.data[input_index]?.splice(data_index, 1);
    setLobbyForm((prev) => ({
      ...prev,
      ...lobbyData,
      inputs: lobbyData.inputs.map((obj) => ({ ...obj })),
      validator: lobbyData?.validator || prev.validator,
      data: [...lobbyData.data],
      errMessage: "",
    }));
  }

  function savePlan() {
    localStorage.setItem("lobby_data", JSON.stringify(lobbyForm.data));
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchBatchDetails();
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {/* Start Modals */}
      <div
        className="modal fade"
        id="lobbyFormModal"
        tabIndex={-1}
        aria-labelledby="lobbyFormModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-size-lg">
          <div className="modal-content px-5 py-2 pb-4 modal-size">
            <div className="modal-header border-0 pb-0 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-x"
                style={{ marginRight: "-25px" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={lobbyFormModalCloseBtn}
                onClick={() => {
                  if (!lobbyFormIsValid) {
                    setLobbyForm((prev) => ({
                      lobbyFormInit,
                      data: [...lobbyFormInit.data],
                    }));
                    setSelectedPlan(null);
                  }
                }}
              >
                <img src={cancel} />
              </button>
            </div>
            <div className="modal-body pt-0">
              {/* <h4 className="text-center w-100 modal-caption">Add Plan</h4> */}

              {lobbyFormErrors &&
                lobbyFormErrors?.map((err) => (
                  <Alert variant="standard" color="error" className="w-full">
                    {err}
                  </Alert>
                ))}
              <div className="w-full form grid grid-cols-1 gap-4 mt-4">
                {lobbyForm?.inputs &&
                  lobbyForm?.inputs?.map((input) => (
                    <div className="input-ctn" key={input.index}>
                      <label
                        htmlFor={"input" + input.index + input.label}
                        className="text-[#1C1C1C] mb-[12px] w-full text-sm font-semibold"
                      >
                        {input.label}
                      </label>
                      {input?.type === "date" ? (
                        <>
                          <input
                            autoComplete="off"
                            id={"input" + input?.index + input?.label}
                            type="date"
                            min="1"
                            name={"input " + input?.index}
                            placeholder={input?.placeholder}
                            value={""}
                            onChange={HandleLobbyFormChange}
                            className="custom-input w-full"
                          />
                          <div className="selected-date w-full px-2 flex gap-6 flex-wrap my-3">
                            {lobbyForm?.data[input?.index] &&
                              lobbyForm?.data[input?.index]?.map(
                                (date, idx) => (
                                  <h4 className="flex">
                                    <img
                                      src={delete_icon}
                                      alt="deleted icon"
                                      className="mr-2 cursor-pointer"
                                      onClick={() =>
                                        removeDate(input?.index, idx)
                                      }
                                    />
                                    <span>{formatPlanDate(date)}</span>
                                  </h4>
                                )
                              )}
                          </div>
                        </>
                      ) : input.type === "select" ? (
                        <div className="custom-select relative">
                          <input
                            autoComplete="off"
                            id={"input" + input.index + input.label}
                            type="text"
                            min="1"
                            name={"input " + input.index}
                            placeholder={input.placeholder}
                            value={
                              input?.options?.find(
                                (i) => i?.value == lobbyForm?.data[input?.index]
                              )?.name || ""
                            }
                            className="custom-input w-full capitalize"
                            readOnly
                            onClick={() => {
                              setShowDropdown(!showDropdown);
                            }}
                          />
                          <Icon
                            icon={
                              showDropdown
                                ? "mdi:chevron-down"
                                : "mdi:chevron-right"
                            }
                            className="text-2xl absolute top-[25%] right-[16px]"
                            onClick={() => {
                              setShowDropdown(!showDropdown);
                            }}
                          ></Icon>
                          {showDropdown && (
                            <div
                              onClick={() => {
                                setShowDropdown(!showDropdown);
                              }}
                              className="select-options cursor-pointer mt-2 w-full py-1 rounded-[8px] bg-white shadow absolute z-10 max-h-[400px] overflow-y-auto overflow-x-hidden"
                            >
                              {input?.options &&
                                input?.options?.map((option, idx) => (
                                  <div
                                    className={`option w-full p-2 hover:bg-gray-200 ${
                                      lobbyForm?.data[input?.index] ===
                                      option?.value
                                        ? "bg-gray-200"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      HandleLobbyFormChange({
                                        target: {
                                          name: "input " + input?.index,
                                          value: option?.value,
                                        },
                                      })
                                    }
                                  >
                                    {option?.name}
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <input
                          autoComplete="off"
                          id={"input" + input.index + input.label}
                          type="number"
                          min="1"
                          name={"input " + input.index}
                          placeholder={input?.placeholder}
                          disabled={input?.disabled}
                          value={lobbyForm?.data[input?.index] || ""}
                          onInput={HandleLobbyFormChange}
                          className="custom-input w-full"
                        />
                      )}
                    </div>
                  ))}
                <div className="mt-[5px] w-full">
                  <button
                    className="btn font-bold text-center text-white bg-primary w-full p-[0.75rem]"
                    onClick={savePlan}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="button"
                    disabled={!lobbyFormIsValid}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Modals */}
      <div className="row restructureloan_page">
        <div className="breadcrumbs flex gap-2">
          <div className="item">Home</div>
          <div className="item divider">&gt;</div>
          <div className="item active">Restructure plan</div>
        </div>
        <div className="two-section-grid">
          <div className="left-section">
            <h3 className="font-bold text-[1rem] leading-[1.375rem] text-dash-primary px-4 mb-[46px]">
              Good News!!!{" "}
              <span className="text-dashboard">
                select a replayment plan and enjoy
              </span>{" "}
              Discounts
            </h3>
            {plansLoading ? (
              <PaymentPlanLoader />
            ) : plans?.length > 0 ? (
              <>
                <div className="plan-items grid md:grid-cols-3 gap-6 px-[16px]">
                  {[...plans]?.slice(0, plansToShow)?.map((plan, idx) => (
                    <div
                      className={`item ${
                        selectedPlan?.id === plan?.id ? "active" : ""
                      }`}
                      key={idx}
                    >
                      <label htmlFor={"plan_" + plan.id} className="subtitle">
                        {(plan?.type === "fixed"
                          ? plan?.data
                          : getCustomPlanLabel(plan.data)) || ""}
                      </label>
                      <button
                        className="hidden"
                        data-bs-toggle="modal"
                        data-bs-target="#lobbyFormModal"
                        ref={lobbyFormModalOpenBtn}
                      >
                        Open Plan Modal
                      </button>
                      <input
                        type="checkbox"
                        name={"plan_" + plan.id}
                        id={"plan_" + plan.id}
                        className={`icon ${
                          selectedPlan?.id === plan?.id ? "checked" : ""
                        }`}
                        checked={selectedPlan?.id === plan?.id}
                        onChange={() => handleInput(plan)}
                      />
                    </div>
                  ))}
                </div>
                <div className="px-[16px] pt-4 flex justify-center">
                  {plansToShow < plans?.length - 1 && (
                    <button className="btn btn-primary font-bold text-center text-white bg-primary px-[0.75rem] py-[0.5rem]">
                      Load More
                    </button>
                  )}

                  {/* <Paginator
                    data={plans}
                    setData={setPlans}
                    method="POST"
                    params={{ batch_ref: batchData?.batch_ref }}
                    setLoading={setPlansLoading}
                  /> */}
                </div>
              </>
            ) : (
              <EmptyPlansTable />
            )}
          </div>{" "}
          <div className="right-section">
            {loadingBatchData ? (
              <div className="h-full w-full grid place-items-center">
                <span className="mx-auto">
                  <Icon
                    icon="mdi:loading"
                    className="text-3xl mx-auto animate-spin"
                  />
                </span>
              </div>
            ) : batchData ? (
              <>
                <div className="logo-name flex mb-10">
                  <CompanyLogo
                    logo={batchData?.company_logo}
                    name={batchData?.company_name}
                  />
                  <div className="flex flex-col justify-center">
                    <div>
                      <h4 className="text-dash-heading font-bold text-xl">
                        {batchData?.company_name || ""}
                      </h4>
                      <p className="faded-text text-[0.625rem]">
                        Due Date: {formatPlanDate(batchData?.due_date) || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <table className="w-full">
                  <thead></thead>
                  <tbody>
                    <tr className="w-full text-[1rem] leading-[1.375rem]">
                      <td className="pb-4 font-bold text-dash-heading">
                        Amount Due:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦
                        {getFormattedNumber(
                          batchData?.current_amount_payable
                        ) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Paid:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦{getFormattedNumber(batchData?.amount_paid) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Balance:
                      </td>
                      <td className="pb-4 text-typography-4">
                        ₦
                        {getFormattedNumber(
                          parseInt(batchData?.current_amount_payable) -
                            parseInt(batchData?.amount_paid || 0)
                        ) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Due Repayment Date:
                      </td>
                      <td className="pb-4 text-typography-4">
                        {formatPlanDate(batchData?.due_date) || ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Status:
                      </td>
                      <td
                        className={`pb-4 ${
                          batchData?.status == 1
                            ? "text-secondary"
                            : batchData.status == 2
                            ? "text-primary"
                            : batchData.status == 3
                            ? "text-green-500"
                            : ""
                        }`}
                      >
                        {batchData?.status == 1
                          ? "Pending"
                          : batchData.status == 2
                          ? "Active"
                          : batchData.status == 3
                          ? "Completed"
                          : ""}
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td className="pb-4 font-bold text-dash-heading">
                        Repayment Option:
                      </td>
                      <td className="pb-4 text-typography-4">
                        {plans?.length || 0}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            ) : (
              false
            )}
          </div>
        </div>
        <div className="two-section-grid">
          <div className="my-10 md:col-span-4 mx-4">
            <Link
              to={
                !selectedPlan
                  ? false
                  : `/dashboard/restructure_loan/${batchData?.restructure_id}/payment_details`
              }
            >
              <button
                disabled={!selectedPlan}
                className="btn btn-primary font-bold text-center text-white bg-primary w-full p-[0.875rem]"
              >
                Proceed to details
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default RestructureLoan;
