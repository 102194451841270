import axios from "axios";
import React, { Component } from "react";
import { baseURL } from "../httprequests/requests";
import { cookieStorage } from "../storage/tokenstorage";

const LogOut = () => {
  console.log("clicked logout");
  var alwaysTrue = true;
  if (alwaysTrue) {
    var token = cookieStorage.getItem("token");

    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(baseURL + "/logout", { headers: headers })
      .then(function (response) {
        console.log(response);

        if (response.data.status == "success") {
          cookieStorage.clear();
          localStorage.clear();
          return window.location.replace("/login");
        } else {
          cookieStorage.clear();
          localStorage.clear();
          return window.location.replace("/login");
          console.log("something went wrong while trying to logout");
        }
      })
      .catch(function (error) {
        console.log("i no go anywhere", error);
        cookieStorage.clear();
        return window.location.replace("/login");
      });
  }
};

export default LogOut;
