import React, { Component } from "react";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import mastercard_logo from "../../../assets/images/mastercard_logo.png";
import message_sent_icon from "../../../assets/gifs/message-sent-successfully-plane.gif";
import failed_icon from "../../../assets/svgs/failel-x-circle.svg";

import "./style.scss";
import { useState } from "react";
import { useRef } from "react";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import Dashboard from "..";
import { calculateActivatedAmount } from "../../../utils/functions";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formDataInit = {
  payment_method: "auto_payment",
  data: "",
};

const PaymentMethod = () => {
  const [batchData, setBatchData] = useState(null);

  const [loadingBatchData, setLoadingBatchData] = useState(true);

  const [formData, setFormData] = useState(formDataInit);
  const [cardForm, setCardForm] = useState({ save_card: false });
  const [lobbyData, setLobbyData] = useState(null);
  const navigate = useNavigate();
  const { restructureId } = useParams();
  const [showDropdown, setShowDropdown] = useState(false);

  function handleInput(e) {
    let name = e.target.name;
    let val = e.target.value;
    console.log(name, val);
    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));
  }

  function handleCardInput(e) {
    setCardForm((prev) => ({ [e.target.name]: e.target.checked }));
  }

  const [activatePlanLoading, setActivatePlanLoading] = useState(false);

  async function activatePlan(start_now) {
    setActivatePlanLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/activate_plan", {
        restructure_id: restructureId,
        payment_method: formData?.payment_method,
        start_date:
          formData?.date?.substring(0, 10) ||
          new Date()?.toISOString()?.substring(0, 10),
        activated_plan: JSON.stringify(lobbyData),
      });

      // let status = "success";
      // let info = { card_linked: true };
      // let message = "Successfully activated plan";

      if (status == "success") {
        showSuccessMessage(message);

        if (formData?.payment_method == 1 && start_now) {
          let params = { payment_type: "1" };
          if (!info.card_linked) {
            params.payment_method = "card";
            await initPayment(params);
            // console.log("Auto Start Now", params);
          } else {
            await autoDebit();
          }
        } else if (formData?.payment_method == 1 && !start_now) {
          let params = { amount: 50, payment_type: "2", just_token: true };
          if (!info.card_linked) {
            params.payment_method = "card";
          }
          // console.log("Auto Start Later", params);
          await initPayment(params);
        } else if (formData?.payment_method == 0 && start_now) {
          let params = { payment_type: "1" };
          console.log("Manual Start Now", params);
          await initPayment(params);
        } else {
          navigate("/dashboard/payments/payment_details/" + restructureId);
        }
        // navigate("/dashboard/payments");
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err.response.message);
    }
    setActivatePlanLoading(false);
  }

  const auto_debit_failed_btn = useRef(null);
  const auto_debit_failed_modal_close_btn = useRef(null);
  const [autoDebitErrMessage, setAutoDebitErrorMessage] = useState("");
  const [loadingAutoDebit, setLoadingAutoDebit] = useState(false);

  async function autoDebit(isOpen = false) {
    setLoadingAutoDebit(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/customers_new_auto_debit", {
        restructure_id: restructureId,
        amount: lobbyData[1],
      });

      if (status == "success") {
        showSuccessMessage(message);
        setAutoDebitErrorMessage("");
        auto_debit_failed_modal_close_btn?.current?.click();
      } else {
        setAutoDebitErrorMessage(message);
        showErrorMessage(message);
        if (!isOpen) {
          auto_debit_failed_btn?.current?.click();
        }
      }
    } catch (err) {
      setAutoDebitErrorMessage(
        err?.response?.message || err?.message || "Unknown error ocurred"
      );
      showErrorMessage(
        err?.response?.message || err?.message || "Unknown error ocurred"
      );
      if (!isOpen) {
        auto_debit_failed_btn?.current?.click();
      }
      console.log(err);
    }
    setLoadingAutoDebit(false);
  }

  const [loadingUpdateCard, setLoadingUpdateCard] = useState(false);

  async function updateCardDetails() {
    setLoadingUpdateCard(true);
    await initPayment({ payment_type: "1", payment_method: "card" });
    setLoadingUpdateCard(false);
  }

  const [loadingManualPayment, setLoadingManualPayment] = useState(false);

  async function startManualPayment() {
    setLoadingManualPayment(true);
    await initPayment({ payment_type: "1" });
    setLoadingManualPayment(false);
  }

  const [paymentLoading, setPaymentLoading] = useState(false);

  async function initPayment(params) {
    setPaymentLoading(true);
    try {
      let callback_url =
        window?.location?.protocol +
        "//" +
        window?.location?.host +
        "/dashboard/payments/confirm_payment/" +
        restructureId;

      let data = {
        restructure_id: restructureId,
        callback_url,
        amount: params?.amount || lobbyData[1],
        ...params,
      };

      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/customers_make_payment", data);

      if (status == "success") {
        window?.location?.replace(info?.authorization_url);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
    setPaymentLoading(false);
  }

  async function fetchBatchDetails(lobby_data) {
    setLoadingBatchData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { details },
          },
        },
      } = await getRequest("/fetch_borrower_details/" + restructureId);

      if (status == "success") {
        if (details.status != 1) {
          showErrorMessage("This loan has already been activated");
          navigate("/dashboard/overview");
          return;
        }
        setBatchData({
          ...details,
          activated_amount: calculateActivatedAmount(lobby_data, details),
        });
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBatchData(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      let lobby_data = JSON.parse(localStorage.getItem("lobby_data"));

      if (!lobby_data || lobby_data?.length < 1) {
        showErrorMessage(
          "Please make sure you have selected a plan from the previous page"
        );
        navigate(`/dashboard/restructure_loan/${restructureId}`);
        return;
      }

      setLobbyData((prev) => [...lobby_data]);

      fetchBatchDetails(lobby_data);
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {/* Modals start */}
      <button
        data-bs-toggle="modal"
        data-bs-target="#autoDebitFailedModal"
        // className="hidden"
        ref={auto_debit_failed_btn}
      >
        Show auto debit error modal
      </button>
      <div
        className="modal fade"
        id="autoDebitFailedModal"
        tabIndex={-1}
        aria-labelledby="autoDebitFailedModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title"></div>
            <div className="modal-body">
              <div className="w-full max-w-[405px] grid place-items-center">
                <img
                  src={failed_icon}
                  alt="Payment failed icon"
                  className="w-[11.5rem] h-[11.5rem]"
                />
                <h4 className="text-black font-bold text-2xl mb-4">
                  Automatic Payment Failed
                </h4>
                <p className="text-black text-sm text-center mb-[18px]">
                  Sorry we couldn’t process your payment with your linked card
                </p>
                {autoDebitErrMessage && (
                  <p className="text-black text-sm text-center mb-[18px]">
                    Reason: “{autoDebitErrMessage}”
                  </p>
                )}
                <button
                  onClick={() => autoDebit(true)}
                  className="font-bold text-center text-white bg-primary w-full max-w-[160px] p-[0.875rem] mb-[18px]"
                >
                  {loadingAutoDebit ? (
                    <span className="mx-auto">
                      <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                    </span>
                  ) : (
                    "Try Again"
                  )}
                </button>
                <button
                  onClick={updateCardDetails}
                  className="font-bold text-center text-white bg-primary w-full max-w-[160px] p-[0.875rem] mb-[18px]"
                >
                  {loadingUpdateCard ? (
                    <span className="mx-auto">
                      <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                    </span>
                  ) : (
                    "Update Card Details"
                  )}
                </button>
                <button
                  onClick={startManualPayment}
                  className="font-bold text-center text-white bg-primary w-full max-w-[160px] p-[0.875rem]"
                >
                  {loadingManualPayment ? (
                    <span className="mx-auto">
                      <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                    </span>
                  ) : (
                    "Make Payment Manually"
                  )}
                </button>
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="hidden"
                  ref={auto_debit_failed_modal_close_btn}
                >
                  Close Modal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="paymentModal"
        tabIndex={-1}
        aria-labelledby="paymentModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title">Payment</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="form-title">Pay ₦ 10,000</div>
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <img
                      src={mastercard_logo}
                      alt="Master Card Logo"
                      className="absolute top-[28%] left-[10px]"
                    />
                    <input
                      type="text"
                      name="card_number"
                      id="card_number"
                      placeholder="Card Number"
                      className="custom-input icon"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="expiry_date"
                      id="expiry_date"
                      placeholder="Expiry Date"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div>
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="cvv"
                      id="cvv"
                      placeholder="Cvv"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="card_holder_name"
                      id="card_holder_name"
                      placeholder="Card holder's name"
                      className="custom-input"
                    />
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full flex">
                    <input
                      type="checkbox"
                      name="save_card"
                      id="save_card"
                      className="custom-checkbox checked relative top-[0.19rem]"
                      checked={cardForm.save_card}
                      onChange={handleCardInput}
                    />
                    <label htmlFor="save_card" className="ml-3">
                      Save Card
                    </label>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#paymentConfirmedModal"
                      className="font-bold hover:bg-[#05aaf1] active:bg-[#05aaf1] text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      Make Payment
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modals End */}
      <div className="row paymentmethod_page">
        <div className="breadcrumbs flex gap-2">
          <div className="item">
            <CompanyLogo
              logo={batchData?.company_logo}
              name={batchData?.company_name}
            />
          </div>
          <div className="item active">{batchData?.company_name}</div>
          <div className="item divider">&gt;</div>
          <div className="item">Restructure plan</div>
          <div className="item divider">&gt;</div>
          <div className="item active">Payment Method</div>
        </div>
        <div className="two-section-grid">
          <div className="left-section mb-[100px]">
            <h3 className="font-bold text-[1rem] leading-[1.375rem] text-dashboard px-4 mb-[0.75rem]">
              Select a Payment Method
            </h3>
            <div className="plan-items">
              <div
                className={`item ${
                  formData.payment_method === "1" ? "active" : ""
                }`}
              >
                <label htmlFor="auto_payment">
                  <div className="title pr-6">Automatic Payment</div>
                  <div className="subtitle pr-6">
                    Link your card for automatic payment
                  </div>
                </label>
                <input
                  type="checkbox"
                  name="payment_method"
                  id="auto_payment"
                  className="icon checked"
                  checked={formData.payment_method === "1"}
                  onChange={(e) =>
                    handleInput({
                      target: { name: e.target.name, value: "1" },
                    })
                  }
                />
              </div>
              <div
                className={`item ${
                  formData.payment_method === "0" ? "active" : ""
                }`}
              >
                <label htmlFor="manual_payment">
                  <div className="title pr-6">Manual Payment</div>
                  <div className="subtitle pr-6">
                    Pay the stipulated amount using a payment gateway
                  </div>
                </label>
                <input
                  type="checkbox"
                  name="payment_method"
                  id="manual_payment"
                  className="icon checked"
                  checked={formData.payment_method === "0"}
                  onChange={(e) =>
                    handleInput({
                      target: { name: e.target.name, value: "0" },
                    })
                  }
                />
              </div>
            </div>
            <div
              className={`my-12 px-4 ${
                showDropdown ? "mb-[250px]" : ""
              } flex flex-wrap gap-6`}
            >
              <button
                className="btn font-bold text-center text-white bg-primary w-full lg:w-[calc(50%-0.75rem)] p-[0.875rem]"
                disabled={!formData.payment_method}
                onClick={() => activatePlan(true)}
              >
                Activate and start payment now
              </button>
              <div className="pop-ctn w-full lg:w-[calc(50%-0.75rem)] relative">
                <button
                  className="btn font-bold text-center text-[16px] text-white bg-primary w-full p-[0.875rem]"
                  disabled={!formData.payment_method}
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  Activate and start payment later
                </button>
                {showDropdown && (
                  <div className="absolute w-full font-normal max-w-[462px] md:w-[462px] rounded-[8px] py-[34px] px-[18px] top-[3rem] shadow-[0px_8px_40px_rgba(0,_0,_0,_0.1)] bg-white text-typography text-left cursor-default">
                    <h4 className="text-[#393939] font-medium text-sm mb-[20px]">
                      Select payment start date
                    </h4>
                    <div className="inputs grid w-full form mb-[45px]">
                      <div className="input-ctn">
                        {/* {!formData.date && (
                          <div className="label z-10 absolute px-3 bg-white custom-input w-[80%] outline-none border-0 shadow-none my-[1px] ml-[1px] text-[#667085] text-[10.88px] leading-[14.86px]">
                            Date
                          </div>
                        )} */}
                        <input
                          type="date"
                          id="date"
                          name="date"
                          className="custom-input text-xs"
                          onInput={handleInput}
                        />
                      </div>
                    </div>
                    <div className="button">
                      <button
                        disabled={!formData.date || !formData.payment_method}
                        className="btn text-center text-white text-xs font-medium bg-primary w-full p-[0.875rem]"
                        onClick={() => activatePlan(false)}
                      >
                        {activatePlanLoading ? (
                          <span className="mx-auto">
                            {" "}
                            <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                          </span>
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;
