import "./user_engage.scss";
import logo from "../../../assets/svgs/logo.svg";
import logo_icon_white from "../../../assets/svgs/logo_icon_white.svg";
import hero_image_1 from "../../../assets/images/hero_man_image_100x100.png";
import hero_image_2 from "../../../assets/images/hero_woman_image_190x190.png";
import hero_image_3 from "../../../assets/images/hero_man_image_100x100_1.png";
import hero_image_4 from "../../../assets/images/hero_woman_image_190x190_1.png";
import hero_image_5 from "../../../assets/images/hero_man_image_190x190.png";
import hero_image_6 from "../../../assets/images/hero_woman_image_190x190_2.png";
import hero_image_7 from "../../../assets/images/hero_man_image_190x190_1.png";
import hero_image_8 from "../../../assets/images/hero_woman_image_190x190_3.png";
import dotted_line_circle from "../../../assets/svgs/dotted_line_circle.svg";
import hero_gradient from "../../../assets/svgs/hero_gradient.svg";
import benefits_gradient_elipse from "../../../assets/svgs/benefits_gradient_elipse.svg";
import circular_arrow from "../../../assets/svgs/circular-arrows 1.svg";
import credit_card from "../../../assets/svgs/credit-card.svg";
import tenure from "../../../assets/svgs/tenure.svg";
import score from "../../../assets/svgs/score.svg";
import remind from "../../../assets/svgs/remind.svg";
import trophy from "../../../assets/svgs/trophy.svg";
import star_vector from "../../../assets/svgs/star_vector.svg";
import polygon from "../../../assets/svgs/polygon.svg";
import slant_gradient from "../../../assets/svgs/slant_gradient.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/functions";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import cancel from "../../../assets/svgs/cancel.svg";
import profile_image from "../../../assets/images/profile_image.png";

const demo_data = {
  isRegistered: null,
  user: {
    id: 1,
    batch_ref: "5nzgWmOB0H",
    restructure_id: "demo1234",
    company_id: "78437",
    status: "2",
    name: "Demo User",
    bvn: "224*******",
    phone: "2348100000000",
    email: "demouser@gmail.com",
    gender: "Male",
    location: "Lagos",
    loan_amount: "20000",
    due_date: "2022-10-01",
    current_amount_payable: "35000",
    activated_plan_id: null,
    activated_amount: "30300",
    total_paid: "20000",
    restructure_link: "Bc0pgWEFOznnHCS5HDBEPFq4VDps7r",
    whatsapp_check: null,
    email_check: null,
    phone_check: null,
    created_at: "2022-12-16T09:47:42.000000Z",
    updated_at: "2022-12-16T09:47:42.000000Z",
    deleted_at: null,
  },
  hyperverge: {
    status: "success",
    statusCode: "200",
    result: {
      detail: "Verification Successfull",
      response_code: "00",
      bvn_data: {
        bvn: "224*******",
        firstName: "DEMO",
        middleName: "USER",
        lastName: "ACCOUNT",
        dateOfBirth: "30-Oct-1988",
        registrationDate: "09-Apr-2015",
        enrollmentBank: "0578",
        enrollmentBranch: "LAUTECH",
        email: "demouser@yahoo.com",
        gender: "Male",
        levelOfAccount: "Level 3 - High Level Accounts",
        lgaOfOrigin: "Ede North",
        lgaOfResidence: "Osogbo",
        maritalStatus: "Single",
        nin: "",
        nameOnCard: "DEMO USER",
        nationality: "Nigeria",
        phoneNumber1: "08100000000",
        phoneNumber2: "",
        residentialAddress: "FLAT 1, DEMO STREET, DEMO TOWN, DEMO CITY",
        stateOfOrigin: "Osun State",
        stateOfResidence: "Osun State",
        title: "Mr",
        watchListed: "NO",
        photo: null,
      },
    },
  },
};

const UserEngage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery(location.search.replace("?", ""));
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const videoModalCloseBtn = useRef(null);

  async function fetchUserData() {
    if (query?.id === "demo1234") {
      setUserData((prev) => demo_data);
      setTimeout(() => {
        console.log("Calling open modal now");
        let btn = document.querySelector("#videoModalOpenBtn");
        console.log(btn);
        btn?.click();
      }, 500);
      return;
    }
    setLoadingUserData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            error,
            info: { isRegistered, user, hyperverge },
          },
        },
      } = await postRequest("/user_engage", { restructure_id: query?.id });

      if (status == "success") {
        if (isRegistered) {
          showSuccessMessage(
            "You have already been registered, login to continue"
          );
          navigate("/login");
        }
        setUserData((prev) => ({
          user,
          hyperverge,
        }));
      } else {
        throw new Error(message);
      }
    } catch (err) {
      showErrorMessage(err?.response?.data?.message || err?.message || err);
      console.log(err);
      setTimeout(window.location.replace("https://restructure.com.ng"), 500);
    }
    setLoadingUserData(false);
    setTimeout(() => {
      console.log("Calling open modal now");
      let btn = document.querySelector("#videoModalOpenBtn");
      console.log(btn);
      btn?.click();
    }, 500);
  }

  useEffect(() => {
    if (!query?.id || !query?.c || !["1", "2", "3", "4"].includes(query?.c)) {
      showErrorMessage(
        "user and/or channel id param not provided or invalid, ensure you clicked on the link as sent to your email"
      );
      setTimeout(window.location.replace("https://restructure.com.ng"), 500);
      return;
    }

    fetchUserData();
  }, []);
  return (
    <>
      {loadingUserData || !userData ? (
        <>
          <div className="w-full h-screen grid place-items-center">
            <div className="spinner-border text-dark spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="modal fade"
            id="videoModal"
            tabIndex={-1}
            aria-labelledby="videoModalLabel"
            aria-hidden="true"
          >
            <button
              type="button"
              className="btn btn-x absolute right-4 top-4"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={videoModalCloseBtn}
            >
              <img src={cancel} />
            </button>
            <div className="modal-dialog modal-size-lg modal-dialog-centered">
              <div className="modal-content">
                <iframe
                  width="auto"
                  height="315"
                  src="https://www.youtube.com/embed/Nb3ik9Khm4I"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <button
            data-bs-toggle="modal"
            id="videoModalOpenBtn"
            data-bs-target="#videoModal"
            className="text-center hidden text-primary font-axiforma text-2xl absolute top-0"
          >
            Click to watch video
          </button>
          <img
            src={hero_gradient}
            alt="hero gradient"
            className="absolute top-[55rem] lg:top-[23rem]"
          />
          <div className="container relative">
            <div className="header py-3">
              <div className="logo">
                <img
                  src={logo}
                  alt="Restructure logo"
                  style={{ width: "80%" }}
                />
              </div>
              <div className="buttons">
                <Link
                  className="w-full"
                  to={
                    "/createpassword" +
                    (userData?.user
                      ? `?${query?.c ? "c=" + query?.c : ""}&id=` +
                        userData?.user?.restructure_id
                      : "")
                  }
                  state={{ userData }}
                >
                  <button className="btn bg-primary px-[1.5rem] text-white text-sm rounded-lg font-bold uppercase">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
            <div className="content">
              <div className="hero-section py-20 grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-5">
                <div className="hero-left">
                  <h1 className="text-secondary text-[48px] leading-[52.8px] font-extrabold mt-10 font-onest lead">
                    Hello {userData?.user?.name?.split(" ")[0] || ""}, <br />{" "}
                    <span className="text-black text-[32px] leading-[35.2px]">
                      Welcome to the Restructure program
                    </span>
                  </h1>
                  <p className="font-onest text-[24px] leading-[29.2px] text-typography mt-[24px]">
                    Loan repayment has never been easier, enjoy flexible
                    customisable repayment plan, milestone payment rewards,
                    improve your credit score and get access to more loans.{" "}
                  </p>
                  <Link
                    className="w-full"
                    to={
                      "/createpassword" +
                      (userData?.user
                        ? `?${query?.c ? "c=" + query?.c : ""}&id=` +
                          userData?.user?.restructure_id
                        : "")
                    }
                    state={{ userData }}
                  >
                    <button className="bg-primary py-[19px] px-[45px] text-white text-[16px] leading-[21.86px] text-center font-bold rounded-[10px] md:my-[52px]">
                      Click to become debt free
                    </button>
                  </Link>
                </div>
                <div className="hero-right relative">
                  <div className="absolute -z-10 -top-5 left-6 h-[67px] w-[67px] rounded-[9px] bg-primary grid place-items-center">
                    <img src={logo_icon_white} alt="Restructure Logo" />
                  </div>
                  <img
                    src={dotted_line_circle}
                    alt="dotted line graphic"
                    className="absolute -z-10"
                  />
                  <div className="grid gap-2 grid-cols-3 grid-rows-3 z-20">
                    <div className="flex flex-col justify-end"></div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_1}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_2}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_3}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_4}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_5}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_6}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_7}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                    <div className="flex flex-col justify-end">
                      <img
                        src={hero_image_8}
                        alt="Hero Image 1"
                        className="ml-auto"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="benefit-section">
                <h2 className="text-typography-4 text-[48px] font-bold leading-[52px] text-center font-axiforma">
                  Benefits of{" "}
                  <span className="text-secondary">Restructure</span>
                </h2>
                <div className="py-[5.2rem]">
                  <div className="flex relative mb-9 flex-wrap flex-grow">
                    <img
                      src={benefits_gradient_elipse}
                      alt="gradient elipse"
                      className="absolute mx-auto -top-4 h-6 w-3/4"
                    />
                    <div className="benefit-flex-item">
                      <img
                        src={circular_arrow}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Fully Automated
                      </h3>
                      <p className="text-benefits text-lg">
                        Your loan repayment is fully automated , you dont have
                        to visit branches to repay loans. Payback from anywhere
                        you are.
                      </p>
                    </div>
                    <div className="benefit-flex-item">
                      <img
                        src={tenure}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Loan tenure extension
                      </h3>
                      <p className="text-benefits text-lg">
                        You have the grace to extend your loan repayment plan,
                        making it easy to payoff the stipulated amount.
                      </p>
                    </div>
                    <div className="benefit-flex-item">
                      <img
                        src={credit_card}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Flexible Customer Payment Portal
                      </h3>
                      <p className="text-benefits text-lg">
                        Customize repayment Plans to suit your pocket, as well
                        as enjoy flexible playment plans from each company you
                        have a loan with.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap flex-grow">
                    <div className="benefit-flex-item">
                      <img
                        src={trophy}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Reward Scheme
                      </h3>
                      <p className="text-benefits text-lg">
                        Enjoy lots of discounts and earn rewards for each
                        payments milestone as you journey to become debt free
                      </p>
                    </div>
                    <div className="benefit-flex-item">
                      <img
                        src={score}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Credit score improvement
                      </h3>
                      <p className="text-benefits text-lg">
                        Adhere to your repayment plan, make payment on time to
                        improve credit score.
                      </p>
                    </div>
                    <div className="benefit-flex-item">
                      <img
                        src={remind}
                        alt="benefit image"
                        className="h-[48px] w-[48px] mb-4"
                      />
                      <h3 className="font-bold text-[18px] leading-[25px] mb-3">
                        Automatic reminders
                      </h3>
                      <p className="text-benefits text-lg">
                        Never miss out on your repayment schedules with our
                        automated reminder.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="verification-sections">
                <h2 className="text-typography-4 text-[48px] font-bold leading-[52px] text-center font-axiforma">
                  Verification of{" "}
                  <span className="text-secondary">Account Information</span>
                </h2>
                <div className="py-[2rem] max-w-[70rem] mx-auto">
                  <p className="text-typography font-onest text-center text-lg mb-[5.8rem]">
                    <span className="font-bold">
                      Hi {userData?.user?.name?.split(" ")[0] || ""},
                    </span>{" "}
                    if you received this message, it means your lender is giving
                    you a great opportunity to pay off your debt via the
                    Restructure platform at your convenience, easy and
                    customizable repayment as you journey to become debt-free.
                  </p>
                  <div className="grid lg:justify-between lg:grid-cols-4">
                    <div className="col-span-4 lg:col-span-1">
                      <div className="profile-image relative h-[20rem] lg:left-0">
                        <img
                          src={star_vector}
                          alt="image background vector"
                          className="h-[19rem] w-[19rem] absolute"
                        />
                        <div className="absolute grid justify-end items-center left-[2.3rem] top-[1.5rem] h-[19rem] w-[19rem] rounded-full bg-primary">
                          <div className="ml-auto bg-red-500 grid place-items-center h-[97%] w-[97%] overflow-hidden rounded-full">
                            {userData?.hyperverge?.result?.bvn_data?.photo ? (
                              <img
                                src={
                                  "data:image/jpeg;charset=utf-8;base64," +
                                  userData?.hyperverge?.result?.bvn_data?.photo
                                }
                                alt="profile-icon"
                                className="h-[380px] w-[400px] relative -top-[2rem] object-contain"
                              />
                            ) : (
                              <img
                                src={profile_image}
                                alt="profile-icon"
                                className="h-[600px] w-[600px] relative -top-[10.51rem] object-contain"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="sign-up-card">
                      <h3 className="card-heading mb-[0.85rem]">
                        Account Information
                      </h3>
                      <div className="bottom-line"></div>
                      <div className="info-data grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-9 py-9">
                        <div>
                          <h4 className="text-xs font-medium font-onest text-[#9F9F9F]">
                            Full Name
                          </h4>
                          <p className="font-semibold text-sm font-axiforma">
                            {userData?.user?.name || ""}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium font-onest text-[#9F9F9F]">
                            Email Address
                          </h4>
                          <p className="font-semibold text-sm font-axiforma">
                            {userData?.user?.email || ""}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium font-onest text-[#9F9F9F]">
                            Phone Number
                          </h4>
                          <p className="font-semibold text-sm font-axiforma">
                            {userData?.user?.phone || ""}
                          </p>
                        </div>
                        <div>
                          <h4 className="text-xs font-medium font-onest text-[#9F9F9F]">
                            BVN
                          </h4>
                          <p className="font-semibold text-sm font-axiforma">
                            {userData?.user?.bvn
                              ? userData?.user?.bvn?.substring(0, 7) + "***"
                              : ""}
                          </p>
                        </div>
                        <div>
                          <Link
                            className="w-full"
                            to={
                              "/createpassword" +
                              (userData?.user
                                ? `?${query?.c ? "c=" + query?.c : ""}&id=` +
                                  userData?.user?.restructure_id
                                : "")
                            }
                            state={{ userData }}
                          >
                            <button className="bg-primary py-[19px] px-[35px] w-[90%] text-white text-[16px] leading-[21.86px] text-center font-bold rounded-[10px]">
                              Click to become debt free
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-section py-20">
                <div
                  className="video-placeholder cursor-pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                >
                  <div className="play-icon h-[5.5rem] w-[7.8rem] bg-primary rounded-[0.75rem] grid place-items-center">
                    <img
                      src={polygon}
                      alt="poligon"
                      className="w-[48px] h-[48px] ml-2"
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#videoModal"
                    className="text-center text-primary font-axiforma text-2xl"
                  >
                    Click to watch video
                  </button>
                </div>
              </div>
            </div>
            <div className="footer py-3">
              <div className="done-thinking-card z-0 py-[4.65rem] px-16 rounded-[1.5rem] bg-[#020D12] relative overflow-hidden">
                <img
                  src={slant_gradient}
                  alt="slant gradient"
                  className="absolute z-0 -top-[5%] -right-[30%] w-[100%] h-[40%]"
                />
                <h1 className="text-center z-10 text-[#555555] font-axiforma text-5xl mb-[3.3rem] font-bold">
                  Done thinking? <br />{" "}
                  <span className="text-secondary">Let’s get started</span>
                </h1>
                <p className="text-center z-10 text-xl font-medium text-white max-w-[34rem] mx-auto mb-[5.45rem]">
                  Create an account and Kickstart your journey to become
                  debt-free. What are you waiting for!!
                </p>
                <div className="w-full z-10 flex justify-center">
                  <Link
                    to={
                      "/createpassword" +
                      (userData?.user
                        ? `?${query?.c ? "c=" + query?.c : ""}&id=` +
                          userData?.user?.restructure_id
                        : "")
                    }
                    state={{ userData }}
                    className="z-10"
                  >
                    <button className="bg-primary z-10 p-[1rem] w-[14.5rem] text-white text-[16px] leading-[21.86px] text-center font-bold rounded-[10px]">
                      Get Started
                    </button>
                  </Link>
                </div>
                <img
                  src={slant_gradient}
                  alt="slant gradient"
                  className="absolute z-0 -bottom-[15%] -left-[30%] md:-left-[40%] md:-bottom-[7%] w-[100%] h-[40%]"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserEngage;
