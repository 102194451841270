import { Box } from "@mui/material";

export default function CompanyLogo({ logo, name, className }) {
  return logo ? (
    <img
      src={logo || ""}
      className={"logo_cirle_lg  me-3 " + className}
      style={{ maxWidth: "none" }}
    />
  ) : (
    <Box
      className={"me-3 grid place-items-center "  + className}
      sx={{
        width: "3.625rem",
        height: "3.625rem",
        borderRadius: "50%",
        border: "1px solid #e9e9e9",
        backgroundColor: "#10b981",
        color: "#00000087",
        fontSize: "30px",
        fontWeight: 700,
        fontFamily: `"Axiforma", sans-serif`,
      }}
    >
      {(name && name[0]?.toUpperCase()) || "No Name"[0]?.toUpperCase()}
    </Box>
  );
}
