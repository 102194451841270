import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { createGlobalStyle } from "styled-components";
import Select from "react-select";
import bgCardOne from "../../../assets/svgs/bg_card_overview_one.svg";
import dirright from "../../../assets/svgs/right-dir.svg";
export const GlobalStyles = createGlobalStyle`

html, body {
      //  zoom: 99%;
    overflow: hidden;
}`;
export const Container = styled.div`
  display: flex;
  background: #fff;
  width: 100%;
  overflow: hidden;
`;
export const FitToWidthDiv = styled.div`
  width: fit-content;
  height: max-content;
`;
export const Notification = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  border: none;
  :after {
    content: "" !important;
    margin: 0px;
    border: none !important;
  }
  background: transparent;

  span {
    width: 15px;
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ed3237;
    position: relative;
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    display: flex;
    margin-left: -4px;
    margin-top: -10px;
    box-sizing: content-box;
  }
`;
export const RouteContainer = styled.div`
  height: 100vh;
  width: 100%;
  margin-left: 250px;
  // position:relative;
  overflow-y: auto;
  .page {
    padding: 32px 26px 50px 50px;
    @media (max-width: 768px) {
      padding: 32px 32px 40px 22px;
    }
  }
  transition: 0.4s ease;

  @media (max-width: 992px) {
    margin-left: 0px;
  }
`;

export const DefaultCard = styled.div`
  border: 1px solid #e9e9e9;
  border-radius: 0.5rem;
  padding: 1.2rem;
`;
export const TabNav = styled.div`
  display: flex;
  border: none;
  background: none;

  width: fit-content;
  background: #fff;
  overflow: hidden;
`;
export const TabButton = styled.button`
  border: none;
  white-space: nowrap;
  font-weight: 700;
  background-color: transparent;
  box-shadow: none;
  color: #393939;
  font-size: 14px;

  margin-right: 45px;
  :last-child {
    margin-right: 10px;
  }
  cursor: pointer;
  text-align: left;
  padding: 0px 0px 10px 0px;
  border-radius: 0;
  transition: 0.4s ease;
  border-bottom: 2px solid transparent;
  :hover {
    color: #1bc8a5;
  }
  &.active {
    padding: 0px 30px 10px 0px;
    color: #1bc8a5;
    border-bottom: 2px solid #1bc8a5;
  }
`;
export const TabView = styled(DefaultCard)``;
export const MenuItem = styled(NavLink)`
  color: #000 !important;
  width: 80%;
  font-size: 14px;
  display: flex;
  text-align: center;
  padding: 9px 15px 9px 20px;

  box-sizing: content-box;
  // margin-left: 10px;

  font-weight: 600;
  @media (max-width: 500px) {
    font-size: 17px;
    width: 80%;
    padding-left: 20px;
  }
  p {
    margin: 0;
    width: fit-content;
    white-space: nowrap;
  }
  text-decoration: none !important;
  transition: 0.2s ease;
  img {
    margin-right: 23px;
    height: 20px;
    width: 20px;
    transiton: 0.4s ease;
    @media (max-width: 500px) {
      transform: scale(0.7);
      margin-right: 10px;
    }
  }
  span {
    height: 20px;
    width: 20px;
    background-size: cover;
    margin-right: 23px;
    display: none;
    transiton: 0.4s ease;
    background-repeat: no-repeat;
  }
  :hover {
    background-color: #dff6ff7c;
    color: #009ddf !important;
    span {
      display: block !important;
    }
    img {
      display: none;
    }
  }
`;
export const MenuItemNotLink = styled.div`
  cursor: pointer;
  color: #f57059 !important;
  width: 80%;
  font-size: 14px;
  text-align: center;
  padding: 9px 15px 9px 20px;
  font-weight: 600;
  box-sizing: content-box;
  margin: 0;
  display: flex;
  @media (max-width: 500px) {
    font-size: 17px;
    width: 80%;
    padding-left: 20px;
  }
  p {
    margin: 0;
    width: fit-content;
    white-space: nowrap;
  }
  text-decoration: none !important;
  transition: 0.2s ease;
  img {
    margin-right: 23px;
    transform: scale(0.9);
    @media (max-width: 500px) {
      transform: scale(0.7);
      margin-right: 10px;
    }
  }
  :hover {
  }
`;
export const UlList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: 30px;
  width: 100%;
`;
export const ListItem = styled.li`
  margin-bottom: 35px;
  width: 100%;
  display:flex ;
  justify-content:end;
  text-align: center;
  position:relative;
  
  .active {
    background-color: #DFF6FF;
    border-right:2px solid #009DDF;
    span {
      display: block !important;
    }
    img{
      display:none;
    }
  }
 
    @media (max-width: 500px) {
      margin-left: 195px;
      height: 46px;
    }
  }
`;
export const ProfileNav = styled.div`
  display: flex;
  align-items: center;
  margin-right: 60px;
  div.icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #10b98145;
    color: #00000087;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    font-family: "Axiforma", sans-serif;
    text-transform: uppercase;
  }
  p {
    color: #898989;
    font-size: 12px;
  }

  .icon-ctn {
    width: fit-content;
    display: flex;
    margin-right: 15px;
    span {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #10b981;
      display: block;
      margin-top: 27px;
      margin-left: -10px;
    }
  }
  .user-info-ctn {
    height: fit-content;
    width: fit-content;

    h3 {
      font-weight: 600;
      font-size: 14px;
      color: #4b1616;
      font-family: var(--bold);
      margin: 0px;
      text-transform: capitalize;
    }
    p {
      font-weight: 400px;
      color: #a3a3a3;
      font-size: 13px;
      margin: 0px;
    }
  }
`;

export const TableCtn = styled.div`
  &.mini-table {
    min-height: 200px;

    tr {
      border-bottom: 1px solid #e9e9e9;
      td:first-child {
        padding-left: 13px;
      }
      td:last-child {
        padding-right: 13px;
      }
    }
    td {
      padding: 13px 8px;
    }
    tr:last-child {
      border-bottom: none;
    }
  }
  &.large-table {
    img {
      display: block;
    }
    min-height: 300px;
    tr {
      border-bottom: 1px solid #e9e9e9;
      td:first-child {
        padding-left: 28px;
      }
      td:last-child {
        padding-right: 28px;
      }
    }
    td {
      padding: 20px 10px;
    }
    tr:last-child {
      border-bottom: none;
    }
  }
`;

export const SideNav = styled.div`
  display: block;
  border: none;
  border-right: 1px solid #e6e6e6;
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding-top: 5px;
  @media (max-width: 768px) {
    border-right: none;
    min-height: unset;
  }
`;
export const NavButton = styled.button`
  border: none;
  width: 100%;
  padding-top: 17.5px;
  white-space: nowrap;
  padding-bottom: 17.5px;
  background-color: transparent;
  color: #414242;
  font-size: 14px;
  font-weight: 500;
  margin-right: 18px;
  cursor: pointer;
  text-align: left;
`;
export const Td = styled.td``;
export const SearchSelect = styled(Select)`
  font-size: 15px;

  background: #fff url(../../../assets/icons/dropdownicon.svg);
  background-repeat: no-repeat;
  background-position: 96% center;
  box-shadow: none !important;
  width: 190px;
  border: 1.03145px solid #4b1616;
  box-sizing: border-box;
  border-radius: 6px;
  height: 38px;
  outline: none important;
  color: #4b1616;
  ::after,
  ::before {
    background: none;
    content: "";
    :hover {
      background-color: #4b161671;
    }
  }
`;
export const Loader = styled.span`
  width: fit-content;
  margin: 0;
  .lds-spinner {
    color: ${({ color }) => (color ? color : `official`)};
    display: inline-block;
    position: relative;
    width: 22.5px;
    height: 22.5px;
  }
  .lds-spinner div {
    transform-origin: 11.25px 11.25px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0.825px;
    left: 10.62px;
    width: 1.6875px;
    height: 5px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
export const ErrorMessageCtn = styled.div`
  display: block;
  p {
    margin: 0px;
    color: red;
    font-size: 15px;
    font-weight: 500;
  }
`;
export const HorizontalSpacer = styled.div`
  height: ${({ height = "30px" }) => height};
  display: block;
  @media (max-width: 768px) {
    height: ${({ heightSm = "0px" }) => heightSm};
  }
`;
export const VerticalSpacer = styled.div`
  width: ${({ width = "30px" }) => width};
  display: block;
  @media (max-width: 768px) {
    width: ${({ widthSm = "0px" }) => widthSm};
  }
`;
export const IntricateVerticalSpacer = styled.div`
  width: ${({ width = "30px" }) => width};
  display: block;
  @media (max-width: 992px) {
    width: ${({ widthMd = "0px" }) => widthMd};
  }
  @media (max-width: 768px) {
    width: ${({ widthSm = "0px" }) => widthSm};
  }
`;
export const HeaderText = styled.h3`
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;
`;
export const InputGroup = styled.div`
  position: relative;
  display: block;
  font-size: 14px;
  select:disabled {
    border-color: #009dde !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:disabled,
  textarea:disabled {
    border-color: #009dde !important;
  }
  input:disabled {
    color: #524e4e7d !important;
  }
  input:valid {
    background: transparent !important;
    border-color: #009dde !important;
  }
  textarea:valid {
    background: transparent !important;
    border-color: #009dde !important;
  }
  select:valid {
    background: transparent !important;
    border-color: #009dde !important;
  }

  select {
    font-size: 15px !important;

    position: relative;
  }
  select::placeholder {
    font-size: 14px;
  }
  select::after {
    position: relative;
    z-index: 5;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    left: 0px;
    top: 3;
    background: url(${dirright});
    transform: rotate(90deg);
    background-repeat: no-repeat;
    background-position: center;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
  textarea:disabled {
    color: #524e4e7d !important;
  }

  select:disabled {
    color: #524e4e7d !important;
  }
  select {
    display: block;
    max-width: 450px;

    border: 1px solid #d1d1d1;
    border-radius: 7px;
    color: var(--color);

    height: 52px;
    width: 100%;
    outline: none !important;
    background: #fff !important;
    box-shadow: none !important;
    ::placeholder {
      font-weight: 400;
      font-size: 13px !important;
      color: #989898;
    }
    :focus {
      border-color: #009dde !important;
    }
  }
  option {
    padding: 18px 24px;
    font-size: 13px !important;
  }
  textarea {
    display: block;
    max-width: 450px;

    border: 1px solid #d1d1d1;
    border-radius: 7px;
    color: var(--color);
    padding: 18px 24px;
    height: 132px;
    width: 100%;
    outline: none !important;
    background: #fff !important;
    box-shadow: none !important;
    ::placeholder {
      font-weight: 400;
      font-size: 13px !important;
      color: #989898;
    }
    :focus {
      border-color: #009dde !important;
    }
  }
  input {
    display: block;
    max-width: 450px;

    border: 1px solid #d1d1d1;
    border-radius: 7px;
    color: var(--color);
    padding: 18px 24px;
    height: 52px;
    width: 100%;
    outline: none !important;
    background: #fff !important;
    box-shadow: none !important;
    ::placeholder {
      font-weight: 400;
      font-size: 13px !important;
      color: #989898;
    }
    :focus {
      border-color: #009dde !important;
    }
  }
  label {
    display: block;

    margin-bottom: 5px;
    color: #2d3748;
    font-weight: 400;
    font-size: 14px;
  }
`;
export const P = styled.p`
  font-size: 14px;
  margin: 0;
`;
export const InputErrorMessage = styled.div`
  height: 17px;
  color: rgba(236, 111, 111, 0.9);
  font-weight: 400;
  font-size: 13px;
  transition: 0.3s ease;
  @media (max-width: 499px) {
    font-size: 10px;
    height: 15px;
  }
`;
export const ProfileImage = styled.img`
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  width: 6.812rem;
  height: 6.812rem;
  @media (max-width: 768px) {
    width: 4rem;
    height: 4rem;
  }
`;

export const Button = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 278px;
  height: 52px;
  max-width: ${({ widthMax = "unset" }) => widthMax} !important;
  color: #fff !important;
  font-size: 16px;

  border-radius: 7px;
  box-shadow: none !important;
  outline: none !important;
  background: #ffc50c !important;
  :hover {
    background: #dead09 !important;
  }
`;
export const JumboText = styled.h1`
  color: #000000;
  font-family: var(--bold);
  font-size: 33px;
  margin: 0;
`;

export const Messages = styled.button`
  background: transparent;
  padding: 7px;
`;
export const CardCtn = styled.div`
  background-color: #fff;
  border-radius: 7px;
  position: relative;
  padding: 24px 40px;
  min-height: 315px;
`;
export const CustomButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  width: ${({ width = "162px" }) => width};
  height: 48px;
  color: ${({ color = "#fff" }) => color};
  font-size: 13px;
  font-weught: 700;
  border: none;
  border-radius: 7px;
  box-shadow: none !important;
  outline: none !important;
  background: ${({ bg = "#009DDF " }) => bg} !important;
  :hover {
    background: ${({ bgHover = "#03acf5 " }) => bgHover} !important;
  }
  :disabled {
    background: ${({ bgHover = "#009cdfc7 " }) => bgHover} !important;
  }
`;
export const CardTitle = styled.h2`
  font-family: "Rubik", sans-serif;
  color: #243656;
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
`;
export const InfoText = styled.h5`
  font-size: 14px;
  line-height: 130%;

  text-align: center;

  color: #6a6a6a;
`;
export const ThickerInfoText = styled.h5`
  font-size: 14px;
  line-height: 130%;
  font-family: var(--bold);

  color: #6a6a6a;
`;
export const ThickestText = styled.h4`
  font-weight: 600;
  font-size: 28px;
  color: #1d1d1d;
  font-family: var(--bold);
`;
export const InputLargeArea = styled.textarea`
  border: 1px solid #ffc50c !important;
  border-radius: 7px;
  padding: 18px 24px;
  height: 180px;
  outline: none;
  font-size: 14px;
  color: #2d3748;
  transition: 0.3s ease;
  :placeholder {
    color: #2d3748d0;
  }
`;

export const OverviewCard = styled.div`
  color: #fff;
  padding: 7%;
  width: 100%;
  filter: drop-shadow(0px 3.53909px 8.2716px #afe9ff);
  filter: drop-shadow(0px 3.53909px 8.2716px rgba(0, 0, 0, 0.2));

  padding-bottom: 7%;
  border-radius: 0.75rem;
  background-image: url(${({ background = bgCardOne }) => background});
  background-color: ${({ bgColor = "#393939" }) => bgColor};

  background-position: ${({ bgPos = "center" }) => bgPos};
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  div.icon_ctn {
    border-radius: 0.5rem;
    height: 2.437rem;
    display: flex;
    background-color: ${({ bgColorSec = "#393939" }) => bgColorSec};
    align-items: center;
    justify-content: center;
    width: 2.437rem;
  }
  p {
    font-weight: 500;
    margin-bottom: 0;
    letter-spacing: 0.02rem;
    font-size: 0.9rem;
  }
  h5 {
    font-weight: 700;
    font-size: 1.375rem;
  }
`;
export const OverviewDataCard = styled.div`
  padding: 7%;
  width: 100%;
  padding-bottom: 7%;
  border-radius: 0.68rem;
  background: transparent;
  display:flex;
  align-content-center;
  border: 1px solid #e9e9e9;
  height: 100%;
  div.icon_ctn {
    border-radius: 50%;
    height: 2.812rem;
    width: 2.812rem;
    display: flex;
    background-color: ${({ bgColorSec = "#393939" }) => bgColorSec};
    align-items: center;
    justify-content: center;
    margin-right: 11px;
  }
  p {
    font-weight: 400;
    margin-bottom: 0;
    font-size: 0.65rem;
  }
  h5 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.125rem;
  }
`;
