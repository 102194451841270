import React, { Component, useRef } from "react";
import { ProfileImage } from "../styles/styled";
import user from "../../../assets/images/profile_mock.png";
import Dashboard from "..";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import profile_image from "../../../assets/images/profile_image.png";
import { useState, useEffect } from "react";
import { setToStorage } from "../../../utils/storage/tokenstorage";
import { Skeleton } from "@mui/material";

const maritalStatus = [
  { name: "Married" },
  { name: "Single" },
  { name: "Complicated" },
];

const gender = [{ name: "Male" }, { name: "Female" }, { name: "Complicated" }];

const religions = [
  { name: "Christianity" },
  { name: "Muslim" },
  { name: "Others" },
];

const formDataInit = {
  fname: "",
  lname: "",
  address: "",
  marital_status: "",
  date_of_birth: "",
  gender: "",
  religion: "",
  phone: "",
};

const BasicInfoPage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loadingUserDetails, setLoadingUserDetails] = useState(false);

  const changeProfileInput = useRef(null);
  const [formData, setFormData] = useState(formDataInit);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [formisValid, setFormIsValid] = useState(false);

  async function fetchUserDetails() {
    setLoadingUserDetails(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { user },
          },
        },
      } = await getRequest("/customers_get_user");

      if (status == "success") {
        setUserDetails(user);
        let form = {};
        for (let key of Object.keys(formData)) {
          form[key] = user[key];
        }
        setFormData((prev) => form);

        setToStorage("user fname", user.fname);
        setToStorage("user lname", user.lname);
        setToStorage("user email", user.email);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      err?.response?.data?.message &&
        showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingUserDetails(false);
  }

  function handleInput(e) {
    let name = e.target.name;
    let val = e.target.value;

    if (e.target.type === "file") {
      val = e.target.files[0];
    }

    let data = { ...formData, [name]: val };

    setFormData((prev) => data);

    validateForm(data);
  }

  function validateForm(data) {
    let keys = Object.keys(data);
    let status = {};
    for (let key of keys) {
      if (!data[key] || data[key].length < 3) {
        status[key] = false;
      } else {
        status[key] = true;
      }
    }

    let valid = Object.values(status).includes(false) ? false : true;

    if (valid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    return valid;
  }

  async function submitForm() {
    if (!validateForm(formData)) {
      showErrorMessage("Kindlt fill all input fields correctly");
    }
    setSubmitLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { user },
          },
        },
      } = await postRequest("/update_customer_profile", formData);

      if (status == "success") {
        showSuccessMessage(message);
        setUserDetails(user[0]);
        let form = {};
        for (let key of Object.keys(formData)) {
          form[key] = user[0][key];
        }
        setFormData((prev) => form);

        setToStorage("user fname", user[0].fname);
        setToStorage("user lname", user[0].lname);
        setToStorage("user email", user[0].email);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      err?.response?.data?.message &&
        showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setSubmitLoading(false);
  }

  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <>
      {loadingUserDetails ? (
        <div className="row mx-3 mt-4 pt-1 gx-md-5 gy-4">
          <div className="col-md-12 d-block mb-md-5 mb-3 ">
            <Skeleton
              variant="cicular"
              className="rounded-[50%] border border-solid border[#e6e6e6] md:w-[6.812rem] md:h-[6.812rem] w-[4rem] h-[4rem]"
            />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-20 mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>{" "}
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-20 mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>{" "}
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-[70px] mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>
          <div className="col-md-6">
            <Skeleton
              variant="text"
              className="text-base w-[120px] mb-1 mt-0"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>
          <div className="col-md-6">
            <Skeleton
              variant="text"
              className="text-base w-[120px] mb-1 mt-0"
            />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>{" "}
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-[70px] mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>{" "}
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-[70px] mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>
          <div className="col-md-6">
            <Skeleton variant="text" className="text-base w-[80px] mb-1 mt-0" />
            <Skeleton
              variant="rectangular"
              className="w-full h-[50px] rounded-[8px]"
            />
          </div>
          <div className="col-md-12">
            <Skeleton
              variant="rectangular"
              className="w-[185px] mt-4 h-[50px] rounded-[8px]"
            />
          </div>
        </div>
      ) : userDetails ? (
        <div className="row mx-3 mt-4 pt-1 gx-md-5 gy-4">
          <div className="col-md-12 d-block mb-md-5 mb-3 ">
            {userDetails?.photo ? (
              <ProfileImage
                src={
                  "data:image/jpeg;charset=utf-8;base64," + userDetails?.photo
                }
                alt="profile image"
              />
            ) : (
              <ProfileImage src={profile_image} alt="profile image" />
            )}
            {/* <input
            type="file"
            name="profile"
            hidden={true}
            ref={changeProfileInput}
          />
          <button className="btn d-block mt-3 faded-text fs-14 fw-500 border-0">
            Change Picture
          </button> */}
          </div>
          <div className="col-md-6">
            <Dashboard.InputGroup
              required
              label={"First Name"}
              type="text"
              placeholder={"first name"}
              name="fname"
              focus
              disabled={false}
              errorMessage={""}
              error={false}
              value={formData.fname}
              onInput={handleInput}
            />
          </div>{" "}
          <div className="col-md-6">
            <Dashboard.InputGroup
              required
              label={"Last Name"}
              type="text"
              placeholder={"last name"}
              name="lname"
              focus
              disabled={false}
              errorMessage={""}
              error={false}
              value={formData.lname}
              onInput={handleInput}
            />
          </div>{" "}
          <div className="col-md-6">
            <Dashboard.InputGroup
              required
              label={"Address"}
              type="text"
              placeholder={"full address"}
              name="address"
              disabled={false}
              errorMessage={""}
              error={false}
              value={formData.address}
              onInput={handleInput}
            />
          </div>
          <div className="col-md-6">
            <Dashboard.InputGroupSelect
              disabled={false}
              required
              label={"Marital Status"}
              placeholder={"Select your marital status"}
              options={maritalStatus}
              name="marital_status"
              type={"text"}
              errorMessage={""}
              error={false}
              value={formData.marital_status}
              onInput={handleInput}
            />
          </div>
          <div className="col-md-6">
            <Dashboard.InputGroup
              required
              label={"Date of Birth"}
              type="date"
              placeholder={"DD/MM/YY"}
              name="date_of_birth"
              focus
              disabled={false}
              errorMessage={""}
              error={false}
              value={formData.date_of_birth}
              onInput={handleInput}
            />
          </div>{" "}
          <div className="col-md-6">
            <Dashboard.InputGroup
              required
              label={"Phone"}
              type="tel"
              placeholder={"234"}
              name="phone"
              focus
              disabled={false}
              errorMessage={""}
              error={false}
              value={formData.phone}
              onInput={handleInput}
            />
          </div>{" "}
          <div className="col-md-6">
            <Dashboard.InputGroupSelect
              disabled={false}
              required
              label={"Gender"}
              placeholder={"Select your gender"}
              options={gender}
              name="gender"
              type={"text"}
              errorMessage={""}
              error={false}
              value={formData.gender}
              onInput={handleInput}
            />
          </div>
          <div className="col-md-6">
            <Dashboard.InputGroupSelect
              disabled={false}
              required
              label={"Religion"}
              placeholder={"Select your religion"}
              options={religions}
              name="religion"
              type={"text"}
              errorMessage={""}
              error={false}
              value={formData.religion}
              onInput={handleInput}
            />
          </div>
          <div className="col-md-12">
            <Dashboard.CustomButton
              width={"158px"}
              className="mt-4"
              type={"submit"}
              disabled={!formisValid}
              onClick={submitForm}
            >
              {submitLoading ? (
                <span className="mx-auto">
                  {" "}
                  <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                </span>
              ) : (
                "Update"
              )}
            </Dashboard.CustomButton>{" "}
          </div>
        </div>
      ) : (
        false
      )}
    </>
  );
};

export default BasicInfoPage;
