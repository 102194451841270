import React, { Component } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import GuardedRoute from "../guards/authGuard";
import CreatePasswordPage from "../pages/auth/createpasswordpage/createpasswordpage";
import ForgotPasswordPage from "../pages/auth/forgotpasswordpage/forgotpasswordpage";
import LoginPage from "../pages/auth/loginpage/login";
import DashboardRoutes from "../pages/dashboard/dashboardroutes";
import UserEngage from "../pages/auth/userengage/user_engage";

const AllRoutes = () => {
  return (
    <>
      <div className="popup-ctn" id="popup-parent"></div>
      <Router>
        <Routes>
          <Route path="" element={<LoginPage />} />{" "}
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />{" "}
          <Route path="/createpassword" element={<CreatePasswordPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/user_engage" element={<UserEngage />} />
          <Route exact path="/" element={<GuardedRoute />}>
            <Route exact path="dashboard/*" element={<DashboardRoutes />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default AllRoutes;
