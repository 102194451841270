import React, { Component, createContext, useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  useLocation,
  Navigate,
  Routes,
  useNavigate,
} from "react-router-dom";
import Dashboard from ".";
import SideMenu from "../../dashboardcomponents/sidemenu/sidemenu";
import TopNav from "../../dashboardcomponents/topnav/topnav";
import TokenRefresh from "../../utils/auth/tokenrefresh";
import storageFetch from "../../utils/storage/fetchtoken";
import OverviewPage from "./overview/overviewpage";
import PaymentDetails from "./paymentdetails/paymentdetails";
import PaymentMethod from "./paymentmethod/paymentmethod";
import ProfilePage from "./profilepage/profilepage";
import RestructureLoan from "./restructureloan/restructureloan";
import RewardsPage from "./rewardspage/rewardspage";
import Rewards from "./rewardspage/rewardspage";
import SettingsPage from "./settings/settingspage";
import { GlobalStyles } from "./styles/styled";
import ConfirmPayment from "./transactionspage/components/confirmpayment";
import TransactionsPage from "./transactionspage/transactionspage";
import ViewNextPaymentPage from "./viewnextpaymentpage/viewnextpaymentpage";

export const ToggleContext = createContext(true);

const DashboardRoutes = () => {
  const [isUserStillOnTab, setIsUserStillOnTab] = useState(true);
  const [userData, setUserData] = useState({});
  const [blurStatus, setBlurStatus] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const navigate = useNavigate();
  let user = {};
  useEffect(() => {
    setUserData(user);
    let currentUrl = window.location.href;

    // Split the URL into its component parts
    let urlParts = currentUrl.split("/");

    // Remove the last element in the array (which should be the current page)
    urlParts.pop();

    let previousUrl = urlParts.join("/");
    if (previousUrl == "login") {
      user.fname = storageFetch("user fname");
      user.lname = storageFetch("user lname");
      user.email = storageFetch("user email");
      user.company_id = storageFetch("company_id");
      user.admin_status = storageFetch("admin status");
      user.id = storageFetch("user id");
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };

    // console.log(user, userData, "Fetched user");
  }, []);
  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      setIsUserStillOnTab(false);
    } else if (document.visibilityState === "visible") {
      // The user has returned to the tab, so set the flag to true
      setIsUserStillOnTab(true);
    }
  };
  useEffect(() => {
    TokenRefresh();
  }, [isUserStillOnTab]);

  return (
    <>
      <ToggleContext.Provider
        value={{ isHidden, setIsHidden, userData, setUserData }}
      >
        <Dashboard>
          <GlobalStyles />
          <SideMenu />
          <Dashboard.RouteContainer className="route-ctn">
            <TopNav />
            <div className="page">
              <Routes>
                <Route path="*" element={<OverviewPage />} />
                <Route path="/overview" element={<OverviewPage />} />
                <Route
                  path="/restructure_loan/:restructureId"
                  element={<RestructureLoan />}
                />
                <Route
                  path="/restructure_loan/:restructureId/payment_details"
                  element={<PaymentDetails />}
                />
                <Route
                  path="/restructure_loan/:restructureId/payment_method"
                  element={<PaymentMethod />}
                />
                <Route path="/rewards" element={<RewardsPage />} />
                <Route path="/payments" element={<TransactionsPage />} />
                <Route
                  path="/payments/confirm_payment/:restructureId"
                  element={<ConfirmPayment />}
                />
                <Route
                  path="/payments/payment_details/:restructureId"
                  element={<ViewNextPaymentPage />}
                />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/settings" element={<SettingsPage />} />
              </Routes>
            </div>
          </Dashboard.RouteContainer>
        </Dashboard>
      </ToggleContext.Provider>
    </>
  );
};

export default DashboardRoutes;
