import { Skeleton } from "@mui/material";

export default function TransactionTableLoader() {
  return (
    <div className="px-3 w-full overflow-auto">
      <table className="w-full min-w-[548px]">
        <thead>
          <tr className="bg-[#F8F8F9] text-sm text-[#393939] border-y-[20px] border-transparent">
            <th className="pl-[16px] min-w-[100px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </th>
            <th className="py-[10px] min-w-[100px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </th>
            <th className="py-[10px] pr-6">
              <Skeleton variant="text" width="80px"></Skeleton>
            </th>
            <th className="py-[10px] min-w-[120px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </th>
            <th className="py-[10px] pr-[16px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b-[20px] text-[#555555] border-transparent text-md">
            <td className="py-[5px] pl-[16px] flex gap-2 pr-8 items-center">
              <Skeleton
                variant="circular"
                className="logo logo_cirle_lg w-[45px] h-[45px]"
              ></Skeleton>
              <Skeleton
                variant="text"
                width="75px"
                className="h-fit"
              ></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </td>
            <td className="py-[5px] pr-[16px] capitalize">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
          </tr>
          <tr className="border-b-[20px] text-[#555555] border-transparent text-md">
            <td className="py-[5px] pl-[16px] flex gap-2 pr-8 items-center">
              <Skeleton
                variant="circular"
                className="logo logo_cirle_lg w-[45px] h-[45px]"
              ></Skeleton>
              <Skeleton
                variant="text"
                width="75px"
                className="h-fit"
              ></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </td>
            <td className="py-[5px] pr-[16px] capitalize">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
          </tr>
          <tr className="border-b-[20px] text-[#555555] border-transparent text-md">
            <td className="py-[5px] pl-[16px] flex gap-2 pr-8 items-center">
              <Skeleton
                variant="circular"
                className="logo logo_cirle_lg w-[45px] h-[45px]"
              ></Skeleton>
              <Skeleton
                variant="text"
                width="75px"
                className="h-fit"
              ></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </td>
            <td className="py-[5px] pr-[16px] capitalize">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
          </tr>
          <tr className="border-b-[20px] text-[#555555] border-transparent text-md">
            <td className="py-[5px] pl-[16px] flex gap-2 pr-8 items-center">
              <Skeleton
                variant="circular"
                className="logo logo_cirle_lg w-[45px] h-[45px]"
              ></Skeleton>
              <Skeleton
                variant="text"
                width="75px"
                className="h-fit"
              ></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </td>
            <td className="py-[5px] pr-[16px] capitalize">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
          </tr>
          <tr className="border-b-[20px] text-[#555555] border-transparent text-md">
            <td className="py-[5px] pl-[16px] flex gap-2 pr-8 items-center">
              <Skeleton
                variant="circular"
                className="logo logo_cirle_lg w-[45px] h-[45px]"
              ></Skeleton>
              <Skeleton
                variant="text"
                width="75px"
                className="h-fit"
              ></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="100px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
            <td className="py-[5px]">
              <Skeleton variant="text" width="120px"></Skeleton>
            </td>
            <td className="py-[5px] pr-[16px] capitalize">
              <Skeleton variant="text" width="80px"></Skeleton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
