import React, { useState } from "react";
import { Route, Redirect, Navigate, Outlet } from "react-router-dom";
import { cookieStorage } from "../utils/storage/tokenstorage";

const GuardedRoute = () => {
  let authorize = false;
  authorize = cookieStorage.getItem("isLogged");
  return authorize ? <Outlet /> : <Navigate to="/login" />;
};

export default GuardedRoute;
