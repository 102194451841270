import axios from "axios";
import React, { Component } from "react";
import LogOut from "../auth/logout";
import { showErrorMessage } from "../notification";
import { cookieStorage } from "../storage/tokenstorage";
// export const baseURL = "https://restructure-api-app-mtjuz.ondigitalocean.app/api"; //staging
export const baseURL = "https://prod-api.restructure.com.ng/api"; //Production
// export const baseURL = "https://restructure-api.blacklistng.com/public/api"; //Oldtest

axios.interceptors.response.use((response) => {
  if (response?.data?.status?.includes("expire")) {
    showErrorMessage("Token expired, pleas login again");
    LogOut();
    return;
  }
  return response;
});

export const postRequest = (endpoint, payload) => {
  let token = cookieStorage.getItem("token");

  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(baseURL + endpoint, JSON.stringify(payload), {
    headers: headers,
  });
};
export const multipartPostRequest = (endpoint, payload) => {
  let token = cookieStorage.getItem("token");

  var headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };
  return axios.post(baseURL + endpoint, payload, { headers: headers });
};
export const getRequest = (endpoint, payload = {}) => {
  let token = cookieStorage.getItem("token");

  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return axios.get(baseURL + endpoint, { headers: headers });
};
