import React, { Component, useContext, useState } from "react";
import Dashboard from "../../pages/dashboard";
import menu from "../../assets/svgs/menu.svg";
import bell from "../../assets/svgs/bell.svg";
import "./styles.scss";

import storageFetch from "../../utils/storage/fetchtoken";
import { ToggleContext } from "../../pages/dashboard/dashboardroutes";
import {
  HorizontalSpacer,
  Messages,
  SearchBar,
  VerticalSpacer,
} from "../../pages/dashboard/styles/styled";
const TopNav = () => {
  const { isHidden, setIsHidden } = useContext(ToggleContext);
  const { userData, setUserData } = useContext(ToggleContext);
  const [showNotify, setShowNotify] = useState(false);
  let today = new Date();
  let monthformat = today.getMonth();
  let months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  let dayOfWeek = today.getDay();
  let weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  monthformat = months[monthformat];
  console.log("userdata summary", userData);
  let date =
    weekdays[dayOfWeek] +
    ", " +
    today.getDate() +
    " " +
    monthformat +
    " " +
    today.getFullYear();
  return (
    <>
      <nav className=" d-flex justify-content-between" id="topnav">
        <div className="d-flex align-items-center">
          <button
            className="btn header-btn-toggle"
            onClick={() => {
              setIsHidden(!isHidden);
              console.log("isHidden", isHidden);
            }}
            style={{ marginLeft: "10px" }}
          >
            <img className="menu-icon d-block" src={menu} />
          </button>
          <p className="today-date m-0 ms-5 d-none d-md-block ml-2">{date}</p>
        </div>
        <div className="w-fit d-flex">
          <div className="notify-ctn">
            <Dashboard.Notification />
            <VerticalSpacer width="18px" widthSm="18px" />
          </div>{" "}
          <div className="notify-ctn">
            <Dashboard.ProfileNav
              fname={storageFetch("user fname")}
              lname={storageFetch("user lname")}
              email={storageFetch("user email")}
              photo={localStorage.getItem("photo")}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNav;
