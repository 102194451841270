import no_data_icon from "../../assets/svgs/no_data_icon.svg";

export default function EmptyPlansTable({ height, text }) {
  return (
    <div className="grid place-items-center w-full" style={{ height: height || "350px" }}>
      <div>
        <img src={no_data_icon} alt="empty data image" className="mx-auto mb-3" />
        <h4 className="text-xs">{ text || "There are not plan data to show at the moment." }</h4>
      </div>
    </div>
  );
}
