import ReactDOM from "react-dom";
import React from "react";
import Popup from "./popup";

const triggerNotification = (title, message, messageType) => {
  const validMessageTypes = ["error", "info", "warning", "success"];
  if (!validMessageTypes.includes(messageType)) {
    throw Error("Invalid Popup message type");
  }

  const parent = document.getElementById("popup-parent");
  const x = parent.appendChild(document.createElement("div"));
  const id = "element-" + parseInt(Math.random() * 255599);
  x.setAttribute("id", `message-${id}`);
  x.classList.add("notification-fixed-container");
  ReactDOM.render(
    <Popup
      messageType={messageType}
      timer={4000}
      title={title}
      message={message}
      id={id}
    />,
    document.getElementById(`message-${id}`)
  );
};

export const showErrorMessage = (message,title) => {
  triggerNotification(title, message, "error");
};

// export const showInfoMessage = (message,title) => {
//   triggerNotification(title, message, "info");
// };

export const showSuccessMessage = (message, title) => {
  triggerNotification(title, message, "success");
};

// export const showWarningMessage = (message,title) => {
//   triggerNotification(title, message, "warning");
// };
