import React, { Component, useState } from "react";
import Dashboard from "..";
import { postRequest } from "../../../utils/httprequests/requests";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../utils/notification";

const formDataInit = {
  old_password: "",
  password: "",
  password_confirmation: "",
};

const ChangePasswordPage = () => {
  const [formData, setFormData] = useState(formDataInit);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formError, setFormError] = useState({});
  const [formisValid, setFormIsValid] = useState(false);

  function handleInput(e) {
    let name = e.target.name;
    let val = e.target.value;

    if (e.target.type === "file") {
      val = e.target.files[0];
    }

    let data = { ...formData, [name]: val };

    setFormData((prev) => data);

    validateForm(data);
  }

  function validateForm(data) {
    let keys = Object.keys(data);
    let status = {};
    for (let key of keys) {
      if (!data[key] || data[key].length < 3) {
        status[key] = false;
      } else {
        status[key] = true;
      }
    }

    let valid = Object.values(status).includes(false) ? false : true;

    if (valid) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }

    return valid;
  }

  async function submitForm() {
    if (!validateForm(formData)) {
      showErrorMessage("Kindlt fill all input fields correctly");
    }
    setSubmitLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/update_customer_password", formData);

      if (status == "success") {
        showSuccessMessage(message);
        setFormData((prev) => formDataInit);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      err?.response?.data?.message &&
        showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setSubmitLoading(false);
  }

  return (
    <>
      <div className="row mx-3 mt-4 pt-1 gx-md-5 gy-4">
        <div className="col-md-12">
          <Dashboard.InputGroup
            required
            label={"Enter old password"}
            type="text"
            placeholder={"old password"}
            name="old_password"
            focus
            disabled={false}
            errorMessage={""}
            error={false}
            value={formData.old_password}
            onInput={handleInput}
          />
        </div>{" "}
        <div className="col-md-12">
          <Dashboard.InputGroup
            required
            label={"Enter new password"}
            type="text"
            placeholder={"new password"}
            name="password"
            focus
            disabled={false}
            errorMessage={""}
            error={false}
            value={formData.password}
            onInput={handleInput}
          />
        </div>{" "}
        <div className="col-md-12">
          <Dashboard.InputGroup
            required
            label={"Verify new password"}
            type="text"
            placeholder={"verify password"}
            name="password_confirmation"
            focus
            disabled={false}
            errorMessage={""}
            error={false}
            value={formData.password_confirmation}
            onInput={handleInput}
          />
        </div>
        <div className="col-md-12">
          <Dashboard.CustomButton
            width={"158px"}
            className="mt-4"
            type={"submit"}
            disabled={!formisValid}
            onClick={submitForm}
          >
            {submitLoading ? (
              <span className="mx-auto">
                {" "}
                <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
              </span>
            ) : (
              "Update"
            )}
          </Dashboard.CustomButton>{" "}
        </div>
        <a href="#" className="text-link  mt-3 fs-12">
          Forgot password?
        </a>
      </div>
    </>
  );
};

export default ChangePasswordPage;
