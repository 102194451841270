import TipsCard from "../TipsCard";
import tip_two_bg_image from "../../../../../assets/images/tip_two_bg_image.png";
import logo from "../../../../../assets/svgs/logo.svg";

export default function TipsTwoTipOne(props) {
  return (
    <TipsCard showInfoIcon={true} {...props}>
      <div className="relative w-full">
        <img
          src={tip_two_bg_image}
          alt="restructure_tips_two_bg"
          className="w-[85%] h-[50%] absolute -right-1 top-0"
        />
        <div className="text-ctn px-[30px] py-[150px]">
          <img src={logo} alt="restructure logo" className="mb-[37px]" />
          <div className="tw-full max-w-[417px] text-[26.8px] leading-[48px] text-[#044865] font-extrabold">
            Learn how restructure can help clear your loans and improve your
            credit score.
          </div>
        </div>
      </div>
    </TipsCard>
  );
}
