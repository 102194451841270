import TipsCard from "../TipsCard";
import trophy from "../../../../../assets/images/trophy.png";
import { Link } from "react-router-dom";

export default function TipsOneTipFour(props) {
  return (
    <TipsCard {...props}>
      <div className="w-full px-[38px] py-[88px] bg-white">
        <div className="card-one mb-[33px] w-full max-w-[429px] flex gap-3.5 bg-white rounded-[14.9px] py-[11.93px] px-[19px] shadow-[0px_149.07px_119.256px_rgba(0,_0,_0,_0.03),_0px_96.6195px_69.8421px_rgba(0,_0,_0,_0.0227778),_0px_57.4196px_37.9853px_rgba(0,_0,_0,_0.0182222),_0px_29.814px_19.3791px_rgba(0,_0,_0,_0.015),_0px_12.1465px_9.71716px_rgba(0,_0,_0,_0.0117778),_0px_2.76056px_4.69295px_rgba(0,_0,_0,_0.00722222)]">
          <div className="circle h-[62.6px] w-[62.6px] min-h-[62.6px] min-w-[62.6px] p-[11px] rounded-full bg-[#FFF4E3] border border-[1.4907px_solid_#FFF4E3] grid place-items-center">
            <img src={trophy} alt="trophy" />
          </div>
          <div className="flex flex-col justify-center text-[12.73px] leading-[17.82px]">
            <h4 className="text-primary mb-[5.32px] font-bold">
              Earn rewards as you pay
            </h4>
            <p className="font-medium text-[#393939]">
              Start and, earn rewards for making repayments
            </p>
          </div>
        </div>
        <div className="card-two ml-auto w-full max-w-[385px] flex gap-3.5 bg-white rounded-[14.9px] py-[11.93px] px-[19px] shadow-[0px_149.07px_119.256px_rgba(0,_0,_0,_0.03),_0px_96.6195px_69.8421px_rgba(0,_0,_0,_0.0227778),_0px_57.4196px_37.9853px_rgba(0,_0,_0,_0.0182222),_0px_29.814px_19.3791px_rgba(0,_0,_0,_0.015),_0px_12.1465px_9.71716px_rgba(0,_0,_0,_0.0117778),_0px_2.76056px_4.69295px_rgba(0,_0,_0,_0.00722222)]">
          <div className="circle h-[62.6px] w-[62.6px] min-h-[62.6px] min-w-[62.6px] p-[11px] rounded-full bg-[#FFF4E3] border border-[1.4907px_solid_#FFF4E3] grid place-items-center">
            <img src={trophy} alt="trophy" />
          </div>
          <div className="flex flex-col justify-center text-[12.73px] leading-[17.82px]">
            <p className="font-medium text-[#393939]">
              You just earned a badge
            </p>
            <Link to="/dashboard/rewards" onClick={props.onCloseBtnClick}>
              <button className="text-[9.94px] text-left p-0 leading-[12.03px] text-primary font-bold">
                View all badges
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="p-[38px] bg-[#031821]">
        <h4 className="font-bold text-[19.2px] leading-[27px] text-white mb-3">
          Earn rewards as you pay
        </h4>
        <p className="text-sm leading-[26px] text-white max-w-[75%]">
          Restructure rewards you based on your milestones, pay more and earn
          more
        </p>
      </div>
    </TipsCard>
  );
}
