import React, { Component, useEffect, useState } from "react";
import { TableCtn } from "../styles/styled";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../assets/images/aladdin_logo.png";
import { Link } from "react-router-dom";
import PlanLoader from "../overview/components/PlanLoader";
import { getRequest } from "../../../utils/httprequests/requests";
import { getFormattedNumber } from "../../../utils/functions";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import Paginator from "../../../dashboardcomponents/paginator/paginator";

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatPlanDate(date) {
  if (!date) return "";
  date = date?.substring(0, 10)?.split("-")?.reverse();
  date[1] = months[parseInt(date[1]) - 1];
  return date.join("-");
}

const PendingPlans = () => {
  const [loans, setLoans] = useState(null);
  const [loadingLoans, setLoadingLoans] = useState(false);

  async function fetchLoans() {
    setLoadingLoans(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/enrolled_plans/1");
      if (status == "success") {
        setLoans((prev) => info?.details);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      // showErrorMessage(err?.response?.data?.message || err?.message || err);
    }
    setLoadingLoans(false);
  }

  useEffect(() => {
    fetchLoans();
  }, []);

  return (
    <>
      {" "}
      <TableCtn className="large-table overflow-auto">
        {loadingLoans ? (
          <PlanLoader />
        ) : loans?.data?.length > 0 ? (
          <table className="fs-14  fw-600 w-100 ">
            <thead>
              <tr>
                <th style={{ width: "100px" }}></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loans?.data?.map((company, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <CompanyLogo
                        logo={company?.company_logo}
                        name={company?.company_name}
                      />
                    </td>
                    <td>
                      <div className="truncated">
                        {company?.company_name || ""}
                      </div>
                      <div className="fs-10 faded-text">
                        Due date: {formatPlanDate(company?.due_date)}
                      </div>
                    </td>
                    <td className="">₦{getFormattedNumber(company?.amount)}</td>
                    <td className="text-center">
                      <Link
                        to={
                          "/dashboard/restructure_loan/" +
                          company?.restructure_id
                        }
                      >
                        <button className="btn fs-14 btn_primary px-4">
                          Restructure Loan
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <EmptyPlansTable />
        )}
      </TableCtn>
      {loans?.data?.length > 0 && (
        <div className="px-3 pt-3 pb-10 w-full">
          <Paginator
            data={loans}
            setData={setLoans}
            setLoading={setLoadingLoans}
          />
        </div>
      )}
    </>
  );
};

export default PendingPlans;
