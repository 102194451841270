import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import axios from "axios";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/notification/index";
import {
  setToStorage,
  cookieStorage,
} from "../../../utils/storage/tokenstorage";

import { useEffect } from "react";
import TokenRefresh from "../../../utils/auth/tokenrefresh";
import { useQuery } from "../../../utils/functions";
import { postRequest } from "../../../utils/httprequests/requests";

import lock_icon from "../../../assets/svgs/lock.svg";
import { Icon } from "@iconify/react";
import validator from "validator";

const FORM_STATIC = {
  email: "",
};

const ForgotPasswordPage = (props) => {
  const [formData, setFormData] = useState(FORM_STATIC);
  const [forgotLoading, setForgotLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();

  const SubmitForm = async (form) => {
    setForgotLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/forgot-password", formData);
      if (status == "success") {
        showSuccessMessage(message);
        navigate("/reset-password");
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(
        err?.response?.data?.message || err?.message || "Unknown error occured"
      );
    }
    setForgotLoading(true);
  };

  const ValidateForm = (data) => {
    if (validator.isEmpty(data?.email)) {
      setFormError("Email is required");
    } else if (!validator.isEmail(data?.email)) {
      setFormError("Email is invalid");
    } else {
      setFormError("");
    }
  };

  function handleInput(e) {
    let data = { ...formData, [e?.target?.name]: e?.target?.value };
    setFormData((prev) => ({ ...prev, ...data }));
    ValidateForm(data);
  }

  return (
    <>
      <div
        className="w-100 world-bg create_password_page"
        style={{ height: "100vh" }}
      >
        <div className="row h-100 w-100 justify-content-center m-0">
          <div className="col-md-5 pt-5">
            <div className="card border-0 mt-5 ">
              <div className="card-body px-md-5 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    SubmitForm();
                  }}
                  className="my-auto mx-md-4 mx-auto pt-4 text-center"
                >
                  <h3 className="form-welcome-title mb-4">Forgot password</h3>
                  <p className="form-welcome-subtitle mb-5">
                    Enter your register email address to recover your account.
                  </p>
                  <div className="form-ctn mb-alt grid justify-center mx-auto">
                    <div className="relative w-100  mb-5 mx-auto">
                      <Icon
                        icon="mdi-email-outline"
                        className="prepend-inner text-lg text-inherit absolute top-[30%] left-[20px]"
                      />
                      <input
                        className="form-control-login form-control w-full mx-auto password-input"
                        type="text"
                        placeholder="Registered Email"
                        name="email"
                        value={formData?.email}
                        onChange={handleInput}
                      />
                    </div>
                    {formError && (
                      <p className="text-left w-100 text-xs text-red-600">
                        {formError}
                      </p>
                    )}
                  </div>
                  <button
                    className="btn custom_button btn-block mx-auto  login-btn mb-alt"
                    style={{ width: "307px ", alignSelf: "center" }}
                    disabled={formError || !formData?.email}
                    type="submit"
                  >
                    {forgotLoading ? (
                      <div
                        className="spinner-border text-light spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "Forgot Password"
                    )}
                  </button>
                  <p className="bottom-text text-center">
                    Not ready to proceed?{" "}
                    <Link to="/login" className="createaccnt-redirect">
                      Sign In
                    </Link>
                  </p>{" "}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
