import Dashboard from "..";
import { HeaderText, TabButton, TabNav, TabView } from "../styles/styled";
import bulb from "../../../assets/svgs/bulb_icon.svg";
import bgCardOne from "../../../assets/svgs/bg_card_overview_one.svg";
import bgCardTwo from "../../../assets/svgs/bg_card_overview_two.svg";
import bgCardFour from "../../../assets/svgs/bg_card_overview_four.svg";
import profile from "../../../assets/svgs/person.svg";
import currencyPink from "../../../assets/svgs/currency_pink.svg";
import currencyGreen from "../../../assets/svgs/currency_green.svg";
import CreditScoreDash from "../../../dashboardcomponents/creditscore/creditscoredashboard";
import { useState } from "react";
import ActivePlans from "../activeplans/activeplans";
import CompletedPlans from "../completedplans/completedplans";
import PendingPlans from "../pendingplans/pendingplans";
import { useRef } from "react";
import TipsOneTipOne from "./components/TipsOne/TipOne";
import TipsOneTipTwo from "./components/TipsOne/TipTwo";
import TipsOneTipThree from "./components/TipsOne/TipThree";
import TipsOneTipFour from "./components/TipsOne/TipFour";
import TipsTwoTipOne from "./components/TipsTwo/TipOne";
import TipsTwoTipTwo from "./components/TipsTwo/TipTwo";
import TipsTwoTipThree from "./components/TipsTwo/TipThree";
import { showErrorMessage } from "../../../utils/notification";
import { useEffect } from "react";
import { getFormattedNumber } from "../../../utils/functions";
import { getRequest } from "../../../utils/httprequests/requests";
import OverviewAnalyticsLoader from "./components/OverviewAnalyticsLoader";
import storageFetch from "../../../utils/storage/fetchtoken";

const OverviewPage = () => {
  const [component, setComponent] = useState(1);
  const Items = [
    {
      name: "Pending",
      status: component === 1 ? "active" : "",
      id: 1,
      component: <PendingPlans />,
    },
    {
      name: "Active",
      status: component === 2 ? "active" : "",
      id: 2,
      component: <ActivePlans />,
    },
    {
      name: "Completed",
      status: component === 3 ? "active" : "",
      id: 3,
      component: <CompletedPlans />,
    },
  ];
  const showDetails = (id = 1) => {
    setComponent(id);
    const currentItem = componentDetails.filter((item) => id === item.id);
    setCurrentComponent(currentItem);
  };
  const [componentDetails] = useState(Items);
  const [currentComponent, setCurrentComponent] = useState([Items[0]]);

  const [activeTipsOne, setActiveTipsOne] = useState(1);
  const tipsOneModalCloseBtn = useRef(null);

  const [tipsOne, setTipsOne] = useState([
    {
      id: 1,
      component: (
        <TipsOneTipOne
          onCloseBtnClick={closeTipsOne}
          onNextBtnClick={() => changeTipsOne(2)}
        />
      ),
    },
    {
      id: 2,
      component: (
        <TipsOneTipTwo
          onCloseBtnClick={closeTipsOne}
          onNextBtnClick={() => changeTipsOne(3)}
        />
      ),
    },
    {
      id: 3,
      component: (
        <TipsOneTipThree
          onCloseBtnClick={closeTipsOne}
          onNextBtnClick={() => changeTipsOne(4)}
        />
      ),
    },
    {
      id: 4,
      component: (
        <TipsOneTipFour
          onCloseBtnClick={closeTipsOne}
          onNextBtnClick={closeTipsOne}
          nextBtnText="Got it"
        />
      ),
    },
  ]);

  function changeTipsOne(num) {
    if (!num || !tipsOne?.find((i) => i?.id === num))
      return console.log("No tips to change to");
    setActiveTipsOne(num);
    console.log("Next Called");
  }

  function closeTipsOne() {
    setActiveTipsOne(1);
    tipsOneModalCloseBtn.current.click();
  }

  const [activeTipsTwo, setActiveTipsTwo] = useState(1);
  const tipsTwoModalCloseBtn = useRef(null);

  const [tipsTwo, setTipsTwo] = useState([
    {
      id: 1,
      component: (
        <TipsTwoTipOne
          onCloseBtnClick={closeTipsTwo}
          onNextBtnClick={() => changeTipsTwo(2)}
        />
      ),
    },
    {
      id: 2,
      component: (
        <TipsTwoTipTwo
          onCloseBtnClick={closeTipsTwo}
          onNextBtnClick={() => changeTipsTwo(3)}
          nextBtnColor="#FFFFFF"
        />
      ),
    },
    {
      id: 3,
      component: (
        <TipsTwoTipThree
          onCloseBtnClick={closeTipsTwo}
          onNextBtnClick={closeTipsTwo}
          nextBtnColor="#FFFFFF"
          nextBtnText="Got it"
        />
      ),
    },
  ]);

  function changeTipsTwo(num) {
    if (!num || !tipsTwo?.find((i) => i?.id === num))
      return console.log("No tips to change to");
    setActiveTipsTwo(num);
    console.log("Next Called");
  }

  function closeTipsTwo() {
    setActiveTipsTwo(1);
    tipsTwoModalCloseBtn.current.click();
  }

  const [overviewInfo, setOverviewInfo] = useState(null);
  const [loadingOverviewInfo, setLoadingOverviewInfo] = useState(false);

  async function fetchOverviewInfo() {
    setLoadingOverviewInfo(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/borrower_overview");

      if (status == "success") {
        setOverviewInfo(info);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      err?.response?.data?.message &&
        showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingOverviewInfo(false);
  }

  useEffect(() => {
    fetchOverviewInfo();
  }, []);

  return (
    <>
      {/* dialogs start */}
      <div
        className="modal fade"
        id="tipsOneModal"
        tabIndex={-1}
        aria-labelledby="tipsOneModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          ref={tipsOneModalCloseBtn}
          className="absolute -top-10"
        >
          close modal
        </button>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content w-full mx-4 bg-transparent border-0 grid place-items-center">
            {tipsOne?.find((i) => i?.id === activeTipsOne)?.component}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="tipsTwoModal"
        tabIndex={-1}
        aria-labelledby="tipsTwoModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          ref={tipsTwoModalCloseBtn}
          className="absolute -top-10"
        >
          close modal
        </button>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content w-full mx-4 bg-transparent border-0 grid place-items-center">
            {tipsTwo?.find((i) => i?.id === activeTipsTwo)?.component}
          </div>
        </div>
      </div>
      {/* dialogs end */}
      <HeaderText>Welcome, {storageFetch("user fname")} 🎉</HeaderText>
      <div className="row m-0 mt-3 mt-md-5 gx-5">
        <div className="col-md-8 ps-0 px-sm-none">
          <div className="row m-0 ">
            {" "}
            <div className="col-md-6 ps-0 px-sm-none mb-2">
              <Dashboard.OverviewCard
                data-bs-toggle="modal"
                data-bs-target="#tipsOneModal"
                icon={bulb}
                background={bgCardFour}
                bgColorSec={"#1F89DB"}
                bgColor={"#4EB396"}
                bgPos={"center"}
                caption={
                  "How to make the best use of Restructure in 5 easy Steps"
                }
                className="cursor-pointer"
              />
            </div>{" "}
            <div className="col-md-6 pe-0 px-sm-none mb-2">
              <Dashboard.OverviewCard
                data-bs-toggle="modal"
                data-bs-target="#tipsTwoModal"
                background={bgCardTwo}
                bgColorSec={"#314A56"}
                bgColor={"#393939"}
                bgPos={"center"}
                icon={bulb}
                caption={
                  "Learn how restructure can help clear your loans and improve your credit score."
                }
                className="cursor-pointer"
              />
            </div>
          </div>

          {loadingOverviewInfo ? (
            <OverviewAnalyticsLoader />
          ) : (
            <div className="row m-0 mt-3 mt-md-5 pb-2">
              <div className="col-md-4 ps-0 px-sm-none mb-3 mb-md-0">
                <Dashboard.OverviewDataCard
                  value={overviewInfo?.total_loans || 0}
                  caption={"Total Loans"}
                  bgColorSec={"#EBEBEB"}
                  icon={profile}
                />
              </div>{" "}
              <div className="col-md-4 p-0 mb-3 mb-md-0">
                <Dashboard.OverviewDataCard
                  value={getFormattedNumber(overviewInfo?.total_amount_owed)}
                  caption={"Total Amount Owed"}
                  bgColorSec={"#FDDFDA"}
                  icon={currencyPink}
                />
              </div>{" "}
              <div className="col-md-4 pe-0 px-sm-none mb-3 mb-md-0">
                <Dashboard.OverviewDataCard
                  value={getFormattedNumber(overviewInfo?.total_amount_paid)}
                  caption={"Total Amount Paid"}
                  bgColorSec={"#CBF4E0"}
                  icon={currencyGreen}
                />
              </div>
            </div>
          )}

          <TabNav className="w-full flex justify-between flex-wrap">
            <div className="flex flex-col justify-center">
              <h4 className="fs-18 fw-700 mb-0">Enrolled Plans</h4>
            </div>
            <div className="w-full max-w-[500px] mt-4 mt-md-0 overflow-x-auto">
              <div className="ml-auto w-fit py-2 flex justify-end flex-nowrap">
                {Items.map((item) => {
                  return (
                    <TabButton
                      key={item.id}
                      className={`btn ${item.status} text-center`}
                      onClick={() => showDetails(item.id)}
                    >
                      {item.name}{" "}
                    </TabButton>
                  );
                })}{" "}
              </div>
            </div>
          </TabNav>
          <TabView className="mt-4 p-0 mb-4">
            {currentComponent[0].component}
          </TabView>
        </div>
        <div className="col-md-4 pe-0 px-sm-none">
          <CreditScoreDash
            showRepaymentStatus={true}
            showMissedStatus={true}
            showWithdrawStatus={false}
          ></CreditScoreDash>
        </div>
      </div>
    </>
  );
};

export default OverviewPage;
