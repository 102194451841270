import validator from "validator";
import { formatPlanDate } from "../../pages/dashboard/pendingplans/pendingplans";

export const frequencyOptions = [
  { name: "Daily", value: 1 },
  { name: "Weekly", value: 2 },
  { name: "Bi-Weekly", value: 3 },
  { name: "Monthly", value: 4 },
  { name: "Quarterly", value: 5 },
];

export const generalPlansValidator = (data) => {
  let valid = false;
  for (let val of data) {
    if (!val || validator.isEmpty(val?.toString())) {
      valid = false;
    } else {
      valid = true;
    }
  }
  return valid;
};

export const maximumTenureValidator = {
  custom: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs;
      let freq;
      let limit;

      if (data[2] === 1) {
        millisecs = 1000 * 60 * 60 * 24 * 1 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 1 * (data[3] || 1);
        freq = "day's";
      } else if (data[2] === 2) {
        millisecs = 1000 * 60 * 60 * 24 * 7 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 7 * (data[3] || 1);
        freq = "week's";
      } else if (data[2] === 3) {
        millisecs = 1000 * 60 * 60 * 24 * 14 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 14 * (data[3] || 1);
        freq = "week's";
      } else if (data[2] === 4) {
        millisecs = 1000 * 60 * 60 * 24 * 30 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 30 * (data[3] || 1);
        freq = "month's";
      } else if (data[2] === 5) {
        millisecs = 1000 * 60 * 60 * 24 * 90 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 90 * (data[3] || 1);
        freq = "month's";
      } else {
        millisecs = 1000 * 60 * 60 * 24 * 90 * (data[4] || 1);
        limit = 1000 * 60 * 60 * 24 * 90 * (data[3] || 1);
        freq = "month's";
      }

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${
            data[3] || 1
          } ${freq}`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  generic: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs;

      if (data[2] === 1) {
        millisecs = 1000 * 60 * 60 * 24 * 1 * (data[4] || 1);
      } else if (data[2] === 2) {
        millisecs = 1000 * 60 * 60 * 24 * 7 * (data[4] || 1);
      } else if (data[2] === 3) {
        millisecs = 1000 * 60 * 60 * 24 * 14 * (data[4] || 1);
      } else if (data[2] === 4) {
        millisecs = 1000 * 60 * 60 * 24 * 30 * (data[4] || 1);
      } else if (data[2] === 5) {
        millisecs = 1000 * 60 * 60 * 24 * 90 * (data[4] || 1);
      } else {
        millisecs = 1000 * 60 * 60 * 24 * -1 * (data[4] || 1);
      }

      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  daily: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs = 1000 * 60 * 60 * 24;
      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  weekly: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs = 1000 * 60 * 60 * 24 * 7;
      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  bi_weekly: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs = 1000 * 60 * 60 * 24 * 14;
      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  monthly: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs = 1000 * 60 * 60 * 24 * 30;
      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);
      console.log(parseInt(batchData?.activated_amount));

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  quarterly: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      let millisecs = 1000 * 60 * 60 * 24 * 90;
      let limit = 1000 * 60 * 60 * 24 * 30 * parseInt(maximum_payment_period);

      console.log(limit / millisecs);

      if (
        !data[1] ||
        data[1] * (limit / millisecs) < parseInt(batchData?.activated_amount)
      ) {
        valid = false;
        errors.push(
          `Amount must be large enough to complete payment in ${maximum_payment_period} months`
        );
      } else if (data[1] < 500) {
        valid = false;
        errors.push(`Amount must be greater than or equal to 500 naira`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
};

export const dateInstallmentsValidator = {
  default: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      if (!data[5] || data[5]?.length !== data[4]) {
        valid = false;
        errors.push(`You must select exactly ${data[4]} dates to proceed`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  extended: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      if (!data[6] || data[6]?.length !== data[4]) {
        valid = false;
        errors.push(`You must select exactly ${data[4]} dates to proceed`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
  custom: (data, maximum_payment_period, batchData) => {
    let valid = generalPlansValidator(data);
    let errors = [];

    if (valid) {
      if (!data[1] || data[1] * data[3] < batchData?.activated_amount) {
        valid = false;
      } else if (!data[4] || data[4]?.length !== data[3]) {
        valid = false;
        errors.push(`You must select exactly ${data[3]} dates to proceed`);
      } else {
        valid = true;
      }
    }

    return { valid, errors };
  },
};

export const lobbyFormInit = {
  id: 1,
  category: 1,
  label: "Daily Payment of Outstanding Loan Amount",
  inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
  validator: maximumTenureValidator.daily,
  data: [1, null, 1],
  status: false,
};

export const allRestructurePlanCategoriesInit = [
  {
    id: 1,
    label: "Flexible Payment Frequency",
    show: false,
  },
  {
    id: 2,
    label: "Discounts Or Forfeiture Of Interest Amount",
    show: false,
  },
  {
    id: 3,
    label: "Discount On Outstanding Loan Amount",
    show: false,
  },
  {
    id: 4,
    label: "Tenure Extension - (Customer Sets Amount & Frequency Of Payment)",
    show: false,
  },
  {
    id: 5,
    label: "One-Off Payments",
    show: false,
  },
  {
    id: 6,
    label: "Discounted Flexible Instalmental Payments",
    show: false,
  },
  {
    id: 7,
    label: "Maxiflex - Customer Sets The Terms",
    show: false,
  },
];

export const allRestructurePlansInit = [
  {
    id: 1,
    category: 1,
    label: "Daily Payment of Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.daily,
    data: [1, null, 1],
    status: false,
  },
  {
    id: 2,
    category: 1,
    label: "Weekly Payment of Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.weekly,
    data: [2, null, 2],
    status: false,
  },
  {
    id: 3,
    category: 1,
    label: "Monthly Payment of Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.monthly,
    data: [3, null, 4],
    status: false,
  },
  {
    id: 4,
    category: 1,
    label: "Quarterly Payment of Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.quarterly,
    data: [4, null, 5],
    status: false,
  },
  {
    id: 5,
    category: 2,
    label: "Daily Payment of Outstanding Principal Only",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.daily,
    data: [5, null, 1],
    status: false,
  },
  {
    id: 6,
    category: 2,
    label: "Weekly Payment of Outstanding Principal Only",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.weekly,
    data: [6, null, 2],
    status: false,
  },
  {
    id: 7,
    category: 2,
    label: "Bi-Weekly Payment of Outstanding Principal Only",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.bi_weekly,
    data: [7, null, 4],
    status: false,
  },
  {
    id: 8,
    category: 2,
    label: "Monthly Payment of Outstanding Principal Only",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.monthly,
    data: [8, null, 5],
    status: false,
  },
  {
    id: 9,
    category: 2,
    label: "Quarterly Payment of Outstanding Principal Only",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.quarterly,
    data: [9, null, 2],
    status: false,
  },
  {
    id: 10,
    category: 2,
    label: "X% discount on Interest Rate or Interest Amount",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      {
        label: "Select frequency",
        index: 2,
        placeholder: "--select frequency--",
        type: "select",
        options: frequencyOptions,
      },
    ],
    validator: maximumTenureValidator.generic,
    data: [10, null, null, "X1"],
    status: false,
  },
  {
    id: 11,
    category: 3,
    label: "Daily Payment, X% off Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.daily,
    data: [11, null, 1, "X1"],
    status: false,
  },
  {
    id: 12,
    category: 3,
    label: "Weekly Payment, X% off Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.weekly,
    data: [12, null, 2, "X1"],
    status: false,
  },
  {
    id: 13,
    category: 3,
    label: "Bi-Weekly Payment, X% off Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.bi_weekly,
    data: [13, null, 3, "X1"],
    status: false,
  },
  {
    id: 14,
    category: 3,
    label: "Monthly Payment, X% off Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.monthly,
    data: [14, null, 4, "X1"],
    status: false,
  },
  {
    id: 15,
    category: 3,
    label: "Quarterly Payment, X% off Outstanding Loan Amount",
    inputs: [{ label: "Enter amount", index: 1, placeholder: "--amount--" }],
    validator: maximumTenureValidator.quarterly,
    data: [15, null, 5, "X1"],
    status: false,
  },
  {
    id: 16,
    category: 4,
    label: "Extend loan tenure by X number of Days - (Oustanding Loan Amount)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of days--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [16, null, 1, "X1", null],
    status: false,
  },
  {
    id: 17,
    category: 4,
    label: "Extend loan tenure by X number of Days - (Principal Amount Only)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of days--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [17, null, 1, "X1", null],
    status: false,
  },
  {
    id: 18,
    category: 4,
    label: "Extend loan tenure by X number of weeks - (Oustanding Loan Amount)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of weeks--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [18, null, 2, "X1", null],
    status: false,
  },
  {
    id: 19,
    category: 4,
    label: "Extend loan tenure by X number of weeks - (Principal Amount Only)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of weeks--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [19, null, 2, "X1", null],
    status: false,
  },
  {
    id: 20,
    category: 4,
    label:
      "Extend loan tenure by X number of months - (Oustanding Loan Amount)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of months--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [20, null, 4, "X1", null],
    status: false,
  },
  {
    id: 21,
    category: 4,
    label: "Extend loan tenure by X number of months - (Principal Amount Only)",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      { label: "Every", index: 4, placeholder: "--no of months--" },
    ],
    validator: maximumTenureValidator.custom,
    data: [21, null, 4, "X1", null],
    status: false,
  },
  {
    id: 22,
    category: 5,
    label: "One off Payment of X% of Outstanding Principal Only",
    inputs: null,
    validator: () => ({ valid: true, errors: [] }),
    data: [22, "Q1", 0, "X1"],
    status: false,
  },
  {
    id: 23,
    category: 5,
    label: "One off Payment with X% Discount on Outstanding Loan Amount",
    inputs: null,
    validator: () => ({ valid: true, errors: [] }),
    data: [23, "Q1", 0, "X1"],
    status: false,
  },
  {
    id: 24,
    category: 6,
    label:
      "X% off Outstanding Amount with balance paid in X number of installments",
    inputs: [
      {
        label: "Enter amount",
        index: 1,
        placeholder: "--amount--",
        disabled: true,
      },
      {
        label: "Choose Dates",
        index: 5,
        placeholder: "--choose dates--",
        type: "date",
      },
    ],
    validator: dateInstallmentsValidator.default,
    data: [24, "Y1", 0, "X1", "X2", null],
    status: false,
  },
  {
    id: 25,
    category: 6,
    label:
      "X% off Outstanding Principal with balance paid in X number of installments.",
    inputs: [
      {
        label: "Enter amount",
        index: 1,
        placeholder: "--amount--",
        disabled: true,
      },
      {
        label: "Choose Dates",
        index: 5,
        placeholder: "--choose dates--",
        type: "date",
      },
    ],
    validator: dateInstallmentsValidator.default,
    data: [25, "Y1", 0, "X1", "X2", null],
    status: false,
  },
  {
    id: 26,
    category: 6,
    label:
      "X% off on X% initial payment with balance paid in X number of installments.",
    inputs: [
      {
        label: "Enter amount",
        index: 1,
        placeholder: "--amount--",
        disabled: true,
      },
      {
        label: "Choose Dates",
        index: 6,
        placeholder: "--no of months--",
        type: "date",
      },
    ],
    validator: dateInstallmentsValidator.extended,
    data: [26, "Y1", 0, "X1", "X2", "X3", null],
    status: false,
  },
  {
    id: 27,
    category: 7,
    label:
      "Customer Sets [Frequency - (Daily, Weekly, Monthly), Repayment Amount & Duration]",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      {
        label: "Select frequency",
        index: 2,
        placeholder: "--select frequency--",
        type: "select",
        options: frequencyOptions,
      },
    ],
    validator: maximumTenureValidator.generic,
    data: [27, null, null],
    status: false,
  },
  {
    id: 28,
    category: 7,
    label: "Customer Sets number of Installments, Duration & Repayment Amount",
    inputs: [
      { label: "Enter amount", index: 1, placeholder: "--amount--" },
      {
        label: "No of Installments",
        index: 3,
        placeholder: "--no of installments--",
      },
      {
        label: "Choose Dates",
        index: 4,
        placeholder: "--no of months--",
        type: "date",
      },
    ],
    validator: dateInstallmentsValidator.custom,
    data: [28, null, 0, null, null],
    status: false,
  },
];
