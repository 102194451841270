import { Skeleton } from "@mui/material";

export default function WithdrawalHistoryLoader() {
  return (
    <table className="fs-14 w-100">
      <thead>
        <tr>
          <th className="pl-3 min-w-[35%]">
            <a className=" text-link fs-12 fw-700" href="#">
              Date/Status
            </a>
          </th>
          <th className="pl-2">
            <a className="text-link fs-12 fw-700" href="#">
              Description
            </a>
          </th>
          <th className="pr-3 text-end">
            <a className=" text-link fs-12 fw-700" href="#">
              Amount
            </a>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="text-[12.5px] leading-[17px]">
          <td className="whitespace-nowrap">
            <Skeleton variant="text" className="h-fit min-w-[35px]"></Skeleton>
          </td>
          <td>
            <Skeleton variant="text" className="h-fit"></Skeleton>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <Skeleton
              variant="text"
              className="h-fit ml-auto min-w-[35px]"
            ></Skeleton>
          </td>
        </tr>
        <tr className="text-[12.5px] leading-[17px]">
          <td className="whitespace-nowrap">
            <Skeleton variant="text" className="h-fit min-w-[35px]"></Skeleton>
          </td>
          <td>
            <Skeleton variant="text" className="h-fit"></Skeleton>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <Skeleton
              variant="text"
              className="h-fit ml-auto min-w-[35px]"
            ></Skeleton>
          </td>
        </tr>
        <tr className="text-[12.5px] leading-[17px]">
          <td className="whitespace-nowrap">
            <Skeleton variant="text" className="h-fit min-w-[35px]"></Skeleton>
          </td>
          <td>
            <Skeleton variant="text" className="h-fit"></Skeleton>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <Skeleton
              variant="text"
              className="h-fit ml-auto min-w-[35px]"
            ></Skeleton>
          </td>
        </tr>
        <tr className="text-[12.5px] leading-[17px]">
          <td className="whitespace-nowrap">
            <Skeleton variant="text" className="h-fit min-w-[35px]"></Skeleton>
          </td>
          <td>
            <Skeleton variant="text" className="h-fit"></Skeleton>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <Skeleton
              variant="text"
              className="h-fit ml-auto min-w-[35px]"
            ></Skeleton>
          </td>
        </tr>
        <tr className="text-[12.5px] leading-[17px]">
          <td className="whitespace-nowrap">
            <Skeleton variant="text" className="h-fit min-w-[35px]"></Skeleton>
          </td>
          <td>
            <Skeleton variant="text" className="h-fit"></Skeleton>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <Skeleton
              variant="text"
              className="h-fit ml-auto min-w-[35px]"
            ></Skeleton>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
