import TipsCard from "../TipsCard";
import xensysLogo from "../../../../../assets/images/xensys_logo.png";
import aladdinLogo from "../../../../../assets/images/aladdin_logo.png";
import { Icon } from "@iconify/react";

export default function TipsOneTipTwo(props) {
  return (
    <TipsCard {...props}>
      <div className="w-full p-[38px] bg-white">
        <div className="card-one relative z-10 bg-white p-[20px] rounded-[8.2px] w-full max-w-[350px] shadow-[0px_82.9383px_66.3507px_rgba(0,_0,_0,_0.03),_0px_53.7563px_38.8581px_rgba(0,_0,_0,_0.0227778),_0px_31.9466px_21.1339px_rgba(0,_0,_0,_0.0182222),_0px_16.5877px_10.782px_rgba(0,_0,_0,_0.015),_0px_6.75794px_5.40635px_rgba(0,_0,_0,_0.0117778),_0px_1.5359px_2.61102px_rgba(0,_0,_0,_0.00722222)]">
          <div className="flex justify-between border-b border-b-[0.843683px_solid_#AAAAAA] pb-[13px] mb-[15px]">
            <div className="flex gap-4">
              <img
                src={xensysLogo}
                alt="uui"
                className="logo w-[45px] h-[45px]"
              />
              <div className="flex flex-col">
                <h4 className="grid mt-auto items-center text-[#393939] text-[12.5px] leading-[17px] font-bold">
                  Xensys Finance
                </h4>
                <p className="text-[10px] mb-auto leading-[17px]">
                  Due Date: 03- Sept-2022
                </p>
              </div>
            </div>
            <div className="due-date text-[#F57059] text-[7.8px] leading-[9px]">
              Due by 62 days
            </div>
          </div>
          <div className="lower-section grid gap-y-2 grid-cols-3 sm:grid-cols-4">
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Loan Amount
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 20,000
              </p>
            </div>
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Amount Due
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 20,000
              </p>
            </div>
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Amount Repayed
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 0.00
              </p>
            </div>
            <div className="col-span-full sm:col-span-1 grid items-center justify-center sm:justify-end">
              <Icon
                icon="mdi-arrow-right"
                className="block text-xl text-primary"
              ></Icon>
            </div>
          </div>
        </div>
        <div className="card-two relative z-0 ml-auto bg-white p-[20px] rounded-[8.2px] w-full max-w-[313px] shadow-[0px_82.9383px_66.3507px_rgba(0,_0,_0,_0.03),_0px_53.7563px_38.8581px_rgba(0,_0,_0,_0.0227778),_0px_31.9466px_21.1339px_rgba(0,_0,_0,_0.0182222),_0px_16.5877px_10.782px_rgba(0,_0,_0,_0.015),_0px_6.75794px_5.40635px_rgba(0,_0,_0,_0.0117778),_0px_1.5359px_2.61102px_rgba(0,_0,_0,_0.00722222)]">
          <div className="flex justify-between border-b border-b-[0.843683px_solid_#AAAAAA] pb-[13px] mb-[15px]">
            <div className="flex gap-4">
              <img
                src={aladdinLogo}
                alt="uui"
                className="logo w-[45px] h-[45px]"
              />
              <div className="flex flex-col">
                <h4 className="grid mt-auto items-center text-[#393939] text-[12.5px] leading-[17px] font-bold">
                  Aladdin Digital
                </h4>
                <p className="text-[10px] mb-auto leading-[17px]">
                  Due Date: 03- Sept-2022
                </p>
              </div>
            </div>
            <div className="due-date text-[#F57059] text-[7.8px] leading-[9px]">
              Due by 62 days
            </div>
          </div>
          <div className="lower-section grid gap-y-2 grid-cols-3 sm:grid-cols-4">
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Loan Amount
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 50,000
              </p>
            </div>
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Amount Due
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 50,000
              </p>
            </div>
            <div>
              <h6 className="text-[8.16px] leading-[9.84px] font-bold mb-[3px] text-[#555555]">
                Amount Repayed
              </h6>
              <p className="text-[11.26px] font-bold leading-[14px] text-[#1C1C1C]">
                ₦ 20,000
              </p>
            </div>
            <div className="col-span-full sm:col-span-1 grid items-center justify-center sm:justify-end">
              <Icon
                icon="mdi-arrow-right"
                className="block text-xl text-primary"
              ></Icon>
            </div>
          </div>
        </div>
      </div>
      <div className="p-[38px] bg-[#031821]">
        <h4 className="font-bold text-[19.2px] leading-[27px] text-white mb-3">
          Manage your debts easily
        </h4>
        <p className="text-sm leading-[26px] text-white max-w-[75%]">
          Restructure allows you to have access to all enrolled overdue loans,
          so you can take necessary actions.
        </p>
      </div>
    </TipsCard>
  );
}
