import TipsCard from "../TipsCard";
import woman_smile_tips_img from "../../../../../assets/images/woman_smile_tips_img.png";

export default function TipsTwoTipTwo(props) {
  return (
    <TipsCard {...props}>
      <div className="w-full px-[29px] pt-[33px] bg-white">
        <img
          src={woman_smile_tips_img}
          alt=""
          className="w-full max-w-[640px] mx-auto relative top-[13px]"
        />
      </div>
      <div className="p-[38px] bg-[#066A96] z-10 relative">
        <h4 className="font-bold text-[19.2px] leading-[27px] text-white mb-3">
          Benefits of Restructure
        </h4>
        <p className="text-sm leading-[26px] text-white max-w-[75%]">
          Restrcuture helps you break down your loans so you can easily pay them
          off and be debt free.
        </p>
      </div>
    </TipsCard>
  );
}
