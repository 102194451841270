import { Skeleton } from "@mui/material";

const OverviewAnalyticsLoader = () => {
  const styles = {
    borderRadius: "0.75rem",
    height: { xs: "134.9px", lg: "81px" },
    width: "100%",
  };
  return (
    <>
      <div className="row m-0 mt-3 mt-md-5 pb-2">
        <div className="col-md-4 ps-0 px-sm-none mb-3 mb-md-0">
          <Skeleton variant="rectangular" sx={styles} />
        </div>
        <div className="col-md-4 p-0 mb-3 mb-md-0">
          <Skeleton variant="rectangular" sx={styles} />
        </div>
        <div className="col-md-4 pe-0 px-sm-none mb-3 mb-md-0">
          <Skeleton variant="rectangular" sx={styles} />
        </div>
      </div>
    </>
  );
};

export default OverviewAnalyticsLoader;
