import React, { Component } from "react";
import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import { DefaultCard, TableCtn } from "../../pages/dashboard/styles/styled";
import ultrabankLogo from "../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../assets/images/renmoneye_logo.png";
import polygon from "../../assets/svgs/polygon.svg";

import { warning } from "@remix-run/router";
import { useState } from "react";
import { cookieStorage } from "../../utils/storage/tokenstorage";
import { showErrorMessage } from "../../utils/notification";
import {
  baseURL,
  getRequest,
  postRequest,
} from "../../utils/httprequests/requests";
import { useEffect } from "react";
import Dashboard from "../../pages/dashboard";
import { Icon } from "@iconify/react";
import { useRef } from "react";
import EmptyPlansTable from "../emptyplanstable/emptyplanstable";
import { getFormattedNumber } from "../../utils/functions";
import moment from "moment";
import { Skeleton } from "@mui/material";
import SmallTableLoader from "../smalltableloader/smalltableloader";
import WithdrawalHistoryLoader from "../smalltableloader/withdrawalhistoryloader";
const tableArray = [
  {
    name: "Ultrabank",
    image: ultrabankLogo,
    date: "02-04-2022",
  },
  {
    name: "Xensys Finance",
    image: xensysLogo,
    date: "02-04-2022",
  },
  {
    name: "Renmoneye ",
    image: renmoneyeLogo,
    date: "02-04-2022",
  },
];
const withdrawalArray = [
  {
    amount: "100,000",
    isPending: true,
    description: "Milestone reward from Ultrabank",
    date: "02-04-2022",
  },
  {
    amount: "145,900,000",
    isPending: false,
    description: "Off cycle payment to Ultrabank",
    date: "02-04-2022",
  },
  {
    amount: "-967,000 ",
    isPending: false,
    description: "Reward withdrawal",
    date: "02-04-2022",
  },
];

const CreditScoreDash = (props) => {
  const [showScoreInfoPopup, setShowScoreInfoPopup] = useState(false);
  const [score, setScore] = useState(cookieStorage.getItem("credit_score"));
  const scoreTimer = useRef(null);
  const [scoreLoading, setScoreLoading] = useState(true);

  async function fetchScore() {
    if (scoreTimer.current) {
      window.clearTimeout(scoreTimer.current);
    }
    setScoreLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { score },
          },
        },
      } = await getRequest("/customers_get_user_score_wallet");

      if (status == "success") {
        setScore(parseInt(score));
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setScoreLoading(false);
    scoreTimer.current = window.setTimeout(fetchScore, 50000);
  }

  const [withdrawalHistory, setWithdrawalHistory] = useState(null);
  const [loadingwithdrawalHistory, setLoadingWithdrawalHistory] =
    useState(true);

  async function fetchWithdrawalHistory(url) {
    setLoadingWithdrawalHistory(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { reward_wallet_history },
          },
        },
      } = await postRequest(url?.replace(baseURL, "") || "/fetch_histories");
      if (status == "success") {
        setWithdrawalHistory(reward_wallet_history);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      // err?.response?.data?.message &&
      //   showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingWithdrawalHistory(false);
  }

  function nextHistoryPage() {
    withdrawalHistory?.next_page_url &&
      fetchWithdrawalHistory(withdrawalHistory?.next_page_url);
  }

  function prevHistoryPage() {
    withdrawalHistory?.next_page_url &&
      fetchWithdrawalHistory(withdrawalHistory?.prev_page_url);
  }

  const [repaymentSchedule, setRepaymentSchedule] = useState(null);
  const [loadingRepaymentSchedule, setLoadingRepaymentSchedule] =
    useState(true);

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [repaymentSchedulePage, setRepaymentSchedulePage] = useState(0);

  async function fetchRepaymentSchedule() {
    setLoadingRepaymentSchedule(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { repayment_schedule },
          },
        },
      } = await getRequest("/fetch_repayment_schedule");
      if (status == "success") {
        setRepaymentSchedule(
          repayment_schedule.sort(function (a, b) {
            let date1 = new Date(a?.date).getTime();
            let date2 = new Date(b?.date).getTime();
            return date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
          })
        );
      } else {
        throw new Error(message);
      }
    } catch (err) {
      // err?.response?.data?.message &&
      //   showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingRepaymentSchedule(false);
  }

  function nextRepaymentPage() {
    if (
      (repaymentSchedulePage + 1) * itemsPerPage >
      repaymentSchedule.length - 1
    )
      return;
    setRepaymentSchedulePage(repaymentSchedulePage + 1);
  }

  function prevRepaymentPage() {
    if ((repaymentSchedulePage - 1) * itemsPerPage < 0) return;
    setRepaymentSchedulePage(repaymentSchedulePage - 1);
  }

  const [missedSchedule, setMissedSchedule] = useState(null);
  const [loadingMissedSchedule, setLoadingMissedSchedule] = useState(true);

  const [missedSchedulePage, setMissedSchedulePage] = useState(0);

  async function fetchMissedSchedule() {
    setLoadingMissedSchedule(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { missed_payments },
          },
        },
      } = await getRequest("/fetch_missed_payment");
      if (status == "success") {
        setMissedSchedule(
          missed_payments.sort(function (a, b) {
            let date1 = new Date(a?.date).getTime();
            let date2 = new Date(b?.date).getTime();
            return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
          })
        );
      } else {
        throw new Error(message);
      }
    } catch (err) {
      // err?.response?.data?.message &&
      //   showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingMissedSchedule(false);
  }

  function nextMissedPage() {
    if ((missedSchedulePage + 1) * itemsPerPage > missedSchedule.length - 1)
      return;
    setMissedSchedulePage(missedSchedulePage + 1);
  }

  function prevMissedPage() {
    if ((missedSchedulePage - 1) * itemsPerPage < 0) return;
    setMissedSchedulePage(missedSchedulePage - 1);
  }

  const [loadingPayment, setLoadingPayment] = useState({});

  async function makePayment(data, id) {
    setLoadingPayment((prev) => ({ [id]: true }));
    let params = {
      payment_type: 1,
      amount: data?.amount,
      restructure_id: data.restructure_id,
    };
    if (data?.missed) {
      params.missed_id = data?.id;
    }
    await initPayment(params);
    setLoadingPayment((prev) => ({ [id]: false }));
  }

  async function initPayment(params) {
    try {
      let callback_url =
        window?.location?.protocol +
        "//" +
        window?.location?.host +
        "/dashboard/payments/confirm_payment/" +
        params?.restructure_id;
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/customers_make_payment", {
        callback_url,
        amount: params?.amount,
        ...params,
      });

      if (status == "success") {
        window?.location?.replace(info?.authorization_url);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
  }

  function formatDate(date) {
    if (!date) return "";
    return date?.substring(0, 10)?.split("-")?.reverse()?.join("-");
  }

  let effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchWithdrawalHistory();
      fetchRepaymentSchedule();
      fetchMissedSchedule();
      fetchScore();
    }
    return () => {
      effectCount.current++;
    };
  }, []);
  return (
    <>
      <DefaultCard className="mb-4  mb-md-5 mt-md-0">
        <h5 className="fw-700 fs-18">Credit Score</h5>
        <div className="fs-12 mb-5">
          <span className="bullet red">Low credit score</span>{" "}
          <span className="bullet green">High credit score</span>
        </div>
        <div>
          {" "}
          <GaugeChart
            id="gauge-chart6"
            colors={["#f7694f", "#F5CC19", "#19A641"]}
            percent={((100 / 850) * parseInt(score)) / 100}
            arcsLength={[0.3, 0.5, 0.2]}
            textColor={"purple"}
            hideText={true}
            needleColor={"#4c4b4b"}
            needleBaseColor={"#2d2c2c"}
          />
        </div>
        <h3 className="text-center fw-700 my-3 mt-2">
          {score} <span className="fs-14">/ 850</span>
        </h3>
        <div className="text-center fs-12 mt-4 ps-3 w-full relative">
          {showScoreInfoPopup && (
            <>
              <div
                onClick={() => setShowScoreInfoPopup(!showScoreInfoPopup)}
                className="popup shadow-[0px_4px_25px_rgba(0,_0,_0,_0.1)] font-semibold rounded-[4px] text-[#393939] text-[10px] leading-[129%] px-[18px] md:px-[27px] py-[18px] md:py-[25px] w-[251px] max-w-[251px] absolute -top-[95px] md:-top-[105px] left-[calc(50%-125px)] bg-white"
              >
                Credit score is derived from Restructure performance, Credit
                bureau and alternative data sources
              </div>
              <img
                src={polygon}
                alt="poligon"
                className="arrow-icon absolute -top-[20px] left-[48%]"
              />
            </>
          )}
          <a
            className="bullet text-link orange"
            href="#"
            onClick={() => setShowScoreInfoPopup(!showScoreInfoPopup)}
          >
            Learn More about credit scores
          </a>
        </div>
      </DefaultCard>
      {props.showRepaymentStatus && (
        <DefaultCard className="p-0 mb-4  mb-md-5">
          <div className="p-3 pb-1">
            {" "}
            <h5 className="fw-700 fs-18 mb-4">Repayment Schedule</h5>
            <div className="w-100 justify-content-between d-flex">
              <a className=" text-link fs-12 fw-700" href="#">
                Plan
              </a>
              <a className=" text-link fs-12 fw-700" href="#">
                Next Payment
              </a>
            </div>
          </div>

          <TableCtn className="mini-table overflow-auto">
            {loadingRepaymentSchedule ? (
              <SmallTableLoader />
            ) : (
              <table className="fs-14  fw-600 w-100 ">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {repaymentSchedule?.length > 0 ? (
                    <>
                      {[...repaymentSchedule]
                        ?.splice(
                          repaymentSchedulePage * itemsPerPage,
                          itemsPerPage
                        )
                        ?.map((bank, i) => (
                          <tr key={i}>
                            <td>
                              <img
                                src={bank?.company_logo}
                                className="logo_cirle_sm min-w-[44.98px]"
                              />
                            </td>
                            <td>
                              <div className="truncated text-[12.43px] leading-[17.41px] font-bold text-[#393939]">
                                {bank?.company_name}
                              </div>
                              <div className="truncated whitespace-nowrap text-[10px] leading-[17.41px] font-medium text-[#393939]">
                                ₦{getFormattedNumber(bank?.amount)}
                              </div>
                            </td>
                            <td className="whitespace-nowrap">
                              <div className="h-full flex flex-col justify-center items-end">
                                <div className="whitespace-nowrap text-[12px] leading-[15.23px] font-bold text-[#555555]">
                                  {formatDate(bank?.date)}
                                </div>
                                <button
                                  onClick={() =>
                                    makePayment(bank, "planned" + i)
                                  }
                                  disabled={loadingPayment["planned" + i]}
                                  className="bg-transparent p-0 text-primary whitespace-nowrap text-[10px] leading-[13.66px] font-normal"
                                >
                                  {loadingPayment["planned" + i] ? (
                                    <div className="w-full h-full grid place-items-center">
                                      <span className="mx-auto">
                                        <Icon
                                          icon="mdi:loading"
                                          className="text-2xl mx-auto animate-spin"
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    "Make Payment"
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td colSpan={2}>
                          Page {repaymentSchedulePage + 1} of{" "}
                          {Math.ceil(repaymentSchedule?.length / itemsPerPage)}
                        </td>
                        <td colSpan={2} className="flex justify-end">
                          <Icon
                            icon="mdi:arrow-left-circle"
                            className="text-2xl text-primary cursor-pointer mr-10"
                            onClick={prevRepaymentPage}
                          />
                          <Icon
                            icon="mdi:arrow-right-circle"
                            className="text-2xl text-primary cursor-pointer"
                            onClick={nextRepaymentPage}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center text-xs">
                        <EmptyPlansTable
                          height="200px"
                          text="There are no repayment schedule to show"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </TableCtn>
        </DefaultCard>
      )}
      {props.showMissedStatus && (
        <DefaultCard className="p-0">
          <div className="p-3 pb-1">
            {" "}
            <h5 className="fw-700 fs-18 mb-4">Missed Payments</h5>
            <div className="w-100 justify-content-between d-flex">
              <a className=" text-link fs-12 fw-700" href="#">
                Plan
              </a>
              <a className=" text-link fs-12 fw-700" href="#">
                Supposed payment date
              </a>
            </div>
          </div>

          <TableCtn className="mini-table overflow-auto">
            {loadingMissedSchedule ? (
              <SmallTableLoader />
            ) : (
              <table className="fs-14  fw-600 w-100 ">
                <thead>
                  <tr>
                    <th style={{ width: "60px" }}></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {missedSchedule?.length > 0 ? (
                    <>
                      {[...missedSchedule]
                        ?.splice(
                          missedSchedulePage * itemsPerPage,
                          itemsPerPage
                        )
                        ?.map((bank, i) => (
                          <tr key={i}>
                            <td>
                              <img
                                src={bank?.company_logo}
                                className="logo_cirle_sm min-w-[44.98px]"
                              />
                            </td>
                            <td>
                              <div className="truncated text-[12.43px] leading-[17.41px] font-bold text-[#393939]">
                                {bank?.company_name}
                              </div>
                              <div className="truncated whitespace-nowrap text-[10px] leading-[17.41px] font-medium text-[#393939]">
                                ₦{getFormattedNumber(bank?.amount)}
                              </div>
                            </td>
                            <td className="whitespace-nowrap">
                              <div className="h-full flex flex-col justify-center items-end">
                                <div className="whitespace-nowrap text-[12px] leading-[15.23px] font-bold text-[#555555]">
                                  {formatDate(bank?.supposed_date)}
                                </div>
                                <button
                                  onClick={() =>
                                    makePayment(
                                      { ...bank, missed: true },
                                      "missed" + i
                                    )
                                  }
                                  disabled={loadingPayment["missed" + i]}
                                  className="bg-transparent p-0 text-primary whitespace-nowrap text-[10px] leading-[13.66px] font-normal"
                                >
                                  {loadingPayment["missed" + i] ? (
                                    <div className="w-full h-full grid place-items-center">
                                      <span className="mx-auto">
                                        <Icon
                                          icon="mdi:loading"
                                          className="text-2xl mx-auto animate-spin"
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    "Make Payment"
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td colSpan={2}>
                          Page {missedSchedulePage + 1} of{" "}
                          {Math.ceil(missedSchedule?.length / itemsPerPage)}
                        </td>
                        <td colSpan={2} className="flex justify-end">
                          <Icon
                            icon="mdi:arrow-left-circle"
                            className="text-2xl text-primary cursor-pointer mr-10"
                            onClick={prevMissedPage}
                          />
                          <Icon
                            icon="mdi:arrow-right-circle"
                            className="text-2xl text-primary cursor-pointer"
                            onClick={nextMissedPage}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center text-xs">
                        <EmptyPlansTable
                          height="200px"
                          text="There are no missed payment schedule to show"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </TableCtn>
        </DefaultCard>
      )}
      {props.showWithdrawStatus && (
        <DefaultCard className="p-0">
          <div className="p-3 pb-1">
            {" "}
            <h5 className="fw-700 fs-18 mb-4">Withdrawal History</h5>
          </div>

          <TableCtn className="mini-table overflow-auto pb-[62px]">
            {loadingwithdrawalHistory ? (
              <WithdrawalHistoryLoader />
            ) : (
              <table className="fs-14 w-100">
                <thead>
                  <tr>
                    <th className="pl-3 min-w-[35%]">
                      <a className=" text-link fs-12 fw-700" href="#">
                        Date/Status
                      </a>
                    </th>
                    <th className="pl-2">
                      <a className="text-link fs-12 fw-700" href="#">
                        Description
                      </a>
                    </th>
                    <th className="pr-3 text-end">
                      <a className=" text-link fs-12 fw-700" href="#">
                        Amount
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawalHistory?.data?.length > 0 ? (
                    <>
                      {withdrawalHistory?.data?.map((bank, i) => {
                        return (
                          <tr key={i} className="text-[12.5px] leading-[17px]">
                            <td className="whitespace-nowrap">
                              {formatDate(bank?.created_at) || ""}
                            </td>
                            <td>{bank?.description || ""}</td>
                            <td className="text-md-end whitespace-nowrap">
                              <span
                                className={
                                  parseInt(bank?.amount) > 0
                                    ? "text-[#10B981]"
                                    : parseInt(bank?.amount) < 0
                                    ? "text-[#F57059]"
                                    : ""
                                }
                              >
                                {parseInt(bank?.amount) > 0
                                  ? "+" + bank?.amount
                                  : parseInt(bank?.amount) < 0
                                  ? "-" + bank?.amount
                                  : "0.00"}
                              </span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td colSpan={2}>
                          Page {withdrawalHistory?.current_page} of{" "}
                          {withdrawalHistory.last_page}
                        </td>
                        <td colSpan={2} className="flex justify-end">
                          <Icon
                            icon="mdi:arrow-left-circle"
                            className="text-2xl text-primary cursor-pointer mr-10"
                            onClick={prevHistoryPage}
                          />
                          <Icon
                            icon="mdi:arrow-right-circle"
                            className="text-2xl text-primary cursor-pointer"
                            onClick={nextHistoryPage}
                          />
                        </td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center text-xs">
                        <EmptyPlansTable
                          height="200px"
                          text="There are no withdrawal transactions to show"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </TableCtn>
        </DefaultCard>
      )}
    </>
  );
};

export default CreditScoreDash;
