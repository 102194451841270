import React, { Component } from "react";
import { TableCtn } from "../styles/styled";
import { useState } from "react";
import { showErrorMessage } from "../../../utils/notification";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import { useEffect } from "react";
import PlanLoader from "../overview/components/PlanLoader";
import { formatPlanDate } from "../pendingplans/pendingplans";
import { getFormattedNumber } from "../../../utils/functions";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import Dashboard from "..";
import Paginator from "../../../dashboardcomponents/paginator/paginator";
import { Link } from "react-router-dom";

const ActivePlans = () => {
  const [loans, setLoans] = useState(null);
  const [loadingLoans, setLoadingLoans] = useState(false);

  async function fetchLoans() {
    setLoadingLoans(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/enrolled_plans/2");
      if (status == "success") {
        setLoans((prev) => info?.details);
      } else {
        // setLoans(tableArray);
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      // showErrorMessage(err?.response?.data?.message || err?.message || err);
    }
    setLoadingLoans(false);
  }

  const [loadingPayment, setLoadingPayment] = useState({});

  async function makePayment(data, id) {
    setLoadingPayment((prev) => ({ [id]: true }));
    let params = {
      payment_type: 1,
      amount: data?.activated_plan_id[1],
      restructure_id: data.restructure_id,
    };
    if (data?.missed) {
      params.missed_id = data?.id;
    }
    await initPayment(params);
    setLoadingPayment((prev) => ({ [id]: false }));
  }

  async function initPayment(params) {
    try {
      let callback_url =
        window?.location?.protocol +
        "//" +
        window?.location?.host +
        "/dashboard/payments/confirm_payment/" +
        params?.restructure_id;
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/customers_make_payment", {
        callback_url,
        amount: params?.amount,
        ...params,
      });

      if (status == "success") {
        window?.location?.replace(info?.authorization_url);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
  }

  useEffect(() => {
    fetchLoans();
  }, []);

  return (
    <>
      {" "}
      <TableCtn className="large-table overflow-auto">
        {loadingLoans ? (
          <PlanLoader />
        ) : loans?.data?.length > 0 ? (
          <>
            <table className="fs-14 fw-600 w-100">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loans?.data?.map((company, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <CompanyLogo
                          logo={company?.company_logo}
                          name={company?.company_name}
                        />
                      </td>
                      <td>
                        <div className="truncated">
                          {company?.company_name || ""}
                        </div>
                        <div className="fs-10 faded-text">
                          Due date: {formatPlanDate(company?.due_date)}
                        </div>
                      </td>
                      <td className="">
                        ₦{getFormattedNumber(company?.amount)}
                      </td>
                      <td className="text-center">
                        <Link
                          to={
                            "/dashboard/payments/payment_details/" +
                            company?.restructure_id
                          }
                        >
                          <button className="btn fs-14 btn_primary px-4 mx-auto">
                            View Next Payment
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : (
          <EmptyPlansTable />
        )}
      </TableCtn>
      {loans?.data?.length > 0 && (
        <div className="px-3 pt-3 pb-10 w-full">
          <Paginator
            data={loans}
            setData={setLoans}
            setLoading={setLoadingLoans}
          />
        </div>
      )}
    </>
  );
};

export default ActivePlans;
