import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import axios from "axios";
import {
  showSuccessMessage,
  showErrorMessage,
} from "../../../utils/notification/index";
import {
  setToStorage,
  cookieStorage,
} from "../../../utils/storage/tokenstorage";

import { useEffect } from "react";
import TokenRefresh from "../../../utils/auth/tokenrefresh";
import { useQuery } from "../../../utils/functions";
import { baseURL, postRequest } from "../../../utils/httprequests/requests";

import lock_icon from "../../../assets/svgs/lock.svg";
import { Icon } from "@iconify/react";
import validator from "validator";

const FORM_STATIC = {
  email: "",
  fname: "",
  lname: "",
  password: "",
  verify_password: "",
  phone: "",
  bvn: "",
  date_of_birth: "",
  gender: "",
  marital_status: "",
  religion: "",
  photo: "",
  preferred_channel: "",
};

const demo_data = {
  isRegistered: null,
  user: {
    id: 1,
    batch_ref: "5nzgWmOB0H",
    restructure_id: "demo1234",
    company_id: "78437",
    status: "2",
    name: "Demo User",
    bvn: "224*******",
    phone: "2348100000000",
    email: "demouser@gmail.com",
    gender: "Male",
    location: "Lagos",
    loan_amount: "20000",
    due_date: "2022-10-01",
    current_amount_payable: "35000",
    activated_plan_id: null,
    activated_amount: "30300",
    total_paid: "20000",
    restructure_link: "Bc0pgWEFOznnHCS5HDBEPFq4VDps7r",
    whatsapp_check: null,
    email_check: null,
    phone_check: null,
    created_at: "2022-12-16T09:47:42.000000Z",
    updated_at: "2022-12-16T09:47:42.000000Z",
    deleted_at: null,
  },
  hyperverge: {
    status: "success",
    statusCode: "200",
    result: {
      detail: "Verification Successfull",
      response_code: "00",
      bvn_data: {
        bvn: "224*******",
        firstName: "DEMO",
        middleName: "USER",
        lastName: "ACCOUNT",
        dateOfBirth: "30-Oct-1988",
        registrationDate: "09-Apr-2015",
        enrollmentBank: "0578",
        enrollmentBranch: "LAUTECH",
        email: "demouser@yahoo.com",
        gender: "Male",
        levelOfAccount: "Level 3 - High Level Accounts",
        lgaOfOrigin: "Ede North",
        lgaOfResidence: "Osogbo",
        maritalStatus: "Single",
        nin: "",
        nameOnCard: "DEMO USER",
        nationality: "Nigeria",
        phoneNumber1: "08100000000",
        phoneNumber2: "",
        residentialAddress: "FLAT 1, DEMO STREET, DEMO TOWN, DEMO CITY",
        stateOfOrigin: "Osun State",
        stateOfResidence: "Osun State",
        title: "Mr",
        watchListed: "NO",
        photo: null,
      },
    },
  },
};

const CreatePasswordPage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery(location.search.replace("?", ""));
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const [userData, setUserData] = useState(location?.state?.userData || null);

  const [loadingUserData, setLoadingUserData] = useState(false);

  async function fetchUserData(cancel) {
    console.log(demo_data);
    if (query?.id === "demo1234") {
      setUserData((prev) => demo_data);
      return;
    }
    setLoadingUserData(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            error,
            info: { isRegistered, user, hyperverge },
          },
        },
      } = await postRequest("/user_engage", { restructure_id: query?.id });

      if (status == "success") {
        if (isRegistered) {
          showErrorMessage(
            "You have already been registered, login to continue"
          );
          navigate("/login");
        }
        setUserData((prev) => ({
          user,
          hyperverge,
        }));
      } else {
        throw new Error(message);
      }
    } catch (err) {
      showErrorMessage(err?.response?.data?.message || err?.message || err);
      console.log(err);
      setTimeout(window.location.replace("https://restructure.com.ng"), 500);
    }
    setLoadingUserData(false);
  }

  const ref = useRef(null);

  const [formData, setFormData] = useState(FORM_STATIC);

  const [inputType, setInputType] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [errPasswordLength, setErrPasswordLength] = useState(false);
  const [errVerifyPasswordLength, setErrVerifyPasswordLength] = useState(false);
  const [errPasswordMismatch, setErrPasswordMismatch] = useState(false);
  const [badPassword, setBadPassword] = useState(false);
  const [badVerifyPassword, setBadVerifyPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const SubmitLogin = (form) => {
    setLoginStatus(true);
    let falsly = false;
    if (falsly == true) {
      console.log("You are already logged in");
      setLoginStatus(false);
      setLoginSuccess(true);
      return navigate("/dashboard/search");
    } else {
      if (form?.email && form?.password) {
        // console.log(ValidateForm, formData, "validated info");
        var headers = {
          "Content-Type": "application/json",
        };
        var apiBaseUrl = baseURL + "/customers_login";

        axios
          .post(apiBaseUrl, form, { headers: headers })
          .then((response) => {
            // console.log("got i to this place", response);

            if (response.data.status == "success") {
              cookieStorage.setItem("token", response.data.data.token);
              cookieStorage.setItem("isLogged", true);
              console.log("logged in,", response.data.data.info.user);
              setToStorage("user fname", response.data.data.info.user.fname);
              setToStorage("user lname", response.data.data.info.user.lname);
              setToStorage(
                "company_id",
                response.data.data.info.user.company_id
              );
              setToStorage("user id", response.data.data.info.user.id);
              setToStorage(
                "admin status",
                response.data.data.info.user.is_admin
              );
              setToStorage("user email", response.data.data.info.user.email);
              setToStorage(
                "staff status",
                response.data.data.info.user.is_staff
              );
              setLoginStatus(false);
              setLoginSuccess(true);
              TokenRefresh();
              showSuccessMessage("Login successful");
              return navigate("/dashboard/analytics");
            } else {
              console.log("entered else");
              showErrorMessage(
                response?.data?.message || "Something went wrong , Try again"
              );
              setLoginStatus(false);
            }
          })
          .catch((error) => {
            showErrorMessage(
              error?.response?.data?.message ||
                error?.message ||
                error ||
                "Something went wrong , Try again"
            );
            console.log(error, "error");
            if (true) {
              setLoginStatus(false);
              console.log("invalid password");
            }
          });
      } else {
        setLoginStatus(false);
        setDisabled(false);
        console.log("i no go anywhere", formData, errors);
      }
    }
  };

  const ValidateForm = (data) => {
    setDisabled(true);
    let errors = {};
    let formIsValid = true;

    // if (formData.email == "") {
    //   formIsValid = false;
    //   erremail = true;
    //   errors["email"] = "*Please enter your email-ID.";
    // }
    // if (typeof formData.email !== undefined) {
    //   //regular expression for email validation

    //   var pattern = new RegExp(
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //   );

    //   if (!pattern.test(formData.email)) {
    //     formIsValid = false;
    //     erremail = true;
    //     errors["email"] = "*Please enter valid email-ID.";
    //   }
    // }

    if (!data.password) {
      formIsValid = false;
    }

    if (!data.verify_password) {
      formIsValid = true;
    }

    if (data["password"] && data["password"].length <= 7) {
      formIsValid = false;
    }

    if (data["verify_password"] && data["verify_password"].length <= 7) {
      formIsValid = false;
    }

    if (
      data["verify_password"] &&
      data["verify_password"] !== data["password"]
    ) {
      formIsValid = false;
    }

    setDisabled((prev) => (formIsValid ? false : true));

    return formIsValid;
  };

  const validateField = (data, field) => {
    let errors = {};
    let errpasswordlength = false;
    let errverifypasswordlength = false;
    let errorpasswordmismatch = false;

    if (validator.isEmpty(data[field], { ignore_whitespace: true })) {
      errpasswordlength = true;
      errors[field] = field.replace("_", " ") + " cannot be empty";
    }

    if (data[field] && data[field].length <= 7) {
      // console.log("password check", formData["password"].length);
      errpasswordlength = true;
      errors[field] = "*password less than 7 characters";
    }

    if (
      field === "verify_password" &&
      data["verify_password"] &&
      data["verify_password"] !== data["password"]
    ) {
      // console.log("password check", formData["password"].length);
      errorpasswordmismatch = true;
      errors[field] = "*password mismatch";
    }

    setErrors(errors);
    setErrPasswordLength(errpasswordlength);
    setErrVerifyPasswordLength(errverifypasswordlength);
    setErrPasswordMismatch(errorpasswordmismatch);
  };

  function handleInput(e) {
    let data = { ...formData, [e?.target?.name]: e?.target?.value };
    setFormData((prev) => ({ ...prev, ...data }));
    validateField(data, e?.target?.name);
    ValidateForm(data);
  }

  const [signupLoading, setSignupLoading] = useState(false);

  async function SignUp() {
    setSignupLoading(true);
    try {
      let { verify_password, ...form } = formData;
      let bvn_data = userData?.hyperverge?.result?.bvn_data;
      form.fname = userData?.user?.name?.replaceAll("  ", " ")?.split(" ")[0];
      form.lname = userData?.user?.name?.replaceAll("  ", " ")?.split(" ")[1];
      form = {
        ...form,
        email: userData?.user?.email || "",
        phone: userData?.user?.phone || "",
        bvn: userData?.user?.bvn || "",
        date_of_birth: bvn_data?.dateOfBirth || "",
        gender: bvn_data?.gender || "",
        marital_status: bvn_data?.maritalStatus || "",
        religion: bvn_data?.religion || "",
        photo: bvn_data?.photo || "",
        preferred_channel: query?.c || "",
      };
      console.log("Form:", form);
      const {
        data: {
          status,
          message,
          data: {
            error,
            info: { user },
          },
        },
      } = await postRequest("/customers_register", form);
      if (status == "success") {
        showSuccessMessage(message);

        let loginForm = {
          email: userData?.user?.email,
          password: form?.password,
        };

        SubmitLogin(loginForm);
      } else {
        for (let key in error) {
          showErrorMessage(error[key][0]);
        }
      }
    } catch (err) {
      showErrorMessage(err?.response?.data?.message || err?.message || err);
      console.log(err);
    }
    setSignupLoading(false);
  }

  useEffect(() => {
    if (!query?.id || !query?.c || !["1", "2", "3", "4"].includes(query?.c)) {
      showErrorMessage(
        "user and/or channel id param not provided or invalid, ensure you clicked on the link as sent to your email"
      );
      setTimeout(window.location.replace("https://restructure.com.ng"), 500);
      return;
    }
    if (!userData) {
      fetchUserData();
    }
  }, []);

  return (
    <>
      {loadingUserData || !userData ? (
        <>
          <div className="w-full h-screen grid place-items-center">
            <div className="spinner-border text-dark spinner" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      ) : (
        <div
          className="w-100 world-bg create_password_page"
          style={{ height: "100vh" }}
        >
          <div className="row h-100 w-100 justify-content-center m-0">
            <div className="col-md-5 pt-5">
              <div className="card border-0 mt-5 ">
                <div className="card-body px-md-5 ">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      SignUp();
                    }}
                    className="my-auto mx-md-4 mx-auto pt-4 text-center"
                  >
                    <h3 className="form-welcome-title mb-4">
                      Create new password
                    </h3>
                    <p className="form-welcome-subtitle mb-5">
                      Create and verify a new password to allow you make use of
                      the amazing tools built for you
                    </p>
                    <div className="form-ctn mb-alt grid justify-center mx-auto">
                      <div className="relative w-100  mb-5 mx-auto">
                        <img
                          src={lock_icon}
                          alt=""
                          className="prepend-inner absolute top-[25%] left-[20px]"
                        />
                        <input
                          className="form-control-login form-control w-full mx-auto password-input"
                          type={showPassword ? "text" : "password"}
                          placeholder="New password"
                          name="password"
                          value={formData.password}
                          onChange={handleInput}
                        />
                        <Icon
                          icon="mdi-eye"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          className="prepend-inner text-lg absolute top-[30%] right-[20px] cursor-pointer z-10"
                        />
                      </div>
                      {errors?.password && (
                        <p className="text-left w-100 text-xs text-red-600">
                          {errors?.password}
                        </p>
                      )}
                    </div>
                    <div className="form-ctn grid justify-center mx-auto mb-5">
                      <div className="relative w-100  mb-5 mx-auto">
                        <img
                          src={lock_icon}
                          alt=""
                          className="prepend-inner absolute top-[25%] left-[18px]"
                        />
                        <input
                          className="form-control-login form-control w- mx-auto password-input"
                          type={showVerifyPassword ? "text" : "password"}
                          placeholder="Verify  password"
                          name="verify_password"
                          value={formData.verify_password}
                          onChange={handleInput}
                        />
                        <Icon
                          icon="mdi-eye"
                          onClick={() => {
                            setShowVerifyPassword(!showVerifyPassword);
                          }}
                          className="prepend-inner text-lg absolute top-[30%] right-[18px] cursor-pointer z-10"
                        />
                      </div>
                      {errors?.verify_password && (
                        <p className="text-left w-100 text-xs text-red-600">
                          {errors?.verify_password}
                        </p>
                      )}
                    </div>
                    <button
                      className="btn custom_button btn-block mx-auto  login-btn mb-alt"
                      style={{ width: "307px ", alignSelf: "center" }}
                      disabled={
                        disabled ||
                        !formData.password ||
                        !formData.verify_password
                      }
                      type="submit"
                    >
                      {signupLoading ? (
                        <div
                          className="spinner-border text-light spinner"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Create"
                      )}
                    </button>
                    <p className="bottom-text text-center">
                      Already have an account?{" "}
                      <Link to="/login" className="createaccnt-redirect">
                        Sign In
                      </Link>
                    </p>{" "}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePasswordPage;
