import React, { Component } from "react";
import CreditScoreDash from "../../../dashboardcomponents/creditscore/creditscoredashboard";
import { HeaderText, TabButton, TabNav, TabView } from "../styles/styled";
import nodata_banner from "../../../assets/svgs/nodata_banner.svg";
import CompletedPlans from "../completedplans/completedplans";
import PaymentActivePlans from "./components/paymentactiveplans";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../assets/images/aladdin_logo.png";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { getRequest } from "../../../utils/httprequests/requests";
import { Icon } from "@iconify/react";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import { getFormattedNumber } from "../../../utils/functions";
import { formatPlanDate } from "../pendingplans/pendingplans";
import { Skeleton } from "@mui/material";
import TransactionTableLoader from "../../../dashboardcomponents/transactiontableloader/transactiontableloader";
import Paginator from "../../../dashboardcomponents/paginator/paginator";

const sample_transactions = [
  {
    company_name: "Xensys",
    company_logo: xensysLogo,
    date: "20/01/23 12:04 PM",
    amount: "20,000",
    ref: "d78fduifd",
    payment_channel: "Card",
  },
  {
    company_name: "Ultrabank",
    company_logo: ultrabankLogo,
    date: "20/02/23 12:24 PM",
    amount: "60,000",
    ref: "d78fdfifd",
    payment_channel: "Transfer",
  },
  {
    company_name: "Renmoney",
    company_logo: renmoneyeLogo,
    date: "20/05/23 12:24 PM",
    amount: "80,000",
    ref: "d78fdbknsd",
    payment_channel: "USSD",
  },
  {
    company_name: "Aladdin Digital",
    company_logo: aladdinLogo,
    date: "20/05/23 12:24 PM",
    amount: "80,000",
    ref: "d78fdbknsd",
    payment_channel: "Card",
  },
];

const TransactionsPage = () => {
  const [component, setComponent] = useState(1);

  const PaymentTabs = [
    {
      title: "Active",
      id: 1,
      component: <PaymentActivePlans />,
      status: component === 1 ? "active" : "",
    },
    {
      title: "Completed",
      id: 2,
      component: <CompletedPlans />,
      status: component === 2 ? "active" : "",
    },
  ];

  const showDetails = (id = 1) => {
    setComponent(id);
    const currentItem = componentDetails.filter((item) => id === item.id);
    setCurrentComponent(currentItem);
  };
  const [componentDetails] = useState(PaymentTabs);
  const [currentComponent, setCurrentComponent] = useState([PaymentTabs[0]]);

  const [transactions, setTransactions] = useState(null);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  async function fetchTransactions() {
    setLoadingTransactions(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { payment_history },
          },
        },
      } = await getRequest("/fetch_borrower_payment");
      if (status == "success") {
        setTransactions(payment_history);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      // err?.response?.data?.message &&
      //   showErrorMessage(err?.response?.data?.message);
      console.log(err);
    }
    setLoadingTransactions(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchTransactions();
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {" "}
      <div className="row rewards_page">
        <HeaderText>Payments</HeaderText>
        <div className="row  mt-4">
          <div className="col-md-8 ps-0 px-sm-none">
            <div className="grid grid-cols-1">
              {PaymentTabs?.length < 1 ? (
                <div className="one grid place-items-center min-h-[15rem] px-2">
                  <div className="grid justify-center">
                    <p className="muted-text w-full max-w-[12.3rem] text-center mb-[1.9rem]">
                      No payment plan has been started yet
                    </p>
                    <button className="mx-auto py-[0.6rem] px-[2.2rem] bg-primary rounded-[0.315rem] text-xs text-white font-bold">
                      Start a plan today
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <TabNav>
                    <div className="col-md-5 ml-3 text-end p-0 d-flex justify-content-start mt-4 mt-md-0">
                      {PaymentTabs.map((item) => {
                        return (
                          <TabButton
                            key={item.id}
                            className={`btn ${item.status}`}
                            onClick={() => showDetails(item.id)}
                          >
                            {item.title}{" "}
                          </TabButton>
                        );
                      })}{" "}
                    </div>
                  </TabNav>
                  {currentComponent[0].component}
                </>
              )}

              <div className="two">
                <h4 className="font-bold ml-3 mt-5 mb-10">
                  Transaction History
                </h4>
                {loadingTransactions ? (
                  <TransactionTableLoader />
                ) : transactions?.data?.length > 0 ? (
                  <>
                    <div className="px-3 w-full overflow-auto">
                      <table className="w-full min-w-[548px]">
                        <thead>
                          <tr className="bg-[#F8F8F9] text-sm text-[#393939] border-y-[20px] border-transparent">
                            <th className="pl-[16px] min-w-[100px]">Company</th>
                            <th className="py-[10px] min-w-[100px]">Date</th>
                            <th className="py-[10px] pr-6">Amount</th>
                            <th className="py-[10px] min-w-[120px]">
                              Ref/Session ID
                            </th>
                            <th className="py-[10px] pr-[16px]">
                              Payment Channel
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.data?.map((transaction, idx) => (
                            <tr
                              key={idx}
                              className="border-b-[20px] text-[#555555] border-transparent text-sm"
                            >
                              <td className="py-[5px] pl-[16px] flex gap-2 pr-8">
                                <img
                                  src={transaction.company_logo}
                                  alt="logo"
                                  className="logo logo_cirle_lg w-[45px] h-[45px]"
                                ></img>
                                <h4 className="grid items-center font-bold">
                                  {transaction.company_name}
                                </h4>
                              </td>
                              <td className="py-[5px]">
                                {formatPlanDate(transaction.created_at)}
                              </td>
                              <td className="py-[5px]">
                                ₦{getFormattedNumber(transaction.amount_paid)}
                              </td>
                              <td className="py-[5px]">
                                {transaction.payment_ref}
                              </td>
                              <td className="py-[5px] pr-[16px] capitalize">
                                {transaction.payment_type}
                              </td>
                            </tr>
                          ))}
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="px-3 pt-3 pb-10 w-full">
                      <Paginator
                        data={transactions}
                        setData={setTransactions}
                        setLoading={setLoadingTransactions}
                      />
                    </div>
                  </>
                ) : (
                  <EmptyPlansTable text="There are no transactions to show at the moment" />
                )}
              </div>
            </div>
          </div>{" "}
          <div className="col-md-4 pe-0 px-sm-none pt-5">
            <CreditScoreDash
              showRepaymentStatus={true}
              showMissedStatus={true}
              showWithdrawStatus={false}
            ></CreditScoreDash>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsPage;
