import React, { Component } from "react";
import CreditScoreDash from "../../../dashboardcomponents/creditscore/creditscoredashboard";
import ultrabankLogo from "../../../assets/images/ultrabank_logo.png";
import xensysLogo from "../../../assets/images/xensys_logo.png";
import renmoneyeLogo from "../../../assets/images/renmoneye_logo.png";
import aladdinLogo from "../../../assets/images/aladdin_logo.png";
import { DefaultCard, HeaderText } from "../styles/styled";
import logo from "../../../assets/svgs/logo.svg";
import "./style.scss";
import { useState } from "react";
import { getRequest, postRequest } from "../../../utils/httprequests/requests";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
import { useRef } from "react";
import Dashboard from "..";
import {
  getActiveProgressDot,
  getFormattedNumber,
} from "../../../utils/functions";
import CompanyLogo from "../../../dashboardcomponents/companylogo/companylogo";
import { showErrorMessage } from "../../../utils/notification";
import PlanLoader from "../overview/components/PlanLoader";
import EmptyPlansTable from "../../../dashboardcomponents/emptyplanstable/emptyplanstable";
import { formatPlanDate } from "../pendingplans/pendingplans";
import message_sent_icon from "../../../assets/gifs/message-sent-successfully-plane.gif";
import Paginator from "../../../dashboardcomponents/paginator/paginator";

const sample_payment = {
  company_name: "Ultrabank",
  company_logo: ultrabankLogo,
  amount_due: "100,000",
  total_paid: "50,000",
  next_repayment: "29/01/2023",
  payment_channel: "Card",
  due_date: "29/01/2023",
};

const tableArray = [
  {
    company_name: "Ultrabank",
    company_logo: ultrabankLogo,
    amount: "2,000,000.05",
    due_date: "03-Sept-2022",
  },
  {
    company_name: "Xensys Finance",
    company_logo: xensysLogo,
    amount: "100,000",
    due_date: "29-Dec-2010",
  },
  {
    company_name: "Renmoneye ",
    company_logo: renmoneyeLogo,
    amount: "40,700,000",
    due_date: "15-Jan-2022",
  },
  {
    company_name: "Aladdin Digital ",
    company_logo: aladdinLogo,
    amount: "5,000,000",
    due_date: "03-Jun-2019",
  },
];

let STATIC_SHOW_MILESTONE = {};

for (let [idx, item] of tableArray.entries()) {
  STATIC_SHOW_MILESTONE = { ...STATIC_SHOW_MILESTONE, [idx]: true };
}

const offCycleFormInit = {
  amount: "",
  campaign: null,
};

const RewardsPage = () => {
  const [view, setView] = useState(null);
  const [balance, setBalance] = useState(0);
  const balanceTimer = useRef(null);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const navigate = useNavigate();

  async function fetchBalance() {
    if (balanceTimer.current) {
      window.clearTimeout(balanceTimer.current);
    }
    setBalanceLoading(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { reward_wallet },
          },
        },
      } = await getRequest("/customers_get_user_score_wallet");

      if (status == "success") {
        setBalance(reward_wallet);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setBalanceLoading(false);
    balanceTimer.current = window.setTimeout(fetchBalance, 50000);
  }

  const [rewards, setRewards] = useState([]);
  const [loadingRewards, setLoadingRewards] = useState(false);

  const [loans, setLoans] = useState(null);
  const [loadingLoans, setLoadingLoans] = useState(false);

  const [showMilestone, setShowMilestone] = useState(STATIC_SHOW_MILESTONE);
  const [showDropdown, setShowDropdown] = useState(false);

  async function fetchRewards() {
    setLoadingRewards(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/fetch_reward");
      if (status == "success") {
        setRewards((prev) => info);
        for (let [idx, item] of info?.data?.entries()) {
          setShowMilestone((prev) => ({ ...prev, [idx]: true }));
        }
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingRewards(false);
  }

  async function fetchLoans() {
    setLoadingLoans(true);
    try {
      const {
        data: {
          status,
          message,
          data: {
            info: { details },
          },
        },
      } = await getRequest("/enrolled_plans/2");
      if (status == "success") {
        setLoans((prev) => details);
      } else {
        // setLoans(tableArray);
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      // showErrorMessage(err?.response?.data?.message || err?.message || err);
    }
    setLoadingLoans(false);
  }

  const [offCycleForm, setOffCycleForm] = useState(offCycleFormInit);
  const paymentConfirmedModalOpenBtn = useRef(null);

  function setCampaignValue(val) {
    setOffCycleForm((prev) => ({ ...prev, campaign: val }));
  }

  async function initPayment(params) {
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await postRequest("/make_payment_using_reward_wallet", {
        restructure_id: offCycleForm?.campaign?.restructure_id,
        amount: params?.amount,
        ...params,
      });

      if (status == "success") {
        paymentConfirmedModalOpenBtn?.current?.click();
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
      showErrorMessage(err?.response?.data?.message || err?.message);
    }
  }

  const [offCyclePaymentLoading, setOffCyclePaymentLoading] = useState(false);

  async function startOffCyclePayment() {
    if (!offCycleForm.campaign || offCycleForm.amount.length < 1) {
      return showErrorMessage("Ensure your have filled all field correctly");
    }
    setOffCyclePaymentLoading(true);
    let params = { amount: offCycleForm?.amount };
    await initPayment(params);
    setOffCyclePaymentLoading(false);
  }

  const reward_status = ["Active", "Activated", "Revoked"];

  const [bankDetails, setBankDetails] = useState(null);
  const [loadingBankDetails, setLoadingBankDetails] = useState(true);

  async function fetchBankDetails() {
    setLoadingBankDetails(true);
    try {
      const {
        data: {
          status,
          message,
          data: { info },
        },
      } = await getRequest("/fetch_bank_details");
      if (status == "success") {
        setBankDetails((prev) => info);
      } else {
        throw new Error(message);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingBankDetails(false);
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current < 1) {
      fetchBalance();
      fetchRewards();
      fetchLoans();
      fetchBankDetails();
    }
    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <>
      {/* dialog start */}
      <div
        className="modal fade"
        id="paymentConfirmedModal"
        tabIndex={-1}
        aria-labelledby="paymentConfirmedlLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title">
              <button
                className="open_btn hidden"
                data-bs-toggle="modal"
                data-bs-target="#paymentConfirmedModal"
                ref={paymentConfirmedModalOpenBtn}
              >
                Hello
              </button>
            </div>
            <div className="modal-body">
              <div className="w-full max-w-[405px] grid place-items-center">
                <img
                  src={message_sent_icon}
                  alt="Payment successful"
                  className="w-[11.5rem] h-[11.5rem]"
                />
                <h4 className="text-black font-bold text-2xl mb-4">
                  Payment Successful
                </h4>
                <p className="text-black text-sm text-center mb-[18px]">
                  <span className="text-primary">Beautiful,</span>{" "}
                  Congratulations, your payment of ₦
                  {getFormattedNumber(offCycleForm?.amount || 0)} was
                  successful.
                </p>
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="font-bold text-center text-white bg-primary w-full max-w-[160px] p-[0.875rem]"
                  onClick={() => setOffCycleForm({ ...offCycleFormInit })}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="offCyclePaymentModal"
        tabIndex={-1}
        aria-labelledby="offCyclePaymentModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title py-6">Off Cycle Payment</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="text"
                      name="campaign"
                      id="campaign-input"
                      placeholder="Select Campaign"
                      className="custom-input"
                      readOnly
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}
                      value={offCycleForm?.campaign?.company_name || null}
                    />
                    <Icon
                      icon="mdi:chevron-down"
                      id="campaign-dropdown-icon"
                      className="text-2xl top-[25%] right-[18px] absolute cursor-pointer"
                      onClick={() => {
                        setShowDropdown(!showDropdown);
                      }}
                    />
                    {showDropdown && (
                      <>
                        <div className="triangle h-0 w-0 border-[20px] top-[35%] rotate-180 rounded-none border-t-white border-r-transparent border-l-transparent border-b-transparent absolute left-[86%] z-20"></div>
                        <div
                          id="campaign-dropdown"
                          className="dropdown max-h-[300px] overflow-auto py-[32px] absolute z-10 shadow-[0px_4px_10px_rgba(0,_0,_0,_0.15)] rounded-[2px] bg-white mt-2 right-0 sm:-right-[25%]"
                        >
                          <div className="flex flex-col w-full">
                            {loans?.data?.length > 0 &&
                              loans?.data?.map((loan, idx) => (
                                <div
                                  className={`${
                                    loan?.restructure_id ===
                                    offCycleForm?.campaign?.restructure_id
                                      ? "bg-gray-100"
                                      : ""
                                  } flex px-[40px] gap-3 py-2.5 cursor-pointer hover:bg-gray-100`}
                                  onClick={() => {
                                    setShowDropdown(false);
                                    setCampaignValue(loan);
                                  }}
                                  key={idx}
                                >
                                  <CompanyLogo
                                    logo={loan.company_logo}
                                    name={loan.company_name}
                                    className="logo_circle_sm"
                                  />
                                  <div className="flex flex-col">
                                    <h4 className="grid mt-auto items-center text-[#393939] text-[12.5px] leading-[17px] font-bold">
                                      {loan.company_name}
                                    </h4>
                                    <p className="text-[10px] mb-auto leading-[17px]">
                                      ₦{getFormattedNumber(loan?.amount)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full relative">
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder="Amount"
                      className="custom-input"
                      value={offCycleForm.amount || null}
                      onInput={(e) =>
                        setOffCycleForm((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                    <button
                      className="bg-transparent text-black text-xs px-3"
                      onClick={() =>
                        setOffCycleForm((prev) => ({
                          ...prev,
                          amount: offCycleForm?.campaign?.activated_plan_id[1],
                        }))
                      }
                    >
                      Autofill
                    </button>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="w-full">
                    <button
                      onClick={startOffCyclePayment}
                      disabled={!offCycleForm.amount || !offCycleForm.campaign}
                      className="btn font-bold text-center text-white bg-primary w-full p-[0.875rem]"
                    >
                      {offCyclePaymentLoading ? (
                        <span className="mx-auto">
                          {" "}
                          <Dashboard.Loader className="mx-auto"></Dashboard.Loader>
                        </span>
                      ) : (
                        "Complete Off Cycle Payment"
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  setOffCycleForm((prev) => ({ amount: "", campaign: null }))
                }
              >
                Cancel Payment
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="withdrawalModal"
        tabIndex={-1}
        aria-labelledby="withdrawalModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-size-lg modal-dialog-centered">
          <div className="modal-content w-full max-w-[625px] mx-4">
            <div className="modal-title">Withdraw</div>
            <div className="modal-body">
              <div className="form input-grid w-full max-w-[365px] grid grid-cols-2 gap-x-4 gap-y-7">
                <div className="col-span-2">
                  <div className="form-title">
                    Withdraw ₦ {getFormattedNumber(balance || 0)}
                  </div>
                  {parseInt(balance || 0) < 500 && (
                    <div className="text-center text-red-500 text-xs mt-1">
                      You do not have up to the minimum withdrawal limit
                    </div>
                  )}
                </div>
                {bankDetails?.bank_name ? (
                  <>
                    <div className="col-span-2">
                      <div className="custom-input">
                        Bank: {bankDetails?.bank_name}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="custom-input">
                        Account Number: {bankDetails?.account_number}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <div className="custom-input">
                        {bankDetails?.account_name}
                      </div>
                    </div>
                    <div className="col-span-2 px-2 mb-[50px]">
                      Withdrawals are made to account number linked in your
                      settings.
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-span-2 px-2 text-center">
                      Looks like you have not linked a bank account yet.
                    </div>
                    <div className="col-span-2 px-2 mb-[50px] flex justify-center">
                      <button
                        className="btn font-bold text-sm text-center text-primary p-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          navigate("/dashboard/settings", {
                            state: { tab: 3 },
                          });
                        }}
                      >
                        Goto Settings
                      </button>
                    </div>
                  </>
                )}
                <div className="col-span-2">
                  <div className="w-full">
                    <button className="font-bold text-center text-white bg-primary w-full p-[0.875rem]">
                      Complete Withdrawal
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="text-center text-xs font-bold text-black"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel Withdrawal
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* dialog end */}
      <div className="rewards_page">
        <HeaderText>Rewards</HeaderText>
        <div className="row mt-4 w-full gap-6 grid grid-cols-12">
          <div className="col-span-full md:col-span-8">
            <div className="grid grid-cols-1 gap-6">
              <div className="col-md-12">
                <DefaultCard className="text-center rewards_card py-4 mx-auto mb-[91px] w-full max-w-[420px]">
                  <img src={logo} style={{ width: "100px", height: "auto" }} />
                  <p className=" mb-0 mt-5 fw-400">Total Rewards Balance</p>
                  <p className="text-blue mr-n50 mb-5 pb-4">
                    <span className=" fw-700 fs-28">
                      {balanceLoading ? (
                        <span className="mx-auto">
                          <Icon
                            icon="mdi:loading"
                            className="text-3xl mx-auto inline-flex animate-spin"
                          />
                        </span>
                      ) : view ? (
                        "₦" + getFormattedNumber(balance)
                      ) : (
                        "****"
                      )}
                    </span>
                    <span>
                      <button
                        className="btn btn-transparent text-link p-1 mt-n10"
                        onClick={() => {
                          setView(!view);
                          console.log("view toggle", view);
                        }}
                      >
                        {" "}
                        {view ? (
                          <i className="fa fa-eye-slash ms-4 fw-500 fs-24 "></i>
                        ) : (
                          <i className="fa fa-eye ms-4 fw-500 fs-24 "></i>
                        )}
                      </button>
                    </span>
                  </p>
                  <div className="flex justify-center gap-3 mb-4">
                    <button
                      className="withdraw_btn btn font-medium"
                      data-bs-toggle="modal"
                      data-bs-target="#withdrawalModal"
                    >
                      Withdraw
                    </button>
                    <button
                      className="off-cycle_btn btn btn_primary font-medium bg-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#offCyclePaymentModal"
                    >
                      Off cycle payment
                    </button>
                  </div>
                </DefaultCard>
                <div className="px-3">
                  <h4 className="text-typography text-sm font-bold">
                    All Rewards and Milestones
                  </h4>
                </div>
                <div className="px-3 overflow-auto">
                  {loadingRewards ? (
                    <div className="py-10">
                      <PlanLoader />
                    </div>
                  ) : rewards?.data?.length > 0 ? (
                    <>
                      <div className="flex flex-col gap-y-10 min-w-[548px] pt-10 w-full">
                        {rewards?.data?.map((company, idx) => (
                          <div className="item grid grid-cols-7 gap-x-6 w-full">
                            <div className="min-w-[100px]">
                              <CompanyLogo
                                logo={company?.company_logo}
                                name={company?.company_name}
                              />
                            </div>
                            <div className="grid items-center col-span-2 ml-2">
                              <div>
                                <div className="truncated font-bold text-[16px] leading-[22.4px]">
                                  {company?.company_name || ""}
                                </div>
                                <div className="fs-10 faded-text">
                                  Status:{" "}
                                  <span
                                    className={
                                      company?.reward?.status == 1
                                        ? "text-yellow-500"
                                        : company?.reward?.status == 2
                                        ? "text-green-500"
                                        : "text-red-500"
                                    }
                                  >
                                    {
                                      reward_status[
                                        parseInt(company?.reward?.status) - 1
                                      ]
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="grid items-center col-span-2">
                              <p className="text-sm font-bold">
                                ₦
                                {getFormattedNumber(
                                  company?.reward?.total_amount || 0
                                )}
                              </p>
                            </div>
                            <div className="col-span-2 flex flex-col justify-content-center">
                              <Link
                                to={
                                  "/dashboard/payments/payment_details/" +
                                  company?.restructure_id
                                }
                              >
                                <button className="btn fs-14 btn_primary px-4 mx-auto">
                                  View Restructure Plan
                                </button>
                              </Link>
                            </div>
                            <div className="col-span-full mt-[14px]">
                              <button
                                className="text-primary bg-transparent font-bold px-0"
                                onClick={() =>
                                  showMilestone[idx]
                                    ? setShowMilestone((prev) => ({
                                        ...prev,
                                        [idx]: false,
                                      }))
                                    : setShowMilestone((prev) => ({
                                        ...prev,
                                        [idx]: true,
                                      }))
                                }
                              >
                                {showMilestone[idx] ? "Hide" : "Show"}{" "}
                                Milestones and Rewards
                              </button>
                            </div>
                            {showMilestone[idx] && (
                              <div className="col-span-full mt-[28px]">
                                <div className="reward-progress">
                                  <div
                                    className={`progress-fill percent-${(
                                      (100 /
                                        parseInt(
                                          company?.activated_amount || 0
                                        )) *
                                      parseInt(company?.total_paid || 0)
                                    ).toFixed(0)}`}
                                  ></div>
                                  <div
                                    className={`progress-dot ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        0
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦{getFormattedNumber(0, 0)}
                                    </div>
                                  </div>
                                  <div
                                    className={`progress-dot one ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        (20 / 100) *
                                          parseInt(company?.activated_amount)
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦
                                      {getFormattedNumber(
                                        parseInt(
                                          company?.reward?.stages?.stage_1
                                        ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`progress-dot two ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        (40 / 100) *
                                          parseInt(company?.activated_amount)
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦
                                      {getFormattedNumber(
                                        parseInt(
                                          company?.reward?.stages?.stage_2
                                        ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`progress-dot three ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        (60 / 100) *
                                          parseInt(company?.activated_amount)
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦
                                      {getFormattedNumber(
                                        parseInt(
                                          company?.reward?.stages?.stage_3
                                        ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`progress-dot four ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        (80 / 100) *
                                          parseInt(company?.activated_amount)
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦
                                      {getFormattedNumber(
                                        parseInt(
                                          company?.reward?.stages?.stage_4
                                        ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={`progress-dot five ${
                                      getActiveProgressDot(
                                        company?.total_paid,
                                        (100 / 100) *
                                          parseInt(company?.activated_amount)
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="dot-label">
                                      ₦
                                      {getFormattedNumber(
                                        parseInt(
                                          company?.reward?.stages?.stage_5
                                        ),
                                        0
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="py-10">
                      <EmptyPlansTable text="There are no rewards data to show at the moment." />
                    </div>
                  )}
                </div>
                {rewards?.data?.length > 0 && (
                  <div className="px-3 pt-3 pb-10 w-full">
                    <Paginator
                      data={rewards}
                      setData={setRewards}
                      setLoading={setLoadingRewards}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>{" "}
          <div className="col-span-full mt-5 md:col-span-4">
            <CreditScoreDash
              showRepaymentStatus={false}
              showWithdrawStatus={true}
            ></CreditScoreDash>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardsPage;
