import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import successcancelicon from "../../assets/svgs/success-cancel.svg";
import errorcancelicon from "../../assets/svgs/error-cancel.svg";
import successbanner from "../../assets/svgs/notify-success.svg";
import errorbanner from "../../assets/svgs/notify-error.svg";

import Aos from "aos";
import "aos/dist/aos.css";
import "./popup.scss";
/**
 * Neat Idea: When we integrate a state manager we can have a parent component that handles the display of notifications
 * instead of mounting them using reactDOM. Also we could use that container to render multiple notifications
 * at once.
 */
export default function Popup(props) {
  const [closeTimeout, setCloseTimeout] = useState(null);

  useEffect(() => {
    console.log("started sequence")
    beginCloseTimeout();
  }, []);

  const closePopup = () => {
    clearTimeout(closeTimeout);
    const element = document.getElementById(`message-${props.id}`);
    ReactDOM.unmountComponentAtNode(element);
    element.remove();
  };

  const beginCloseTimeout = () => {
    if (props.timer) {
      const timeout = setTimeout(() => closePopup(), props.timer);
      setCloseTimeout(timeout);
    }
  };
  let cancelIcon, bannerIcon;
  if (props.messageType == "error") {
    cancelIcon = errorcancelicon;
    bannerIcon = errorbanner;
  }
  if (props.messageType == "success") {
    cancelIcon = successcancelicon;
    bannerIcon = successbanner;
  }

  return (
    <div
      className={`notification-container ${props.messageType}-container`}
      onMouseEnter={() => clearTimeout(closeTimeout)}
      onMouseLeave={() => beginCloseTimeout()}
    >
      <div>
        <div className="notification-info-container">
          <div className="d-flex align-items-center">
            <div className="notify-banner me-3">
              <img src={bannerIcon} />
            </div>
            <div className="notify-text">
              <h5 className={`${props.messageType}-info-text`}>
                {" "}
                {props.message}
              </h5>
            </div>
          </div>
          <button
            className="btn popup-cancel-btn px-3"
            onClick={() => closePopup()}
            alt="close icon"
          >
            {" "}
            <img src={cancelIcon} id="close-notification-icon" />
          </button>
        </div>
      </div>
    </div>
  );
}
