import { Skeleton } from "@mui/material";

export default function SmallTableLoader() {
  return (
    <table className="fs-14  fw-600 w-100 ">
      <thead>
        <tr>
          <th style={{ width: "60px" }}></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Skeleton
              variant="circular"
              className="logo logo_cirle_sm min-w-[44.98px] min-h-[44.98px]"
            ></Skeleton>
          </td>
          <td>
            <div className="truncated text-sm font-bold text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[60px]"></Skeleton>
            </div>
            <div className="truncated whitespace-nowrap text-sm font-medium text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[50px]"></Skeleton>
            </div>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <div className="whitespace-nowrap text-sm font-bold text-[#555555]">
              <Skeleton
                variant="text"
                className="h-fit ml-auto w-[80px]"
              ></Skeleton>
            </div>
            <Skeleton
              variant="text"
              component="button"
              className="p-0 whitespace-nowrap w-[80px] ml-auto text-sm font-normal"
            ></Skeleton>
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton
              variant="circular"
              className="logo logo_cirle_sm min-w-[44.98px] min-h-[44.98px]"
            ></Skeleton>
          </td>
          <td>
            <div className="truncated text-sm font-bold text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[60px]"></Skeleton>
            </div>
            <div className="truncated whitespace-nowrap text-sm font-medium text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[50px]"></Skeleton>
            </div>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <div className="whitespace-nowrap text-sm font-bold text-[#555555]">
              <Skeleton
                variant="text"
                className="h-fit ml-auto w-[80px]"
              ></Skeleton>
            </div>
            <Skeleton
              variant="text"
              component="button"
              className="p-0 whitespace-nowrap w-[80px] ml-auto text-sm font-normal"
            ></Skeleton>
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton
              variant="circular"
              className="logo logo_cirle_sm min-w-[44.98px] min-h-[44.98px]"
            ></Skeleton>
          </td>
          <td>
            <div className="truncated text-sm font-bold text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[60px]"></Skeleton>
            </div>
            <div className="truncated whitespace-nowrap text-sm font-medium text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[50px]"></Skeleton>
            </div>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <div className="whitespace-nowrap text-sm font-bold text-[#555555]">
              <Skeleton
                variant="text"
                className="h-fit ml-auto w-[80px]"
              ></Skeleton>
            </div>
            <Skeleton
              variant="text"
              component="button"
              className="p-0 whitespace-nowrap w-[80px] ml-auto text-sm font-normal"
            ></Skeleton>
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton
              variant="circular"
              className="logo logo_cirle_sm min-w-[44.98px] min-h-[44.98px]"
            ></Skeleton>
          </td>
          <td>
            <div className="truncated text-sm font-bold text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[60px]"></Skeleton>
            </div>
            <div className="truncated whitespace-nowrap text-sm font-medium text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[50px]"></Skeleton>
            </div>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <div className="whitespace-nowrap text-sm font-bold text-[#555555]">
              <Skeleton
                variant="text"
                className="h-fit ml-auto w-[80px]"
              ></Skeleton>
            </div>
            <Skeleton
              variant="text"
              component="button"
              className="p-0 whitespace-nowrap w-[80px] ml-auto text-sm font-normal"
            ></Skeleton>
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton
              variant="circular"
              className="logo logo_cirle_sm min-w-[44.98px] min-h-[44.98px]"
            ></Skeleton>
          </td>
          <td>
            <div className="truncated text-sm font-bold text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[60px]"></Skeleton>
            </div>
            <div className="truncated whitespace-nowrap text-sm font-medium text-[#393939]">
              <Skeleton variant="text" className="h-fit w-[50px]"></Skeleton>
            </div>
          </td>
          <td className="text-md-end whitespace-nowrap">
            <div className="whitespace-nowrap text-sm font-bold text-[#555555]">
              <Skeleton
                variant="text"
                className="h-fit ml-auto w-[80px]"
              ></Skeleton>
            </div>
            <Skeleton
              variant="text"
              component="button"
              className="p-0 whitespace-nowrap w-[80px] ml-auto text-sm font-normal"
            ></Skeleton>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
