import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getFormattedNumber, useQuery } from "../../../../utils/functions";
import { postRequest } from "../../../../utils/httprequests/requests";
import { showErrorMessage } from "../../../../utils/notification";
import message_sent_icon from "../../../../assets/gifs/message-sent-successfully-plane.gif";
import failed_icon from "../../../../assets/svgs/failel-x-circle.svg";
import checking_icon from "../../../../assets/svgs/alert-circle.svg";
import { Icon } from "@iconify/react";

export default function ConfirmPayment() {
  const location = useLocation();

  const query = useQuery(location.search.replace("?", ""));

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [status, setStatus] = useState("checking");
  const [message, setMessage] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { restructureId } = useParams();
  const [retryCount, setRetryCount] = useState(0);

  async function verifyTransaction() {
    setStatus("checking");
    try {
      const {
        data: {
          status,
          message,
          data: { error, info },
        },
      } = await postRequest("/customers_confirm_payment", {
        trxref: query?.trxref,
        reference: query?.reference,
      });

      if (status == "success") {
        setStatus("success");
        setData(info);
      } else if (status == "error") {
        setStatus("error");
        setData(info);
        throw new Error(message);
      }
    } catch (err) {
      setStatus("error");
      setMessage(err?.response?.data?.message || err?.message || err);
      showErrorMessage(err?.response?.data?.message || err?.message || err);
      console.log(err);
    }
  }

  const effectCount = useRef(0);

  useEffect(() => {
    if (effectCount.current > 0) return;
    if (!query?.trxref || !query?.reference) {
      showErrorMessage(
        "Valid transaction refrence is required for verification"
      );
      navigate("/dashboard/payments");
    }
    verifyTransaction();

    return () => {
      effectCount.current++;
    };
  }, []);

  return (
    <div className="w-full h-[450px] grid place-items-center">
      <div className="relative md:-left-[30px] md:-top-[40px] max-w-[380px]">
        <img
          src={
            status === "checking"
              ? checking_icon
              : status === "success"
              ? message_sent_icon
              : failed_icon
          }
          alt="message sent gif"
          className="mx-auto w-[8rem] h-[8rem] mb-2 animate-pulse"
        />
        <h4 className="text-center text-[#021C4F]  font-bold text-2xl mb-4">
          {status === "checking"
            ? "Confirmation In-Progress"
            : status === "success"
            ? "Payment Successful"
            : "Payment failed"}
        </h4>
        <p className="text-center">
          {status === "checking"
            ? "Please wait while we confirm your payment."
            : status === "success"
            ? `Your payment of ₦${getFormattedNumber(
                data?.amount_paid || data?.amount
              )} was successful.`
            : message ||
              `Your payment of ₦${getFormattedNumber(
                data?.amount_paid || data?.amount
              )} was not successful.
            Please try again.`}
        </p>
        <div className="min-w-[200px] flex justify-center mt-8">
          {status === "checking" ? (
            <div className="w-full h-full grid place-items-center">
              <span className="mx-auto">
                <Icon
                  icon="mdi:loading"
                  className="text-3xl mx-auto animate-spin"
                />
              </span>
            </div>
          ) : status === "success" ? (
            <Link
              className="w-full flex justify-center"
              to={"/dashboard/payments/payment_details/" + restructureId}
            >
              <button className="btn bg-[#009DDF] text-white hover:bg-[#05aaf1] rounded-[10px] text-sm font-bold max-w-[160px] mx-auto text-center">
                Continue
              </button>
            </Link>
          ) : (
            <button
              onClick={
                retryCount < 1
                  ? () => {
                      verifyTransaction();
                      setRetryCount(retryCount + 1);
                    }
                  : navigate(
                      "/dashboard/payments/payment_details/" + restructureId
                    )
              }
              className="btn bg-[#009DDF] text-white hover:bg-[#05aaf1] rounded-[10px] text-sm font-bold max-w-[160px] mx-auto text-center"
            >
              {retryCount < 1 ? "Retry" : "Go Back"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
